export default {
    data() {
        return {
        }
    },
    methods: {

        reverseFacetData(array){
            let valor;
            let arrayResult = Array()
            for (let i=array.length - 1; i >= 0; i--) {
                if(i % 2 === 0){
                    arrayResult.push(array[i], valor)
                }else{
                    valor = array[i]
                }
            }
            return arrayResult
        },

        colorSeriesChart(nameSerie){
            let colorSerie
            if(nameSerie === 'Examen' || nameSerie === 'Examen' || nameSerie === 'Exam') colorSerie = '#2f7ed8'
            else if(nameSerie === 'Treball Final de Grau' || nameSerie === 'Trabajo final de grado' || nameSerie === 'Bachelor thesis') colorSerie = '#0d233a'
            else if(nameSerie === 'Projecte/Treball Final de Carrera' || nameSerie === 'Proyecto/Trabajo final de carrera' || nameSerie === 'Master thesis (pre-Bologna period)') colorSerie = '#8bbc21'
            else if(nameSerie === 'Projecte Final de Màster Oficial' || nameSerie === 'Projecte Final de Màster Oficial' || nameSerie === 'Master thesis') colorSerie = '#910000'
            else if(nameSerie === 'Tesina' || nameSerie === 'Tesina' || nameSerie === 'Minor thesis') colorSerie = '#1aadce'
            else if(nameSerie === 'Llibre' || nameSerie === 'Libro' || nameSerie === 'Book') colorSerie = '#492970'
            else if(nameSerie === 'Audiovisual' || nameSerie === 'Audiovisual' || nameSerie === 'Audiovisual') colorSerie = '#f28f43'
            else if(nameSerie === 'Treball d\'assignatura' || nameSerie === 'Trabajo de asignatura' || nameSerie === 'Coursework') colorSerie = '#77a1e5'
            else if(nameSerie === 'Apunts' || nameSerie === 'Apuntes' || nameSerie === 'Lecture notes') colorSerie = '#c42525'
            else if(nameSerie === 'Problema, exercici' || nameSerie === 'Problema, ejercicio' || nameSerie === 'Problem statement, exercise') colorSerie = '#a6c96a'
            else if(nameSerie === 'Projecte Final de Màster UPC' || nameSerie === 'Proyecto final de máster UPC' || nameSerie === 'UPC Master thesis') colorSerie = '#c96a85'
            else if(nameSerie === 'Pràctica' || nameSerie === 'Práctica' || nameSerie === 'Practice') colorSerie = '#f8e84c'
            else if(nameSerie === 'Altres' || nameSerie === 'Otros' || nameSerie === 'Other') colorSerie = '#f30c0c'
            else if(nameSerie === 'Treball de recerca tutelat' || nameSerie === 'Trabajo de investigación tutelado' || nameSerie === 'Tutored research work') colorSerie = '#a15404'
            else if(nameSerie === 'Projecte de col·laboració' || nameSerie === 'Proyecto de colaboración' || nameSerie === 'Collaborative project') colorSerie = '#6ac9b6'
            else if(nameSerie === 'Programari' || nameSerie === 'Software' || nameSerie === 'Software') colorSerie = '#6a74c9'
            else if(nameSerie === 'Proposta de tesi' || nameSerie === 'Propuesta de tesis' || nameSerie === 'Doctoral thesis proposal') colorSerie = '#8eee65'
            else if(nameSerie === 'Imatge' || nameSerie === 'Imagen' || nameSerie === 'Image') colorSerie = '#8934ec'
            else if(nameSerie === 'Treball professional' || nameSerie === 'Trabajo profesional' || nameSerie === 'Professional work') colorSerie = '#f85904'
            else if(nameSerie === 'Projecte de tesi' || nameSerie === 'Proyecto de tesis' || nameSerie === 'Doctoral thesis project') colorSerie = '#02582b'
            else if(nameSerie === 'Exercici de pràctiques' || nameSerie === 'Ejercicio de prácticas' || nameSerie === 'Practical exercise') colorSerie = '#b7c946'
            else if(nameSerie === 'Projecte per a l\'homologació del títol' || nameSerie === 'Proyecto para la homologación del título' || nameSerie === 'Recognition of foreign diploma') colorSerie = '#ad0202'
            else colorSerie = 'black'
            return colorSerie
        },

        colorSeriesRightsChart(nameSerie){
            let colorSerie;
            if(nameSerie === 'Accés obert' || nameSerie === 'Acceso abierto' || nameSerie === 'Open Access') colorSerie = '#6dd067'
            else if(nameSerie === 'Accés restringit a la comunitat UPC' || nameSerie === 'Acceso restringido a la comunidad UPC' || nameSerie === 'Restricted access to the UPC academic community') colorSerie = '#5b86b4'
            else if(nameSerie === 'Accés restringit per decisió de l\'autor' || nameSerie === 'Acceso restringido por decisión del autor' || nameSerie === 'Restricted access - author\'s decision') colorSerie = '#e39393'
            else if(nameSerie === 'Accés restringit per acord de confidencialitat' || nameSerie === 'Acceso restringido por acuerdo de confidencialidad' || nameSerie === 'Restricted access - confidentiality agreement') colorSerie = '#dc4c4c'
            else if(nameSerie === 'Accés restringit a la comunitat acadèmica del CSUC' || nameSerie === 'Acceso restringido a la comunidad académica del CSUC' || nameSerie === 'Restricted access to the CSUC academic community') colorSerie = '#5db8cc'
            else if(nameSerie === 'Accés restringit per política de l\'editorial' || nameSerie === 'Acceso restringido por política de la editorial' || nameSerie === 'Restricted access - publisher\'s policy') colorSerie = '#d38da2'
            else colorSerie = 'black'
            return colorSerie
        },

        imageLoad(rights, imageUrl){
            if(rights === "Open Access"){
                return imageUrl
            }else if(rights === "Restricted access to the UPC academic community" || rights === "Restricted access to the CSUC academic community"){
                return require("@/assets/images/restricted.png")
            }else {
                return require("@/assets/images/locked.png")
            }
        },

        imageUrlAlt(event, rights){
            if(rights === "Restricted access to the UPC academic community" || rights === "Restricted access to the CSUC academic community"){
                event.target.src = require("@/assets/images/restricted.png")
            }else {
                event.target.src = require("@/assets/images/locked.png")
            }
        },
    }
};
