<template>

    <!-- https://html5-editor.net/ -->


    <div>

      <div v-if="$language.current === 'ca'">
        <h1>Sobre APR&Egrave;N</h1>
        <p><strong>Apr&egrave;n</strong> &eacute;s el portal que facilita l&rsquo;acc&eacute;s als continguts que elabora el professorat de la Universitat Polit&egrave;cnica de Catalunya (UPC) com a eina de suport a la seva activitat docent.</p>
        <p><strong>Apr&egrave;n</strong> es nodreix dels continguts docents que el professorat de la UPC ha incorporat a <strong><a href="https://upcommons.upc.edu/">UPCommons</a></strong>, el portal del coneixement obert de la UPC i a <strong><a href="https://zonavideo.upc.edu/">Zonav&iacute;deo</a></strong>, el portal d&rsquo;audiovisuals de la UPC.&nbsp;</p>
        <p>Els objectius d&rsquo;<strong>Apr&egrave;n</strong> s&oacute;n:&nbsp;</p>
        <ul>
          <li class="ml-5" aria-level="1"><strong>Millorar la visibilitat i l&rsquo;impacte</strong> de la producci&oacute; docent del professorat de la UPC, afavorint-ne la consulta des d&rsquo;una plataforma agregadora que n&rsquo;unifica l&rsquo;acc&eacute;s i la consulta.</li>
          <li class="ml-5" aria-level="1"><strong>Donar valor a la producci&oacute; docent</strong> del professorat de la UPC.&nbsp;</li>
          <li class="ml-5" aria-level="1"><strong>Incrementar la publicaci&oacute; en acc&eacute;s obert</strong> dels continguts docents elaborats pel personal docent i investigador (PDI) de la UPC, facilitant que es puguin reutilitzar com a instrument per a la millora de la doc&egrave;ncia.</li>
          <li class="ml-5" aria-level="1"><strong>Identificar un&iacute;vocament els autors dels continguts docents</strong>, tant pel que fa al nom personal com a la filiaci&oacute; institucional.</li>
          <li class="ml-5" aria-level="1"><strong>Potenciar l&rsquo;&uacute;s d&rsquo;UPCommons com el repositori institucional,</strong> amb l&rsquo;objectiu de recollir i preservar tota la producci&oacute; acad&egrave;mica de la UPC.</li>
        </ul>
        <p>&nbsp;</p>
        <br><br>
        <p><strong>Qu&egrave; hi puc trobar?</strong></p>
        <p><strong>Apr&egrave;n </strong>permet consultar els continguts docents de la UPC per diferents opcions:&nbsp;</p>
        <ul>
          <li class="ml-5" aria-level="1"><strong>Professorat</strong>: consulta dels continguts docents elaborats pel professorat de la UPC.</li>
          <li class="ml-5" aria-level="1"><strong>Organitzaci&oacute;</strong>: consulta dels continguts docents elaborats o dirigits pels membres d&rsquo;una unitat (centres docents o departaments) de la UPC.</li>
          <li class="ml-5" aria-level="1"><strong>Assignatures</strong>: consulta dels continguts docents relacionats amb una assignatura impartida a la UPC.</li>
          <li class="ml-5" aria-level="1"><strong>Titulacions</strong>: consulta dels continguts docents relacionats amb una titulaci&oacute; de grau, de m&agrave;ster o de doctorat impartida a la UPC.</li>
          <li class="ml-5" aria-level="1"><strong>Continguts</strong>: consulta de tots els continguts docents disponibles a UPCommons.&nbsp;</li>
          <li class="ml-5" aria-level="1"><strong>Acc&eacute;s obert</strong>: consulta de tots els continguts docents disponibles a UPCommons i a Zonav&iacute;deo, publicats amb <strong>acc&eacute;s obert </strong>a internet.</li>
          <li class="ml-5" aria-level="1"><strong>TFE (treballs de fi d&rsquo;estudis)</strong>: consulta dels treballs de fi de grau, de les tesines de fi de m&agrave;ster&hellip;, elaborats per l&rsquo;estudiantat de la UPC com a cicle final i de s&iacute;ntesi dels coneixements i de les compet&egrave;ncies adquirides a la UPC.</li>
          <li class="ml-5" aria-level="1"><strong>V&iacute;deos</strong>: consulta dels continguts audiovisuals vinculats a l&rsquo;activitat docent de la UPC.&nbsp;</li>
        </ul>
        <p dir="ltr">&nbsp;</p>
        <p>Els principals tipus de continguts docents que es preveuen actualment a <strong>Apr&egrave;n</strong> s&oacute;n:&nbsp;</p>
        <ul>
          <li class="ml-5" aria-level="1">Apunts i presentacions</li>
          <li class="ml-5" aria-level="1">Ex&agrave;mens</li>
          <li class="ml-5" aria-level="1">Treballs de fi d&rsquo;estudis (TFE)</li>
          <li class="ml-5" aria-level="1">Audiovisuals</li>
          <li class="ml-5" aria-level="1">Llibres&nbsp;</li>
          <li class="ml-5" aria-level="1">Treballs d&rsquo;estudiants</li>
          <li class="ml-5" aria-level="1">Problemes i exercicis</li>
          <li class="ml-5" aria-level="1">Pr&agrave;ctiques</li>
          <li class="ml-5" aria-level="1">Programari</li>
          <li class="ml-5" aria-level="1">Imatges</li>
        </ul>
      </div>


      <div v-if="$language.current === 'es'">
        <h1>Sobre APR&Egrave;N</h1>
        <p><strong>Apr&egrave;n </strong><span >es el portal que facilita el acceso a los contenidos que elabora el profesorado de la Universitat Polit&egrave;cnica de Catalunya (UPC) como herramienta de apoyo a su actividad docente.</span></p>
        <p>&nbsp;</p>
        <p><strong>Apr&egrave;n</strong><span > se nutre de los contenidos docentes que el profesorado de la UPC ha incorporado a </span><strong><a href="https://upcommons.upc.edu/">UPCommons</a></strong><span >, el portal del conocimiento abierto de la UPC y a </span><strong><a href="https://zonavideo.upc.edu/">Zonav&iacute;deo</a></strong><span >, el portal de audiovisuales de la UPC.&nbsp;</span></p>
        <p>&nbsp;</p>
        <p><span >Los objetivos de </span><strong>Apr&egrave;n</strong><span > son:</span></p>
        <p>&nbsp;</p>
        <ul>
          <li class="ml-5" aria-level="1"><strong>Mejorar la visibilidad y el impacto</strong><span > de la producci&oacute;n docente del profesorado de la UPC, favoreciendo su consulta desde una plataforma agregadora que unifica su acceso y consulta.</span></li>
          <li class="ml-5" aria-level="1"><strong>Poner en valor la producci&oacute;n docente</strong><span > del profesorado de la UPC.&nbsp;</span></li>
          <li class="ml-5" aria-level="1"><strong>Incrementar la publicaci&oacute;n en abierto</strong><span > de los contenidos docentes elaborados por el personal docente e investigador (PDI) de la UPC, facilitando que puedan reutilizarse como instrumento para la mejora de la docencia.</span></li>
          <li class="ml-5" aria-level="1"><strong>Identificar un&iacute;vocamente a los autores de los contenidos docentes</strong><span >, tanto por su nombre personal como por su filiaci&oacute;n institucional.</span></li>
          <li class="ml-5" aria-level="1"><strong>Potenciar el uso de UPCommons como el repositorio institucional,</strong><span > con el objetivo de recoger y preservar toda la producci&oacute;n acad&eacute;mica de la UPC.</span></li>
        </ul>
        <p>&nbsp;</p>
        <br><br>
        <p><strong>&iquest;Qu&eacute; puedo encontrar?</strong></p>
        <p>&nbsp;</p>
        <p><strong>Apr&egrave;n </strong><span >permite consultar los contenidos docentes de la UPC por diferentes opciones:</span></p>
        <p>&nbsp;</p>
        <ul>
          <li class="ml-5" aria-level="1"><strong>Profesorado</strong><span >: consulta de los contenidos docentes elaborados por el profesorado de la UPC.</span></li>
          <li class="ml-5" aria-level="1"><strong>Organizaci&oacute;n</strong><span >: consulta de los contenidos docentes elaborados o dirigidos por los miembros de una unidad (centros docentes o departamentos) de la UPC.</span></li>
          <li class="ml-5" aria-level="1"><strong>Asignaturas</strong><span >: consulta de los contenidos docentes relacionados con una asignatura impartida en la UPC.</span></li>
          <li class="ml-5" aria-level="1"><strong>Titulaciones</strong><span >: consulta de los contenidos docentes relacionados con una titulaci&oacute;n de grado, de m&aacute;ster o de doctorado impartida en la UPC.</span></li>
          <li class="ml-5" aria-level="1"><strong>Contenidos</strong><span >: consulta de todos los contenidos docentes disponibles en UPCommons.&nbsp;</span></li>
          <li class="ml-5" aria-level="1"><strong>Acceso abierto</strong><span >: consulta de todos los contenidos docentes disponibles en UPCommons y en Zonav&iacute;deo, publicados con </span><strong>acceso abierto </strong><span >en internet.</span></li>
          <li class="ml-5" aria-level="1"><strong>TFE (trabajos de fin de estudios)</strong><span >: consulta de los trabajos de fin de grado, de las tesinas de fin de m&aacute;ster&hellip;, elaborados por los estudiantes de la UPC como ciclo final y de s&iacute;ntesis de los conocimientos de las competencias adquiridas en la UPC.</span></li>
          <li class="ml-5" aria-level="1"><strong>V&iacute;deos</strong><span >: consulta de los contenidos audiovisuales vinculados a la actividad docente de la UPC.&nbsp;</span></li>
        </ul>
        <p>&nbsp;</p>
        <br><br>
        <p><span >Los principales tipos de contenidos docentes que se contemplan actualmente en </span><strong>Apr&egrave;n</strong><span > son:</span></p>
        <p>&nbsp;</p>
        <ul>
          <li class="ml-5" aria-level="1"><span >Apuntes y presentaciones</span></li>
          <li class="ml-5" aria-level="1"><span >Ex&aacute;menes</span></li>
          <li class="ml-5" aria-level="1"><span >Trabajos de fin de estudios (TFE)</span></li>
          <li class="ml-5" aria-level="1"><span >Audiovisuales</span></li>
          <li class="ml-5" aria-level="1"><span >Libros&nbsp;</span></li>
          <li class="ml-5" aria-level="1"><span >Trabajos de estudiantes</span></li>
          <li class="ml-5" aria-level="1"><span >Problemas y ejercicios</span></li>
          <li class="ml-5" aria-level="1"><span >Pr&aacute;cticas</span></li>
          <li class="ml-5" aria-level="1"><span >Programas</span></li>
          <li class="ml-5" aria-level="1"><span >Im&aacute;genes</span></li>
        </ul>
      </div>

      <div v-if="$language.current === 'en'">
        <h1>About Apr&egrave;n&nbsp;</h1>
        <p><strong>Apr&egrave;n</strong><span style="font-weight: 300;"> is a portal that provides access to the teaching support materials developed by the teaching staff of the Universitat Polit&egrave;cnica de Catalunya (UPC).</span></p>
        <p><strong>Apr&egrave;n</strong><span style="font-weight: 300;"> contains the teaching content that UPC teaching staff incorporate into </span><a href="https://upcommons.upc.edu/"><strong>UPCommons</strong></a><span style="font-weight: 300;">, the UPC&rsquo;s open knowledge portal</span><span style="font-weight: 300;">, and </span><strong><a href="https://zonavideo.upc.edu/">Zonavideo</a></strong><span style="font-weight: 300;">, the UPC&rsquo;s audiovisual portal </span></p>
        <p><span style="font-weight: 300;">The objectives of </span><strong>Apr&egrave;n</strong><span style="font-weight: 300;"> are:</span></p>
        <ul>
          <li style="font-weight: 300;" aria-level="1"><strong>To improve the visibility and impact</strong><span style="font-weight: 300;"> of the teaching output of UPC teaching staff with an integrating platform that standardises access and search.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>To add value to the teaching output</strong><span style="font-weight: 300;"> of UPC teaching staff.&nbsp;</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>To increase open access publishing</strong><span style="font-weight: 300;"> of the teaching content produced by UPC teaching and research staff and make it easier for the content to be reused to improve teaching.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>To identify the authors of the teaching </strong><strong>content unambiguously</strong><span style="font-weight: 300;">, both in terms of their personal name and their institutional affiliation.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>To promote the use of UPCommons as the institutional repository</strong><span style="font-weight: 300;"> for collecting and preserving all of the UPC&rsquo;s academic output.</span></li>
        </ul>
        <br><br>
        <p><strong>What can I find in Apr&egrave;n?</strong></p>
        <p><strong>Apr&egrave;n </strong><span style="font-weight: 300;">can be used to consult the UPC&rsquo;s teaching content by choosing one of the options listed below.</span></p>
        <ul>
          <li style="font-weight: 300;" aria-level="1"><strong>Teaching staff</strong><span style="font-weight: 300;">: consult the teaching content produced by UPC teaching staff.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>Organisation</strong><span style="font-weight: 300;">: consult the teaching content produced or supervised by the members of a UPC unit (schools or departments).</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>Subjects</strong><span style="font-weight: 300;">: consult the teaching content related to a subject taught at the UPC.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>Degrees</strong><span style="font-weight: 300;">: consult the teaching content related to a bachelor&rsquo;s, master&rsquo;s or doctoral degree taught at the UPC.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>Content</strong><span style="font-weight: 300;">: consult all the teaching content available on UPCommons.&nbsp;</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>Open access</strong><span style="font-weight: 300;">: consult all the teaching content available on UPCommons and Zonav&iacute;deo that has been published in </span><strong>open access</strong><span style="font-weight: 300;"> on the internet.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>Bachelor&rsquo;s and master&rsquo;s theses</strong><span style="font-weight: 300;">: consult bachelor&rsquo;s and master&rsquo;s theses produced by UPC students as a final work and summary of the knowledge and competencies that they have gained at the UPC.</span></li>
          <li style="font-weight: 300;" aria-level="1"><strong>Videos</strong><span style="font-weight: 300;">: consult the audiovisual content linked to teaching activities at the UPC.&nbsp;</span></li>
        </ul>
        <p><span style="font-weight: 300;">The main types of educational content currently available in the </span><strong>Apr&egrave;n system</strong><span style="font-weight: 300;"> are:</span></p>
        <ul>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Lecture notes and presentations</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Exam papers</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Bachelor&rsquo;s and master&rsquo;s theses</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Audiovisual content</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Books&nbsp;</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Student assignments</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Problems and exercises</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Practicals</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Software</span></li>
          <li style="font-weight: 300;" aria-level="1"><span style="font-weight: 300;">Images</span></li>
        </ul>
      </div>

    </div>



</template>

<script>

    export default {
        name: 'About',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: circle;
        padding: 0;
    }

    li {
        /*display: inline-block;*/
        margin: 0 10px;
    }

    /*a {
        color: #42b983;
    }

    .alert-crea {
        color: #ffffff;
        background-color: #395471;
        border-color: #c6c8ca;
    }

    .alert-identifica {
        color: #395471;
        background-color: #b1d8e8;
        border-color: #c6c8ca;
    }

    .alert-publica {
        color: #395471;
        background-color: #f0ca00;
        border-color: #c6c8ca;
    }

    .alert-comparteix {
        color: #ffffff;
        background-color: #4facbe;
        border-color: #c6c8ca;
    }
    */
</style>
