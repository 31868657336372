<template>

    <div>
        <span class="text-green font-weight-bold h5">{{navigation_start}}</span>
        <span class="h6">&nbsp;<translate>a</translate>&nbsp;</span>
        <span class="text-green font-weight-bold h5 ml-1">{{navigation_end}}</span>
        <span class="h6">&nbsp;<translate>de</translate>&nbsp;</span>
        <span class="text-green font-weight-bold h5 ml-1">{{navigation_total}}</span>
        <span class="h6">&nbsp;<translate>resultats</translate></span>
    </div>

</template>

<script>

    export default {
        name: 'Pagination-Header',

        props: {
            navigation_start: Number,
            navigation_end: Number,
            navigation_total: Number,
        }
    }
</script>

