<template>

    <div>
        <span v-if="facetes"></span>
        <highcharts :options="chartOptions"></highcharts>
    </div>

</template>

<script>
    import Utils from "@/extends/Utils";

    export default {
        name: 'ChartByTipus',
        mixins: [Utils],
        props: {
            facetes: [Object, Array],
            facetesPivot: [Object, Array]
        },
        data() {
            return {
                msg: "String",

                chartOptions: {

                    chart: {
                        type: 'column',
                        style: {
                          fontFamily: 'Roboto'
                        }
                        //height: 530
                    },
                    title: {
                        text: ''
                    },

                    xAxis: {
                        categories: [],
                        labels: {
                            rotation: 90,

                        }
                    },

                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal'
                        },
                        series: {
                            groupPadding: 0,
                            //pointWidth: 20
                        }
                    },


                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.x + '</b><br/>' +
                                this.series.name + ': ' + this.y + '<br/>' +
                                'Total: ' + this.point.stackTotal;
                        }
                    },

                    /*legend: {
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },*/

                    legend: {
                      itemStyle: {
                        fontWeight: 'bold',
                      }
                    },


                    colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],

                    series: [],

                },
            }
        },
        updated() {

            if(this.chartOptions.series.length > 0) return
            //reset chart
            this.chartOptions.series = []
            this.chartOptions.xAxis.categories = []
            this.chartOptions.title.text = this.$gettext('Contingut docent per data')
            this.chartOptions.yAxis.title.text = this.$gettext('Número Contingut docents')

              //add xAxys Categories
            Object.entries(this.facetes).forEach(([facetName, facetValue]) => {
                if(facetName == "data"){
                    Object.entries(facetValue).forEach(([index, item]) => {

                        if(index % 2 == 0) this.chartOptions.xAxis.categories.push(item)
                    })
                    this.chartOptions.xAxis.categories.sort();
                    if(this.chartOptions.xAxis.categories.length > 20){
                        this.chartOptions.xAxis.categories = this.chartOptions.xAxis.categories.slice(this.chartOptions.xAxis.categories.length-20,this.chartOptions.xAxis.categories.length)
                    }
                }
            })

            Object.entries(this.facetesPivot).forEach(([facetName, facetValue]) => {
                facetName

                if (facetName === "subtipus,data") {
                  Object.entries(facetValue).forEach(([index, item]) => {
                    index
                    var nameSerie

                    var map = new Map()
                    Object.entries(item.pivot).forEach(([index, pivot]) => {
                      index
                      nameSerie = this.$gettext(item.value)
                      //console.log(item.value+"----->"+pivot.value +": "+pivot.count)
                      map.set(pivot.value, pivot.count)
                    })


                    var data = []
                    for (var i = 0; i < this.chartOptions.xAxis.categories.length; i++) {
                      if (map.get(this.chartOptions.xAxis.categories[i]) != undefined) {
                        data[i] = map.get(this.chartOptions.xAxis.categories[i])
                      } else {
                        data[i] = 0
                      }
                    }

                    //console.log("DATA FINAL = ["+data+"]")

                    var colorSerie = this.colorSeriesChart(nameSerie)
                    //color por orden: this.chartOptions.colors[index%10]
                    this.chartOptions.series.push({name: nameSerie, data: data, color: colorSerie})

                  })
                }
            })

            if(this.chartOptions.xAxis.categories.length <= 10){
                this.chartOptions.plotOptions.series.pointWidth = 40
            }



        }
    }
</script>

