<template>

    <div>
        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><translate>Professorat</translate></li>
            </ol>
        </nav>

        <div class="row">

            <div class="col-sm-12 col-md-8">

                <h2><translate>Organitzacions</translate></h2>
                <nav class="navbar navbar-expand-lg navbar-upc bg-light-upc rounded">

                    <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

                    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

                        <ul class="navbar-nav mr-auto mt-lg-0">

                        </ul>

                        <form class="form-inline">
                            <div class="form-check mt-1 mb-1 form-control-sm">
                                <label class="form-check-label"><translate>Ordenar per:</translate></label>
                                <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                                    <option value="organitzacio_sigles asc">Sigles asc.</option>
                                    <option value="organitzacio_sigles desc">Sigles desc.</option>
                                    <option value="organitzacio_nom_ca asc" selected>Nom asc.</option>
                                    <option value="organitzacio_nom_ca desc">Nom desc.</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </nav>

                <div class="list-group list-group-flush mt-3">

                    <li v-for="organitzacio in organitzacions" :key="organitzacio.id" class="list-group-item list-group-item-action flex-column align-items-start">

                        <div class="d-flex w-100 justify-content-between mb-1">
                            <router-link :to="{name: 'organitzacio', params: {tipus:organitzacio.subtipus, id: organitzacio.id}}">
                              <span v-if="$language.current === 'ca'" >{{ organitzacio.organitzacio_nom_ca}}</span>
                              <span v-else-if="$language.current === 'es'" >{{ organitzacio.organitzacio_nom_es}}</span>
                              <span v-else>{{ organitzacio.organitzacio_nom_en}}</span>
                            </router-link>
                        </div>

                        <div class="text-secondary mb-1" >
                            <span>{{ organitzacio.organitzacio_sigles }}</span>
                        </div>

                        <div class="mb-1" >
                            <span><translate>{{ organitzacio.subtipus }}</translate></span>
                        </div>

                    </li>

                </div>

                <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>

            </div>

            <div id="filtres" class="col-sm-12 col-md-4 mt-0">
                <Facetes ref='facetes' :data="facetes" :numFiltres="1" :xarxaDesactivada="true" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort"></Facetes>
            </div>

        </div>

    </div>

</template>

<script>

    import axios from 'axios'
    import Facetes from '../components/Facetes.vue'
    import Pagination from "../components/Pagination"
    import PaginationHeader from "../components/PaginationHeader";

    export default {
        name: 'Organitzacions',
        components: {
            PaginationHeader,
            Facetes,
            Pagination
        },
        data() {

            return {
                organitzacions: [],
                facetes: [],

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "tipus:organitzacio",
                sort: "organitzacio_nom_ca asc",

                errors: [],
            }
        },

        methods: {
            setMetaTitle(){
              document.title = "Aprèn - "+this.$router.app.$gettext('Organitzacions')
            },
            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },

            solrQuery: async function (currentPage, length, query, sort) {

                this.$store.state.count = currentPage
                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage - 1) * length;

                //let response = await axios.get(this.API_URL + '/engine/execute?query=q=' + query + '??facet.field=subtipus??facet=on??facet.mincount=1??rows=' + length + '??start=' + start + '??sort=' + sort)
                let response = await axios.get(this.API_URL + '/engine/organitzacions?query='+query+'&rows='+length+'&start='+start+'&sort='+sort)
                this.organitzacions = response.data.response.docs;
                this.facetes = response.data.facet_counts.facet_fields;

                this.navigation_start = response.data.response.start + 1;
                this.navigation_total = response.data.response.numFound;
                this.navigation_length = length;
                this.navigation_end = response.data.response.start + this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start + this.navigation_length

                this.totalPages = Math.ceil(response.data.response.numFound / length);
                this.setMetaTitle()

            }
        },

        created() {
            if(this.$route.query.q !== undefined){
                this.query += " AND organitzacio_cerca:\""+this.$route.query.q+"\""
            }
            this.solrQuery(1, this.navigation_length, this.query, this.sort)
        }
    }

</script>

<style lang="css" scoped>

</style>




