<template>
    <!-- $route.params.id -->
    <div>
        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'professors'}"><translate>Professorat</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{professor.professor_nom}}</li>
            </ol>
        </nav>


        <div class="row">
            <div class="col-sm-12">

                <div class="card">
                    <div class="card-header d-flex">
                      <h3 class="flex-grow-1 h3" v-if="professor.professor_nom">{{professor.professor_nom.split(',')[1] +" "+ professor.professor_nom.split(',')[0]}}</h3>

                      <div v-if="professor.professor_futur">
                        <a v-if="professor.professor_dracId" class="mr-4" :href="'https://futur.upc.edu/' + professor.professor_dracId[0]" target="_blank" rel="noreferrer noopener">
                          <img :src="require('@/assets/images/futur-bola.png')" alt="Futur" >
                          <span class="ml-2">Futur</span>
                          <img src="../assets/images/external_link.png" class="ml-1 img-blank" alt="external link">
                        </a>
                        <a v-else class="mr-4" :href="'https://futur.upc.edu/' + professor.id" target="_blank" rel="noreferrer noopener">
                          <img :src="require('@/assets/images/futur-bola.png')" alt="Futur" class="mb-2" >
                          <span class="ml-2">Futur</span>
                          <img src="../assets/images/external_link.png" class="ml-1 img-blank" alt="external link">
                        </a>
                      </div>

                      <!--h3 class="flex-grow-1" v-if="professor.professor_nom">{{professor.professor_nom.split(',')[1] +" "+ professor.professor_nom.split(',')[0]}}</h3-->

                    </div>


                    <div class="card-body">

                        <div class="m-t-20 row">
                            <div class="col-md-2 col-xs-12 text-center mb-2">
                                <img v-if="professor.professor_foto" v-bind:src="professor.professor_foto" alt="user" class="img-responsive">
                                <div v-else ><i style="color: #5fa728;" class="fas fa-user fa-8x mb-2"></i></div>
                                <!--img v-else src="../assets/images/perfil.png" alt="user" class="img-responsive rounded float-left shadow"-->
                            </div>
                            <div class="col-md-8">

                                <div class="row mb-2" v-if="professor.professor_centre_docent">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Centre Docent</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-for="centre in professor.professor_centre_docent" v-bind:key="centre">
                                            <div class="mb-1">
                                              <router-link :to="{name: 'organitzacio', params: {tipus:'centre', id: centre}}">
                                                <translate>{{ centre }}</translate>
                                              </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="professor.professor_departament">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Departament</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-for="departament in professor.professor_departament" v-bind:key="departament">
                                            <div class="mb-1"><router-link :to="{name: 'organitzacio', params: {tipus:'departament', id: departament}}"><translate>{{ departament }}</translate></router-link></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="professor.professor_institut">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Institut</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <div v-for="institut in professor.professor_institut" v-bind:key="institut">
                                      <div class="mb-1"><router-link :to="{name: 'organitzacio', params: {tipus:'institut', id: institut}}"><translate>{{ institut }}</translate></router-link></div>
                                    </div>
                                  </div>
                                </div>

                                <hr v-if="professor.professor_centre_docent || professor.professor_departament || professor.professor_institut">

                                <div v-if="professor.professor_email" class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Correu electrònic</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                            {{professor.professor_email.toLowerCase().split('@')[0]}}<i class="fas fa-at fa-1x"></i>{{professor.professor_email.toLowerCase().split('@')[1]}}
                                        </p>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Dades de contacte</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                            <a :href="'https://directori.upc.edu/directori/dadesPersona.jsp?id=' + professor.professor_gaussId" target="_blank" rel="noreferrer noopener"><translate>Directori de la UPC</translate> <img src="../assets/images/external_link.png" class="img-blank" alt="external link"></a>
                                        </p>
                                    </div>
                                </div>

                                <hr>

                                <div class="row mb-2" v-if="professor.professor_assignatures!=null">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Assignatures</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-for="assignatura in professor.professor_assignatures" v-bind:key="assignatura">
                                            <div class="mb-1">
                                              <router-link :to="{name: 'assignatura', params: {id: assignatura}}"><translate>{{ assignatura }}</translate></router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="professor.professor_titulacions!=null">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Titulacions</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-for="titulacio in professor.professor_titulacions" v-bind:key="titulacio">
                                            <div class="mb-1"><router-link :to="{name: 'titulacio', params: {id: titulacio}}"><translate>{{ titulacio }}</translate></router-link></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 col-xs-12"><img src="../assets/images/fons_fitxa.png" alt="user" class="img-responsive rounded float-left"></div>
                        </div>

                    </div>

                    <div class="card-footer">
                      <div class="d-flex">
                        <div class="mr-auto p-0">
                          <AddThis publicId="ra-5ae95b2117917438" :data-title="professor.professor_nom" :async="true"/>
                        </div>
                        <div class="mt-2">
                          <div class="d-flex">

                            <img src="../assets/images/mini-upc.png" alt="user" class="mr-1 d-none d-lg-block">
                            <a class="mr-4 d-none d-lg-block" :href="'https://directori.upc.edu/directori/dadesPersona.jsp?id=' + professor.professor_gaussId" target="_blank" rel="noreferrer noopener"><translate>Directori de la UPC</translate> <img src="../assets/images/external_link.png" class="img-blank" alt="external link"></a>

                            <img src="../assets/images/mini-upc.png" alt="user" class="mr-1 d-none d-lg-block">
                            <a class="mr-4 d-none d-lg-block" :href="'https://discovery.upc.edu/discovery/search?tab=Alma_records&search_scope=Alma_records&vid=34CSUC_UPC:VU1&offset=0&query=creator,exact,' + professor.professor_nom" target="_blank" rel="noreferrer noopener"><translate>Cataleg biblioteques</translate> <img src="../assets/images/external_link.png" class="img-blank" alt="external link"></a>

                            <div v-if="professor.professor_futur">
                              <img src="../assets/images/mini-upc.png" alt="user" class="mr-1">
                              <a v-if="professor.professor_dracId" class="mr-4" :href="'https://futur.upc.edu/' + professor.professor_dracId[0]" target="_blank" rel="noreferrer noopener"><translate>Futur</translate> <img src="../assets/images/external_link.png" class="img-blank" alt="external link"></a>
                              <a v-else class="mr-4" :href="'https://futur.upc.edu/' + professor.id" target="_blank" rel="noreferrer noopener"><translate>Futur</translate> <img src="../assets/images/external_link.png" class="img-blank" alt="external link"></a>
                            </div>

                            <img src="../assets/images/mini-upc.png" alt="user" class="mr-1">
                            <a class="mr-4" :href="'https://apren.upc.edu/api/RSS/' + professor.id" target="_blank" rel="noreferrer noopener"><translate>RSS</translate></a>

                          </div>

                        </div>

                      </div>

                    </div>

                </div>
            </div>

        </div>

        <br>

        <div class="row d-none d-lg-block">
            <div class="col-sm-12">
                <b-tabs content-class="mt-3 col-12">
                    <b-tab active>
                        <template v-slot:title>
                            <h5><i class="fas fa-chart-bar"></i> <translate>Resum Gràfic</translate></h5>
                        </template>
                        <b-card-text>
                            <div class="row">
                                <div class="col-4">
                                    <ChartByTipus :facetes="facetes"></ChartByTipus>
                                </div>
                                <div class="col-8">
                                    <ChartByData :facetes="facetes" :facetesPivot="facetesPivot"></ChartByData>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <h5><i class="fas fa-globe-europe"></i> <translate>Resum Gràfic rights</translate></h5>
                        </template>
                        <b-card-text>
                          <div class="row">
                            <div class="col-12">
                              <ChartByDataAndAccess :facetes="facetes" :facetesPivot="facetesPivot"></ChartByDataAndAccess>
                            </div>
                          </div>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <br>


        <div class="row">

            <div class="col-sm-12 col-md-8">

              <h2><translate>Materials</translate></h2>
              <nav class="navbar navbar-expand-lg navbar-upc bg-light rounded">

                  <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

                  <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

                      <ul class="navbar-nav mr-auto mt-lg-0">

                      </ul>

                      <form class="form-inline">
                          <div class="form-check mt-1 mb-1 form-control-sm">
                              <label class="form-check-label"><translate>Ordenar per:</translate></label>
                              <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                                <option value="data asc, material_data_available asc"><translate>Data</translate> asc.</option>
                                <option value="data desc, material_data_available desc" selected><translate>Data</translate> desc.</option>
                                <option value="material_titol asc"><translate>Títol</translate> asc.</option>
                                <option value="material_titol desc"><translate>Títol</translate> desc.</option>
                              </select>
                          </div>
                      </form>
                  </div>
              </nav>

              <MaterialList :materials="materials"></MaterialList>

              <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>

            </div>

            <div id="filtres" class="col-sm-12 col-md-4 mt-0">
                <Facetes ref='facetes' :data="facetes" :numFiltres="7" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort"></Facetes>
            </div>

        </div>

    </div>

</template>

<script>

    import axios from 'axios'
    import Facetes from '../components/Facetes.vue'
    import ChartByTipus from '../components/ChartByTipus.vue'
    import ChartByData from '../components/ChartByData.vue'
    import ChartByDataAndAccess from '../components/ChartByDataAndAccess.vue'
    import Pagination from "../components/Pagination"
    import PaginationHeader from "../components/PaginationHeader"
    import Utils from "../extends/Utils"
    import MaterialList from "../components/MaterialList"
    import AddThis from 'vue-simple-addthis-share'

    export default {
        mixins: [Utils],
        name: 'Professor',
        components: {
          PaginationHeader,
          Facetes,
          ChartByTipus,
          ChartByData,
          ChartByDataAndAccess,
          Pagination,
          MaterialList,
          AddThis
        },

        data() {
            return {
                professor: {},
                materials: [],
                facetes: [],
                facetesPivot: [],

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "(material_professors:"+this.$route.params.id+" OR material_altresAutories:"+this.$route.params.id+")",
                sort: "data desc, material_data_available desc",
                errors: []
            }
        },

        methods: {

            setMetaTitle(elem){
              document.title = "Aprèn - " + elem.professor_nom.split(',')[1] +" "+ elem.professor_nom.split(',')[0]
            },

            solrQueryProfessor: function (gaussId) {
                axios.get(this.API_URL + '/engine/'+gaussId)
                    .then(response => {

                        if(response.data.response.numFound === 0) window.location = this.APREN_URL
                        this.professor = response.data.response.docs[0];
                        this.setMetaTitle(this.professor)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },

            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },
            solrQuery: function (currentPage, length, query, sort) {
                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage-1)*length

                axios.get(this.API_URL + '/engine/item?query='+query+'&rows='+length+'&start='+start+'&sort='+sort)
                //axios.get(this.API_URL + '/engine/execute?query=q='+query+'??facet.field=subtipus??facet.field=data??facet.field=material_rights??facet.field=material_idiomes??facet.field=material_arees_tematiques??facet.field=material_examen_resolt??facet.field=material_ods??facet.field=material_professors_faceta??facet.field=material_centres??facet.field=material_departaments??facet.field=material_campus??facet.field=material_titulacions??facet.field=material_assignatures_faceta??facet.pivot=subtipus,data??facet.pivot=subtipus,material_rights??facet.pivot=material_rights,subtipus??facet=on??f.data.facet.sort=index??f.material_ods.facet.sort=index??facet.mincount=1??rows='+length+'??start='+start+'??sort='+sort)
                    .then(response => {

                        this.materials = response.data.response.docs;
                        this.facetes = response.data.facet_counts.facet_fields;
                        this.facetesPivot = response.data.facet_counts.facet_pivot;

                        //reverse facet data
                        response.data.facet_counts.facet_fields.data = this.reverseFacetData(response.data.facet_counts.facet_fields.data)

                        this.navigation_start = response.data.response.start+1;
                        this.navigation_total = response.data.response.numFound;
                        this.navigation_length = length;
                        this.navigation_end = response.data.response.start+this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start+this.navigation_length

                        this.totalPages = Math.ceil(response.data.response.numFound / length);

                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            }
        },

        created() {
            this.solrQueryProfessor(this.$route.params.id)
            this.solrQuery(1, this.navigation_length, this.query, this.sort)
        }

    }

</script>

<style scoped>

    .card-header {
        border-bottom: 5px solid #5fa728 !important;
        /*e2ab17*/
    }

    .card-body {
        background-color: #ddf1ea;
        border-bottom: 1px solid #dfdfdf !important;
    }

    .card-footer {
        border-top: 5px solid #5fa728 !important;
    }


</style>
