<template>

    <div>
        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><translate>Materials</translate></li>
            </ol>
        </nav>

        <div class="row d-none d-lg-block">
            <div class="col-sm-12">
                <b-tabs content-class="mt-3 col-12">
                    <b-tab active>
                        <template v-slot:title>
                            <h5><i class="fas fa-chart-bar"></i> <translate>Resum Gràfic</translate></h5>
                        </template>
                        <b-card-text>
                            <div class="row">
                                <div class="col-4">
                                    <ChartByTipus :facetes="facetes"></ChartByTipus>
                                </div>
                                <div class="col-8">
                                    <ChartByData :facetes="facetes" :facetesPivot="facetesPivot"></ChartByData>
                                </div>

                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <h5><i class="fas fa-globe-europe"></i> <translate>Resum Gràfic rights</translate></h5>
                      </template>
                      <b-card-text>
                        <div class="row">
                          <div class="col-12">
                            <ChartByDataAndAccess :facetes="facetes" :facetesPivot="facetesPivot"></ChartByDataAndAccess>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <br>

        <div class="row">

            <div class="col-sm-12 col-md-8">

                <h2><translate>Materials</translate></h2>

                <nav class="navbar navbar-expand-lg navbar-upc bg-light-upc rounded">

                    <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

                    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul class="navbar-nav mr-auto mt-lg-0"></ul>
                        <form class="form-inline">
                            <div class="form-check mt-1 mb-1 form-control-sm">
                                <label class="form-check-label"><translate>Ordenar per:</translate></label>
                                <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                                    <option value="data asc, material_data_available asc"><translate>Data</translate> asc.</option>
                                    <option value="data desc, material_data_available desc" selected><translate>Data</translate> desc.</option>
                                    <option value="material_titol asc"><translate>Títol</translate> asc.</option>
                                    <option value="material_titol desc"><translate>Títol</translate> desc.</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </nav>

                <MaterialList :materials="materials"></MaterialList>

                <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>

            </div>

            <div id="filtres" class="col-sm-12 col-md-4 mt-0">
                <Facetes ref='facetes' :data="facetes" :numFiltres="7" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort" :xarxa-desactivada="false"></Facetes>
            </div>

        </div>
    </div>
</template>

<script>

    import axios from 'axios'
    import Utils from '../extends/Utils'
    import Facetes from '../components/Facetes.vue'
    import ChartByTipus from '../components/ChartByTipus.vue'
    import ChartByData from '../components/ChartByData.vue'
    import ChartByDataAndAccess from '../components/ChartByDataAndAccess.vue'
    import Pagination from "../components/Pagination"
    import PaginationHeader from "../components/PaginationHeader"
    import MaterialList from "../components/MaterialList"

    export default {
        mixins: [Utils],
        name: 'Materials',
        components: {
            PaginationHeader,
            Facetes,
            ChartByTipus,
            ChartByData,
            ChartByDataAndAccess,
            Pagination,
            MaterialList
        },
        data() {

            return {
                materials: [],
                facetes: [],
                facetesPivot: [],

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "tipus:material",
                sort: "data desc, material_data_available desc",

                errors: [],
            }
        },

        methods: {
            setMetaTitle(){
              document.title = "Aprèn - "+this.$router.app.$gettext('Materials')
            },

            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },

            solrQuery: function (currentPage, length, query, sort) {

                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage-1)*length;

                axios.get(this.API_URL +'/engine/item?query='+query+'&rows='+length+'&start='+start+'&sort='+sort)
                    .then(response => {

                        this.materials = response.data.response.docs;
                        this.facetes = response.data.facet_counts.facet_fields;
                        this.facetesPivot = response.data.facet_counts.facet_pivot;

                        //reverse facet data
                        response.data.facet_counts.facet_fields.data = this.reverseFacetData(response.data.facet_counts.facet_fields.data)

                        this.navigation_start = response.data.response.start+1;
                        this.navigation_total = response.data.response.numFound;
                        this.navigation_length = length;
                        this.navigation_end = response.data.response.start+this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start+this.navigation_length

                        this.totalPages = Math.ceil(response.data.response.numFound / length);
                        this.setMetaTitle()
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            }
        },

        created() {
            if(this.$route.query.q !== undefined){
                this.query += " AND material_cerca:\""+this.$route.query.q+"\""
            }
            this.solrQuery(1, this.navigation_length, this.query, this.sort)
        }
    }

</script>



