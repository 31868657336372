<template>

    <div>
        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><translate>Assignatures</translate></li>
            </ol>
        </nav>

        <div class="row">

            <div class="col-sm-12 col-md-8">

                <h2><translate>Assignatures</translate></h2>
                <nav class="navbar navbar-expand-lg navbar-upc bg-light-upc rounded">

                    <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

                    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

                        <ul class="navbar-nav mr-auto mt-lg-0">

                        </ul>

                        <form class="form-inline">

                            <div class="form-check mt-1 mb-1 form-control-sm">
                                <label class="form-check-label"><translate>Ordenar per:</translate></label>
                                <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                                    <option value="assignatura_nom_ca asc" selected>Nom asc.</option>
                                    <option value="assignatura_nom_ca desc">Nom desc.</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </nav>

                <div class="list-group list-group-flush mt-3">

                    <li v-for="(assignatura) in assignatures" :key="assignatura.id" class="list-group-item list-group-item-action flex-column align-items-start">

                        <div class="d-flex w-100 justify-content-between">
                            <router-link :to="{name: 'assignatura', params: {id: assignatura.id}}">
                                <span v-if="$language.current === 'ca'" >{{ assignatura.assignatura_nom_ca}}</span>
                                <span v-else-if="$language.current === 'es'" >{{ assignatura.assignatura_nom_es}}</span>
                                <span v-else>{{ assignatura.assignatura_nom_en}}</span>
                            </router-link>
                        </div>

                        <div>

                            <div class="mt-2 text-secondary font-weight-bold mb-1">{{ assignatura.assignatura_sigles }}</div>

                            <div class="mt-2" v-if="assignatura.assignatura_centres">
                                <span v-for="centre in (assignatura.assignatura_centres.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual))" v-bind:key="centre">
                                    <translate>Centres</translate>: <translate>{{centre}}</translate><br>
                                </span>
                            </div>

                            <div class="mt-2 text-secondary " v-if="assignatura.assignatura_titulacions">


                                    <div v-for="(titulacio, index) in assignatura.assignatura_titulacions" v-bind:key="titulacio">
                                        <div class="d-flex w-100 justify-content-between">
                                            <span><translate>{{titulacio}}</translate></span>
                                            <!--span v-if="getTipus(assignatura.assignatura_tipus, index) == 'OB'" class="ml-2 text-secondary font-weight-bold"><translate>OB</translate></span>
                                            <span v-else-if="getTipus(assignatura.assignatura_tipus, index) == 'OP'" class="ml-2 text-black-50 font-weight-bold"><translate>OP</translate></span>
                                            <span v-else-if="getTipus(assignatura.assignatura_tipus, index) == 'ALE'" class="ml-2 text-black-50 font-weight-bold"><translate>ALE</translate></span>
                                            <span v-else class="ml-2 font-weight-bold text-danger"><translate>OTHER</translate></span-->

                                            <span v-if="getTipus(assignatura.assignatura_tipus, index) === 'OB'" class="mb-1"><span class="badge badge-upc"><translate>OB</translate></span></span>
                                            <span v-else-if="getTipus(assignatura.assignatura_tipus, index) === 'OP'" class="mb-1"><span class="badge badge-success"><translate>OP</translate></span></span>
                                            <span v-else-if="getTipus(assignatura.assignatura_tipus, index) === 'ALE'" class="mb-1"> <span class="badge badge-danger"><translate>ALE</translate></span></span>
                                            <span v-else class="mb-1"><span class="badge badge-dark"><translate>OTHER</translate></span></span>


                                        </div>
                                    </div>
                            </div>

                        </div>
                    </li>
                </div>
                <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>

            </div>

            <div id="filtres" class="col-sm-12 col-md-4 mt-0">
                <Facetes ref='facetes' :data="facetes" :numFiltres="2" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort"></Facetes>
            </div>

        </div>
    </div>

</template>

<script>

    import axios from 'axios'
    import Facetes from '../components/Facetes.vue'
    import Pagination from "../components/Pagination"
    import PaginationHeader from "../components/PaginationHeader";

    export default {
        name: 'Assignatures',
        components: {
            PaginationHeader,
            Facetes,
            Pagination
        },
        data() {

            return {
                assignatures: [],
                facetes: Object,

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "tipus:assignatura",
                sort: "assignatura_nom_ca asc",

                errors: [],

            }
        },

        computed:{

        },
        methods: {
            setMetaTitle(){
              document.title = "Aprèn - "+this.$router.app.$gettext('Assignatures')
            },
            getTipus(tipus, index) {
                let tipusStr = tipus+""
                return tipusStr.split(",")[index];
            },
            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },
            solrQuery: function (currentPage, length, query, sort) {

                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage-1)*length;

                axios.get(this.API_URL + '/engine/assignatures?query='+query+'&rows='+length+'&start='+start+'&sort='+sort)
                    .then(response => {

                        this.assignatures = response.data.response.docs;
                        this.facetes = response.data.facet_counts.facet_fields;

                        this.navigation_start = response.data.response.start+1;
                        this.navigation_total = response.data.response.numFound;
                        this.navigation_length = length;
                        this.navigation_end = response.data.response.start+this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start+this.navigation_length

                        this.totalPages = Math.ceil(response.data.response.numFound / length);
                        this.setMetaTitle()

                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            }
        },

        created() {
            if(this.$route.query.q !== undefined){
                this.query = "assignatura_cerca:\""+this.$route.query.q+"\""
            }
            this.solrQuery(1, this.navigation_length, this.query, this.sort)
        },

    }


</script>

<style lang="css">

</style>




