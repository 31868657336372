<template>

    <div>
        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'materials'}"><translate>Materials</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ material.material_titol}}</li>
            </ol>
        </nav>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="h3">{{material.material_titol}}</h3>
                    </div>

                    <div class="card-body">

                        <div class="m-t-20 row">

                            <div class="col-md-2 col-xs-12 mb-4">

                              <a v-bind:href="material.material_original" target="_blank" rel="noreferrer noopener">
                                <img v-if="material.material_thumbnail" :src="material.material_thumbnail" @error="imageUrlAlt($event, material.material_rights)" alt="thumbnail" class="img-responsive float-left img-thumbnail">
                                <img v-else src="../assets/images/no-photo.png" alt="thumbnail">
                              </a>

                            </div>
                            <div v-if="material.material_comunitat" class="col-md-8">

                                <div v-if="material.material_comunitat[0] === 'llibres' || material.material_comunitat[0] === 'examens' || material.material_comunitat[0] === 'videodocent'">

                                  <div class="row mb-2" v-if="material.material_professors">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>autor</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                      <div v-for="(professor) in material.material_professors" :key="professor">
                                        <div v-if="professor.indexOf('##') === -1 || professor.split('##')[1] === ''">
                                          <p class="mb-1">{{professor.split("##")[0]}}</p>
                                        </div>
                                        <div v-else>
                                          <router-link :to="{name: 'professor', params: {id: professor.split('##')[1]}}">
                                            <p class="mb-1">{{professor.split("##")[0]}}</p>
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row mb-2" v-if="material.material_altresAutories">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Editor</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                      <div v-for="(altre) in material.material_altresAutories" :key="altre">
                                        <div v-if="altre.indexOf('##') === -1 || altre.split('##')[1] === ''">
                                          <p class="mb-1">{{altre}}</p>
                                        </div>
                                        <div v-else>
                                          <router-link :to="{name: 'professor', params: {id: altre.split('##')[1]}}">
                                            <p class="mb-1">{{altre.split("##")[0]}}</p>
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="material.material_comunitat[0] === 'oer'" >
                                  <div class="row mb-2" v-if="material.material_professors">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>autor</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                      <div v-for="(professor) in material.material_professors" :key="professor">
                                        <div v-if="professor.indexOf('##') === -1 || professor.split('##')[1] === ''">
                                          <p class="mb-1">{{professor.split("##")[0]}}</p>
                                        </div>
                                        <div v-else>
                                          <router-link :to="{name: 'professor', params: {id: professor.split('##')[1]}}">
                                            <p class="mb-1">{{professor.split("##")[0]}}</p>
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row mb-2" v-if="material.material_altresAutories">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>altres autors</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                      <div v-for="(altre) in material.material_altresAutories" :key="altre">
                                        <p class="mb-1">{{altre}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="material.material_comunitat[0] === 'pfc'" >

                                  <div class="row mb-2" v-if="material.material_professors">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>tutor</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                      <div v-for="(professor) in material.material_professors" :key="professor">
                                        <div v-if="professor.indexOf('##') === -1 || professor.split('##')[1] === ''">
                                          <p class="mb-1">{{professor.split("##")[0]}}</p>
                                        </div>
                                        <div v-else>
                                          <router-link :to="{name: 'professor', params: {id: professor.split('##')[1]}}">
                                            <p class="mb-1">{{professor.split("##")[0]}}</p>
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row mb-2" v-if="material.material_altresAutories">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Estudiant</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                      <div v-for="(altre) in material.material_altresAutories" :key="altre">
                                        <p class="mb-1">{{altre}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>tipus document</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1"><translate>{{material.subtipus}}</translate></p>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>data</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">{{material.data}}</p>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>rights</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                          <translate>{{material.material_rights}}</translate>
                                          <span class="mr-2"></span>
                                          <i v-if="material.material_rights === 'Open Access'" class="fas fa-lock-open text-warning" :title="material.material_rights"></i>
                                          <img v-else-if="material.material_rights === 'Restricted access to the UPC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the UPC academic community">
                                          <img v-else-if="material.material_rights === 'Restricted access to the CSUC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the CSUC academic community">
                                          <i v-else class="fas fa-lock text-warning" :title="material.material_rights"></i>
                                        </p>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_rights_uri != null">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Llicència</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <p class="mb-1"><a v-bind:href="material.material_rights_uri" target="_blank" rel="noreferrer noopener">{{material.material_rights_uri}}</a> </p>
                                  </div>
                                </div>


                                <div class="row mb-2" v-if="material.material_publisher != null">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Editorial</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <p class="mb-1">{{material.material_publisher}}</p>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_academicyear != null">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>any academic</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <p class="mb-1">{{material.material_academicyear}}</p>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_version != null">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Versió</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <p class="mb-1">{{material.material_version}}</p>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_has_version != null">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Última versió</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <p class="mb-1">
                                      <router-link :to="{name: 'material', params: {id: material.material_has_version}}">{{material.material_has_version}}</router-link>
                                    </p>
                                  </div>
                                </div>

                                <hr>

                                <div class="row mb-2" v-if="material.material_isbn">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold">ISBN</p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-for="isbn in material.material_isbn" v-bind:key="isbn">
                                          <span class="mb-2">{{isbn}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_dl">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Dipòsit legal</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        {{material.material_dl}}
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_centres">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Centre</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-for="centre in material.material_centres" v-bind:key="centre">
                                            <span class="mb-1"><router-link :to="{name: 'organitzacio', params: {tipus:'centre', id: centre}}"><translate>{{ centre }}</translate></router-link></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_assignatures">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Assignatures</translate></p></div>
                                    <div class="col-md-9 col-xs-12 text-secondary">
                                        <div v-for="assignatura in material.material_assignatures" v-bind:key="assignatura">
                                            <span class="mb-1"><router-link :to="{name: 'assignatura', params: {id: assignatura}}"><translate>{{ assignatura }}</translate></router-link></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_titulacions">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Titulacions</translate></p></div>
                                    <div class="col-md-9 col-xs-12 text-secondary">
                                        <div v-for="titulacio in material.material_titulacions" v-bind:key="titulacio">
                                            <span class="mb-1"><router-link :to="{name: 'titulacio', params: {id: titulacio}}"><translate>{{ titulacio }}</translate></router-link></span>
                                        </div>
                                    </div>
                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold">UPCommons</p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1" v-if="material" ><a :href="'http://hdl.handle.net/' + material.id.replace('_','/')" target="_blank" rel="noreferrer noopener">http://hdl.handle.net/{{material.id.replace('_','/')}}</a></p>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_zonavideo">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold">Zonavideo</p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <p class="mb-1"><a :href="material.material_zonavideo" target="_blank" rel="noreferrer noopener">{{material.material_zonavideo}}</a></p>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="material.material_resum">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Resum</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-for="(resum, index) in material.material_resum" v-bind:key="resum">
                                            <span v-if="index !== (material.material_resum.length-1)" class="mb-4">{{resum}}</span>
                                            <span v-else>{{resum}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4 mb-2" v-if="material.subtipus === 'Audiovisual'">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Vídeo</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <div v-if="material.material_zonavideo">
                                          <iframe allowfullscreen="allowfullscreen" style="border: 0;" height="295" width="100%" id="pumukitiframe" :src="material.material_zonavideo.replaceAll('/video/', '/iframe/')"></iframe>
                                        </div>
                                    </div>
                                </div>


                                <hr v-if="material.material_colaborador">
                                <div class="row mb-2" v-if="material.material_colaborador">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Entitat col·laboradora</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                          <div v-for="(colaborador, index) in material.material_colaborador" v-bind:key="colaborador">
                                              <span v-if="index !== (material.material_colaborador-1)" class="mb-4">{{colaborador}}</span>
                                              <span v-else>{{resum}}</span>
                                          </div>
                                  </div>
                                </div>

                                <hr v-if="material.material_geolocalitzacio">
                                <div class="row mb-2" v-if="material.material_geolocalitzacio">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Localització</translate></p></div>
                                  <div class="col-md-9 col-xs-12">
                                      <span v-for="(geo, index) in material.material_geolocalitzacio" v-bind:key="geo">
                                          <small style="color: #007bc1; font-weight: 200;"><i class="fas fa-map-marker-alt"></i><sup>{{ index+1 }}</sup> <a href="javascript:void(0);" @click='selectGeolocation(geo)' rel="nofollow">{{geo.split(";")[2].split("=")[1]}}</a></small><br>
                                      </span>


                                      <l-map ref="mapControl" :options="mapOptions" style="height: 300px" @ready="onReady">
                                        <l-tile-layer :url="url" :attribution="attribution"/>
                                        <l-marker :key="index"
                                            v-for="(geo, index) in material.material_geolocalitzacio"
                                            :lat-lng='[geo.split(";")[1].split("=")[1], geo.split(";")[0].split("=")[1]]'>
                                          <l-popup>
                                            <div>
                                              {{index+1}} - {{geo.split(";")[2].split("=")[1]}}
                                            </div>
                                          </l-popup>
                                        </l-marker>
                                      </l-map>
                                  </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-xs-12"><img src="../assets/images/fons_fitxa.png" alt="user" class="img-responsive rounded float-left"></div>
                        </div>
                    </div>

                    <div class="card-footer">
                      <div class="d-flex">
                        <div class="mr-auto p-0">
                          <AddThis publicId="ra-5ae95b2117917438" :data-title="material.material_titol" :async="true"/>
                        </div>
                        <div class="mt-2">
                          <div class="d-flex justify-content-end">
                            <img src="../assets/images/mini-upc.png" alt="user" class="mr-1">
                            <a v-if="material.id" :href="'http://hdl.handle.net/' + material.id.replace('_','/')" target="_blank" rel="noreferrer noopener">UPCommons <img src="../assets/images/external_link.png" class="img-blank" alt="external link"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

        </div>

        <h2 class="mt-5" v-if="material.material_professors!=null"><translate>participant</translate></h2>
        <div class="row">
            <div class="col-sm-12 col-md-12 mt-2">
                <ul class="list-unstyled" style="column-count: 1;">
                    <div v-for="(professor) in material.material_professors" :key="professor">
                        <div v-if="professor.indexOf('##') === -1 ||professor.split('##')[1] === ''">
                            <li class="mb-3"><i class="fas fa-user mr-2 text-secondary"></i> {{professor.split("##")[0]}}</li>
                        </div>
                        <div v-else>
                            <router-link :to="{name: 'professor', params: {id: professor.split('##')[1]}}">
                                <li class="mb-3"><i class="fas fa-user mr-2 text-secondary"></i> {{professor.split("##")[0]}}</li>
                            </router-link>
                        </div>
                    </div>
                </ul>
            </div>
        </div>

        <h2 class="mt-4" v-if="material.material_zonavideo == null"><translate>Arxius</translate></h2>
        <div class="row" v-if="material.material_zonavideo == null">
            <div class="col-sm-12 col-md-12 list-group list-group-flush">

                <a :href="arxiu" target="blank_" v-for="(arxiu) in material.material_original" :key="arxiu" class="list-group-item">
                    <!--i class="fas fa-file mr-2 text-secondary"></i> {{arxiu.split("/")[arxiu.split("/").length-1]}}-->
                    <!-- Estos splits son unicamente para coger la extensión del fichero (.pdf) -->
                    <i class="fas fa-file mr-2 text-secondary"></i> {{decodeURIComponent(arxiu.split("/")[arxiu.split("/").length-1])}}
                    <!--i class="fas fa-file mr-2 text-secondary"></i> {{material.id.substring(5)+"-"+index+arxiu.split("/")[arxiu.split("/").length-1].substring(arxiu.split("/")[arxiu.split("/").length-1].indexOf("."))}}-->
                </a>

            </div>
        </div>
    </div>
</template>

<script>

    import AddThis from 'vue-simple-addthis-share'
    import axios from 'axios'

    import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
    import Utils from "../extends/Utils";

    export default {
        mixins: [Utils],
        name: 'Material',
        components: {
          AddThis,
          LMap,
          LTileLayer,
          LMarker,
          LPopup

        },
        data() {
            return {
                material: {},
                materials: [],
                facetes: [],
                facetesPivot: [],

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "material_persones:"+this.$route.params.id,
                sort: "data desc, material_data_available desc",
                errors: [],

                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | <a href="https://geocommons.upc.edu" target="_blank">GEOCommons</a>',
                mapOptions: {
                  zoomSnap: 0.5
                },
                fitBounds: [],
            }
        },


        methods: {
            setMetaTitle(elem){
              document.title = "Aprèn - " + elem.material_titol
            },
            solrQueryMaterial: function (id) {
                axios.get(this.API_URL + '/engine/'+id)
                    .then(response => {
                        if(response.data.response.numFound === 0) window.location = this.APREN_URL
                        this.material = response.data.response.docs[0];
                        this.setMetaTitle(this.material)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },
            /*onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },*/
            /*solrQuery: function (currentPage, length, query, sort) {

                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage-1)*length

                axios.get(this.API_URL + '/engine/execute?query=q='+query+'??facet.field=subtipus??facet.field=data??facet.field=material_asignatura_faceta??facet.field=material_centre??facet.pivot=subtipus,data??facet=on??facet.mincount=1??rows='+length+'??start='+start+'??sort='+sort)
                    .then(response => {

                        this.materials = response.data.response.docs;
                        this.facetes = response.data.facet_counts.facet_fields;
                        this.facetesPivot = response.data.facet_counts.facet_pivot;

                        this.navigation_start = response.data.response.start+1;
                        this.navigation_total = response.data.response.numFound;
                        this.navigation_length = length;
                        this.navigation_end = response.data.response.start+this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start+this.navigation_length

                        this.totalPages = Math.ceil(response.data.response.numFound / length);

                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },*/

            onReady() {

              this.material.material_geolocalitzacio.forEach((value) => {

                let lat = value.split(";")[1].split("=")[1]
                let lon = value.split(";")[0].split("=")[1]

                let coord = []
                coord.push(lat)
                coord.push(lon)
                this.fitBounds.push(coord)

              });

              this.$refs.mapControl.mapObject.fitBounds(this.fitBounds)

              if (this.material.material_geolocalitzacio.length === 1) {
                this.$refs.mapControl.setZoom(8);
              }
            },

            selectGeolocation(geo){
              let lat = geo.split(";")[1].split("=")[1]
              let lon = geo.split(";")[0].split("=")[1]
              let coord = []
              coord.push(lat)
              coord.push(lon)
              this.$refs.mapControl.setZoom(12);
              this.$refs.mapControl.mapObject.setView(coord)

            }

        },

        created() {
            this.solrQueryMaterial(this.$route.params.id)

        }
    }
</script>

<style scoped>
    .card-header {
        border-bottom: 5px solid #5fa728 !important;
    }

    .card-body {
        background-color: #ddf1ea;
        border-bottom: 1px solid #dfdfdf !important;
    }

    .card-footer {
        border-top: 5px solid #5fa728 !important;
    }

    .list-group a { background: whitesmoke; }
    .list-group a:nth-child(odd) { background: white; }

</style>
