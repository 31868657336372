<template>

    <!-- Modal -->

    <b-modal static centered :id="id" title="Informació" ok-only>

        <div v-if="contingut.substr(contingut.indexOf('_')) == '_actiu'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca per la vinculació del professorat amb la UPC.</p>
                <p>Al costat de cada tipus de vinculació s'hi indica el nombre de professors i professores que tenen aquesta vinculació.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por la vinculación del profesorado con la UPC.</p>
                <p>Junto a cada tipo de vinculación se indica el número de profesores y profesoras que tienen esa vinculación.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to the teacher's relationship with the UPC.</p>
                <p>Next to each type of relationship you will see the number of teachers with that relationship.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'assignatura_activa'">
          <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
              <p>Filtre que permet refinar la cerca per assignatures actives o no actives.</p>
          </span>
          <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
              <p>Filtro que permite refinar la búsqueda por asignaturas activas o no activas.</p>
          </span>
          <span v-else class="mt-3 text-secondary font-weight-light">
              <p>Filter to limit your search according to the active or non-active courses.</p>
          </span>
        </div>


        <div v-else-if="contingut == 'titulacio_activa'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca per titulacions actives o no actives.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por titulaciones activas o no activas.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to the active or non-active degrees.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'titulacio_nivell'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
              <p>Filtre que permet refinar la cerca pel nivell de les titulacions.</p>
              <p>Al costat de cada nivell hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquest nivell.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
              <p>Filtro que permite refinar la búsqueda por el nivel de las titulaciones.</p>
              <p>Al lado de cada nivel se indica el número de contenidos que se obtienen si se aplica el filtro para ese nivel.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
              <p>Filter to limit your search according to the degree levels.</p>
              <p>Next to each condition for degree levels you will see the number of contents that will be displayed if you apply the filter for that degree level.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'material_professors_faceta'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca pel professorat que ha participat en l’elaboració d’algun dels continguts que es mostren actualment.</p>
                <p>Es mostra un llistat del professorat que ha participat més en l’elaboració dels continguts que es mostren actualment.</p>
                <p>Al costat de cada professor o professora hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre i, en alguns casos, també hi trobareu una icona que us permetrà anar a la fitxa del professor o professora dins de l’Aprèn.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por el profesorado que ha participado en la elaboración de alguno de los contenidos que se muestran en pantalla.</p>
                <p>Se muestra un listado del profesorado que más ha participado en la elaboración de los contenidos mostrados actualmente.</p>
                <p>Al lado de cada profesor o profesora se indica el número de contenidos que se obtiene si se aplica el filtro y, en algunos casos, también se muestra un icono que permite ir a la ficha del profesor o profesora en Aprèn.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to the teaching staff who have participated in producing one or more of the contents currently displayed.</p>
                <p>It displays a list of the teaching staff who have participated most in producing the contents currently displayed.</p>
                <p>Next to each professor’s name you will see the number of contents that will be displayed if you apply the filter. In some cases, you will also see an icon that you can click on to bring up the professor’s page in the Aprèn system.</p>
            </span>
        </div>


        <div v-else-if="contingut.substr(contingut.indexOf('_')) == '_centre_docent' || contingut.substr(contingut.indexOf('_')) == '_centres'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca pels centres docents relacionats amb algun dels continguts que es mostren actualment.</p>
                <p>Es mostra un llistat dels centres docents que estan relacionats amb més dels continguts que es mostren actualment.</p>
                <p>Al costat de cada centre docent hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquest centre docent i, en alguns casos, també hi trobareu una icona que us permetrà anar a la fitxa del centre docent dins de l’Aprèn.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por los centros docentes relacionados con alguno de los contenidos que se muestran en pantalla.</p>
                <p>Se presenta un listado de centros docentes que están relacionados con los contenidos mostrados actualmente.</p>
                <p>Al lado de cada centro docente se indica el número de contenidos que se obtiene si se aplica el filtro para ese centro docente y, en algunos casos, también se muestra un icono que permite ir a la ficha del centro docente en Aprèn.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to schools related to one or more of the contents currently displayed.</p>
                <p>It displays a list of the schools related to the greatest number of the contents currently displayed.</p>
                <p>Next to the name of each school you will see the number of contents that will be displayed if you apply the filter for that school. In some cases, you will also see an icon that you can click on to bring up the school’s page in the Aprèn system.</p>

            </span>
        </div>


        <div v-else-if="contingut.substr(contingut.indexOf('_')) == '_departament' || contingut.substr(contingut.indexOf('_')) == '_departaments'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca pels departaments relacionats amb algun dels continguts que es mostren actualment.</p>
                <p>Al costat de cada departament hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquest departament i, en alguns casos, també hi trobareu una icona que us permetrà anar a la fitxa del departament dins de l’Aprèn.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por los departamentos relacionados con alguno de los contenidos que se muestran en pantalla.</p>
                <p>Al lado de cada departamento se indica el número de contenidos que se obtiene si se aplica el filtro para ese departamento y, en algunos casos, también se muestra un icono que permite ir a la ficha del departamento en Aprèn.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to departments related to one or more of the contents currently displayed.</p>
                <p>Next to the name of each department you will see the number of contents that will be displayed if you apply the filter for that department. In some cases, you will also see an icon that you can click on to bring up the department’s page in the Aprèn system.</p>
            </span>
        </div>


        <div v-else-if="contingut.substr(contingut.indexOf('_')) == '_campus'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca pels campus relacionats amb alguns dels continguts que es mostren actualment.</p>
                <p>Al costat de cada campus hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre i, en alguns casos, també hi trobareu una icona que us permetrà anar a la fitxa del campus dins de l’Aprèn.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por los campus relacionados con algunos de los contenidos que se muestran en pantalla.</p>
                <p>Al lado de cada campus se indica el número de contenidos que se obtienen si se aplica el filtro y, en algunos casos, también se muestra un icono que permite ir a la ficha del campus en Aprèn.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to campuses related to one or more of the contents currently displayed.</p>
                <p>Next to the name of each campus you will see the number of contents that will be displayed if you apply the filter for that campus. In some cases, you will also see an icon that you can click on to bring up the campus’s page in the Aprèn system.</p>
            </span>
        </div>


        <div v-else-if="contingut.substr(contingut.indexOf('_')) == '_titulacions' || contingut.substr(contingut.indexOf('_')) == '_titulacions_actives'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca per les titulacions actives relacionades amb alguns dels continguts que es mostren actualment.</p>
                <p>Al costat de cada titulació hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre i, en alguns casos, també hi trobareu una icona que us permetrà anar a la fitxa de la titulació dins de l’Aprèn.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por las titulaciones activas relacionadas con algunos de los contenidos que se muestran en pantalla.</p>
                <p>Al lado de cada titulación se indica el número de contenidos que se obtienen si se aplica ese filtro y, en algunos casos, también se muestra un icono que permite ir a la ficha de la titulación en Aprèn.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to current degrees related to one or more of the contents currently displayed.</p>
                <p>Next to the name of each degree you will see the number of contents that will be displayed if you apply the filter for that degree. In some cases, you will also see an icon that you can click on to bring up the degree’s page in the Aprèn system.</p>
            </span>
        </div>


        <div v-else-if="contingut.substr(contingut.indexOf('_')) == '_titulacions_no_actives'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca per les titulacions no actives relacionades amb alguns dels continguts que es mostren actualment.</p>
                <p>Al costat de cada titulació no activa hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre i, en alguns casos, també hi trobareu una icona que us permetrà anar a la fitxa de la titulació no activa dins de l’Aprèn.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por las titulaciones no activas relacionadas con algunos de los contenidos que se muestran en pantalla.</p>
                <p>Al lado de cada titulación no activa se indica el número de contenidos que se obtienen si se aplica ese filtro y, en algunos casos, también se muestra un icono que permite ir a la ficha de la titulación no activa en Aprèn.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to former degrees related to one or more of the contents currently displayed.</p>
                <p>Next to the name of each former degree you will see the number of contents that will be displayed if you apply the filter for that degree. In some cases, you will also see an icon that you can click on to bring up the former degree’s page in the Aprèn system.</p>
            </span>
        </div>


        <div v-else-if="contingut.substr(contingut.indexOf('_')) == '_assignatures' || contingut.substr(contingut.indexOf('_')) == '_assignatures_faceta' ">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca per les assignatures relacionades amb alguns dels continguts que es mostren actualment.</p>
                <p>Al costat de cada assignatura hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre i, en alguns casos, també hi trobareu una icona que us permetrà anar a la fitxa de l’assignatura dins de l’Aprèn.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por las asignaturas relacionadas con algunos de los contenidos que se muestran en pantalla.</p>
                <p>Al lado de cada asignatura se indica el número de contenidos que se obtienen si se aplica ese filtro y, en algunos casos, también se muestra un icono que permite ir a la ficha de la asignatura en Aprèn.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to subjects related to one or more of the contents currently displayed.</p>
                <p>Next to the name of each subject you will see the number of contents that will be displayed if you apply the filter for that subject. In some cases, you will also see an icon that you can click on to bring up the subject’s page in the Aprèn system.</p>
            </span>
        </div>


        <!--div v-else-if="contingut == 'subtipus'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca pel tipus d'organització.</p>
                <p>El llistat en conté tots els tipus, ordenats descendentment pel nombre d'unitats de cada tipus, respecte a les unitats que es mostren actualment.</p>
                <p>Al costat de cada tipus hi trobareu una indicació del nombre d'unitats que obtindríeu si apliqueu el filtre per a aquest tipus.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por el tipo de organización.</p>
                <p>El listado contiene todos los tipos, ordenados de forma descendiente por el número de unidades de cada tipo, respecto a las unidades mostradas.</p>
                <p>Al lado de cada tipo se muestra una indicación del número de unidades que se obtiene si se aplica el filtro para esa tipología.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search by organisation type.</p>
                <p>The list shows organisation types sorted in descending order according to the number of units of each type.</p>
                <p>Next to each organisation type you will see the number of units that will be displayed if you apply the filter for that type.</p>
            </span>
        </div-->


        <div v-else-if="contingut == 'assignatura_tipus' || contingut == 'subtipus'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca pel tipus de continguts que es mostren actualment.</p>
                <p>El llistat conté els diversos tipus de continguts, ordenats descendentment pel nombre de documents.</p>
                <p>Al costat de cada tipus hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquest tipus.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por el tipo de contenido de los mostrados actualmente.</p>
                <p>El listado contiene varios tipos de contenidos, ordenados de forma descendiente por el número de documentos.</p>
                <p>Al lado de cada tipo se indica el número de contenidos que se obtiene si se aplica el filtro para ese tipo.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search to a particular content type from among those currently displayed.</p>
                <p>The list shows content types sorted in descending order according to the number of documents.</p>
                <p>Next to each content type you will see the number of contents that will be displayed if you apply the filter for that type.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'data'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca pel tipus de continguts que es mostren actualment.</p>
                <p>El llistat conté els diversos tipus de continguts, ordenats descendentment pel nombre de documents.</p>
                <p>Al costat de cada tipus hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquest tipus.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por el tipo de contenido de los mostrados actualmente.</p>
                <p>El listado contiene varios tipos de contenidos, ordenados de forma descendiente por el número de documentos.</p>
                <p>Al lado de cada tipo se indica el número de contenidos que se obtiene si se aplica el filtro para ese tipo.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search to a particular content type from among those currently displayed.</p>
                <p>The list shows content types sorted in descending order according to the number of documents.</p>
                <p>Next to each content type you will see the number of contents that will be displayed if you apply the filter for that type.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'material_rights'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca per les condicions d’accés que tenen els continguts.</p>
                <p>Es mostra un llistat amb els diferents nivells d’accés que tenen els continguts, ordenats descendentment pel nombre de continguts.</p>
                <p>Al costat de cada condició d’accés hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquesta condició.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda por las condiciones de acceso que tienen los contenidos.</p>
                <p>Se muestra un listado con los distintos niveles de acceso que tienen los contenidos, ordenados descendientemente por el número de contenidos.</p>
                <p>Al lado de cada condición de acceso se indica el número de contenidos que se obtienen si se aplica el filtro.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to the conditions for accessing the contents.</p>
                <p>It displays a list with the levels of access to the contents, sorted in descending order according to the number of contents.</p>
                <p>Next to each condition for access you will see the number of contents that will be displayed if you apply the filter for that condition.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'material_arees_tematiques'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca a partir dels àmbits de coneixement propis de la UPC.</p>
                <p>Es mostra un llistat amb els diferents àmbits de coneixement assignats als continguts, ordenats descendentment pel nombre de continguts.</p>
                <p>Al costat de cada àmbit hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquesta condició.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda a partir de los ámbitos de conocimiento propios de la UPC.</p>
                <p>Se muestra un listado con los distintos ámbitos de conocimiento asignados a los contenidos, ordenados descendientemente por el número de contenidos.</p>
                <p>Al lado de cada condición de acceso se indica el número de contenidos que se obtienen si se aplica el filtro para esa condición.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to the UPC’s areas of knowledge.</p>
                <p>It displays a list with the areas of knowledge assigned to the contents, sorted in descending order according to the number of contents.</p>
                <p>Next to each area you will see the number of contents that will be displayed if you apply the filter for that condition.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'material_idiomes'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca a partir de l’idioma del contingut.</p>
                <p>Es mostra un llistat amb els diferents idiomes en què s’han elaborat els continguts, ordenats descendentment pel nombre de continguts.</p>
                <p>Al costat de cada idioma hi trobareu una indicació del nombre de continguts que obtindríeu si apliqueu el filtre per a aquesta condició.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda a partir del idioma del contenido.</p>
                <p>Se muestra un listado con los distintos idiomas en los que se han elaborado los contenidos, ordenados descendientemente por el número de contenidos.</p>
                <p>Al lado de cada idioma se indica el número de contenidos que se obtienen si se aplica el filtro para esa condición.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search according to content language.</p>
                <p>It displays a list with the languages used to produce the contents, sorted in descending order according to the number of contents.</p>
                <p>Next to each language you will see the number of contents that will be displayed if you apply the filter for that condition.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'material_examen_resolt'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Filtre que permet refinar la cerca mostrant només els exàmens que n’incorporen la solució.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Filtro que permite refinar la búsqueda mostrando únicamente aquellos exámenes que incorporan la solución.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Filter to limit your search by showing only those exams that include the solutions.</p>
            </span>
        </div>

        <div v-else-if="contingut == 'material_ods'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
              <p>Filtre que permet refinar la cerca recuperant aquells continguts relacionats amb algun dels Objectius de Desenvolupamet Sostenible (ODS).</p>
              <p>El llistat conté els objectius relacionats amb el continguts, ordenats descendenment pel número de continguts s'hi han relacionat, respecte dels continguts visualitzats actualment.</p>
              <p>Al costat de cada objectiu trobareu una indicació del número de continguts que obtindríeu si apliqueu el filtre per aquell objectiu.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
              <p>Filtro que permite refinar la búsqueda recuperando los contenidos relacionados con uno de los Objetivos de Desarrollo Sostenible.</p>
              <p>El listado contiene los objetivos relacionados con los contenidos docentes, ordenados de forma descendente por el número de contenidos que se han relacionado.</p>
              <p>Junto a cada objetivo se indica el número de contenidos que obtiene si se aplica el filtro para ese objetivo.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
              <p>Filter to limit your search according to Goals for Sustainable Development.</p>
              <p>The list shows the goals related to produce the contents, sorted in descending order according to the number of contents.</p>
              <p>Next to each goal you will see the number of contents that will be displayed if you apply the filter for that condition.</p>
            </span>
        </div>


        <div v-else-if="contingut == 'modal_search'">
            <span v-if="language == 'ca'" class="mt-3 text-secondary font-weight-light">
                <p>Ajuda per a la cerca:</p>
                <p>- Les paraules separades per espais se cerquen de manera independent als diferents camps de cerca. És a dir, si cerques 'càlculs numèrics', se't mostraran els resultats que continguin 'càlculs numèrics', 'càlculs' i 'numèrics'.</p>
                <p>- La cerca és per aproximació, és a dir, si cerques la paraula 'càlculs', també et trobarà resultats que continguin paraules derivades com 'càlcul', 'calcular', etc.</p>
                <p>- Per fer una cerca per frase i no per paraules, engloba totes les paraules entre cometes dobles. Exemple: "formació d'un enginyer civil".</p>
                <p>- La cerca és insensible a accents i majúscules.</p>
            </span>
            <span v-else-if="language == 'es'" class="mt-3 text-secondary font-weight-light">
                <p>Ayuda para la búsqueda:</p>
                <p>- Las palabras separadas por espacios se buscan de forma independiente en los diferentes campos de búsqueda. Es decir, si buscas 'cálculos numéricos', se mostrarán los resultados que contengan 'cálculos numéricos', 'cálculos' y 'numéricos'.</p>
                <p>- La búsqueda es por aproximación, es decir, si buscas la palabra 'cálculos', también recuperarás resultados que contengan palabras derivadas como 'cálculo', 'calcular', etc.</p>
                <p>- Para realizar una búsqueda por frase y no por palabras, engloba todas las palabras entre comillas dobles. Ejemplo: ""formación de un ingeniero civil"".</p>
                <p>- La búsqueda es insensible a acentos y mayúsculas.</p>
            </span>
            <span v-else class="mt-3 text-secondary font-weight-light">
                <p>Search tips:</p>
                <p>- Words separated by spaces are searched independently in the search fields. If you enter 'numerical calculations', the search will find items that contain either 'numerical calculations', 'numerical' or 'calculations'.</p>
                <p>- The search includes variations. If you enter 'calculate', the search will find items that contain variations such as 'calculus', 'calculation', etc.</p>
                <p>- You can search for phrases rather than words by enclosing the words in quotation marks. Example: ""civil engineer training"".</p>
                <p>- The search is not case sensitive and ignores accents.</p>
            </span>
        </div>


        <div v-else>
            <p class="mt-3">Contingut NO disponible: <span class="text-danger">{{contingut}}</span></p>
        </div>

    </b-modal>
</template>

<script>
    export default {
        name: "Modal",
        props: {
            id: String,
            contingut: String,
            language: String
        },
    }
</script>

<style scoped>

    .modal-header {
        border-bottom: 5px solid #e2ab17 !important;
    }

    .modal-body {
        background-color: #e9f1e4;
        border-bottom: 1px solid #dfdfdf !important;
    }

    .p{
        font-size: 90px;
    }

    .modal-footer {
        border-top: 5px solid #e2ab17 !important;
    }
    .modal-header{
        display:block;
    }
    footer{
        border-top: 1px solid #d4d5cf;
    }
    .preFooter{
        background: #fff;
        text-align: center;
    }
    .preFooter a{
        display: inline-block;
        height: 50px;
        margin: 15px;
    }
    .preFooter a img{
        max-height: 100%;
        width: auto;
        display: block;
        border: 0px;
        opacity: 0.9;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .preFooter a:hover img{
        opacity: 10;
    }
</style>