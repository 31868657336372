import Vue from 'vue'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head';
import GetTextPlugin from 'vue-gettext'
import translations from '../translations/translations.json'
import HighchartsVue from 'highcharts-vue'
import VueMeta from 'vue-meta'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import routes from './routes'

Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
Vue.use(BootstrapVue)

import "./assets/styles/app.scss"

Vue.use(VueHead)

Vue.use(VueRouter)

Vue.use(HighchartsVue)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

//Vue.("https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js")

Vue.use(GetTextPlugin, {
  autoAddKeyAttributes: true,
  availableLanguages: {
    ca: 'Català',
    en: 'English',
    es: 'Español',
  },
  defaultLanguage: 'ca',
  silent: true,
  translations: translations
})

// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const router = new VueRouter({mode: 'history', duplicateNavigationPolicy: 'reload', routes, linkActiveClass: "bg-active-upc",
  //scrollBehavior (to, from, savedPosition) {
  scrollBehavior () {
    //console.log(to +"-"+ from +"-"+savedPosition)
    return { x: 0, y: 0 }
  }});

router.beforeEach((to, from, next) => {
  let language = to.params.locale;

  /*if (language != 'ca' | language != 'es' | language != 'en') {
    language = 'ca'
  }*/

  Vue.config.language = language

  next();
});

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    currentPageProfessors: 1,
    count: 0,
    routeCurrent: "/"
    //username: localStorage.getItem("username")
  },
  getters: {
    config: state => state.config
    //isAuthenticated: state => !!state.username,
  },
  mutations:{
    changeCurrentPageProfessors(state, index){
      state.currentPageProfessors = index
    },
    increment (state) {
      state.count++
    },
  }
});


Vue.mixin({
  data: function() {
    return {
      APREN_URL: 'https://apren.upc.edu',
      API_URL:'https://apren.upc.edu/api'
      //API_URL:'http://147.83.97.5:7004/apren'

    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
