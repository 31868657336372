<template>
    <!-- $route.params.id -->
    <div>
        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'organitzacions'}"><translate>Organització</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{organitzacio.subtipus}}</li>
                <li class="breadcrumb-item active" aria-current="page">{{organitzacio.organitzacio_nom_ca}}</li>
            </ol>
        </nav>


        <div class="row">
            <div class="col-sm-12">

                <div class="card">
                    <div class="card-header">
                      <h3 class="h3">
                        <span v-if="$language.current === 'ca'" >{{ organitzacio.organitzacio_nom_ca}}</span>
                        <span v-else-if="$language.current === 'es'" >{{ organitzacio.organitzacio_nom_es}}</span>
                        <span v-else>{{ organitzacio.organitzacio_nom_en}}</span>
                      </h3>
                    </div>


                    <div class="card-body">

                        <div class="m-t-20 row">
                            <div class="col-md-3 col-xs-12 text-center mb-2">

                                <img v-if="organitzacio.subtipus === 'centre' || organitzacio.subtipus === 'servei'" :src="require('@/assets/images/centres/'+organitzacio.id+'.jpeg')" alt="organitzacio" class="img-responsive rounded shadow">
                                <div v-else ><i style="color: #5fa728;" class="fas fa-building fa-8x mb-2"></i></div>

                            </div>
                            <div class="col-md-7">

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Nom</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                            <span v-if="$language.current === 'ca'" >{{ organitzacio.organitzacio_nom_ca}}</span>
                                            <span v-else-if="$language.current === 'es'" >{{ organitzacio.organitzacio_nom_es}}</span>
                                            <span v-else>{{ organitzacio.organitzacio_nom_en}}</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>tipus</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                            <translate>{{organitzacio.subtipus}}</translate>
                                        </p>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Sigles</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                            {{organitzacio.organitzacio_sigles}}
                                        </p>
                                    </div>
                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Adreça</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                            <a :href="'https://www.google.es/maps/engine/'+organitzacio.organitzacio_adreca" target="blank_">
                                                {{organitzacio.organitzacio_adreca}}
                                            </a>

                                        </p>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Localitat</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                        <p class="mb-1">
                                            {{organitzacio.organitzacio_localitat}}
                                        </p>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold">URL</p></div>
                                  <div class="col-md-9 col-xs-12">
                                    <p class="mb-1">
                                      <a :href="organitzacio.organitzacio_web" target="blank_">
                                        {{organitzacio.organitzacio_web}}
                                      </a>

                                    </p>
                                  </div>
                                </div>

                                <hr v-if="organitzacio.organitzacio_titulacions_actives || organitzacio.organitzacio_titulacions_no_actives">

                                <div class="row mb-2" v-if="organitzacio.organitzacio_titulacions_actives">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Titulacions Actives</translate></p></div>
                                    <div class="col-md-9 col-xs-12 text-secondary">
                                        <div v-for="titulacio in organitzacio.organitzacio_titulacions_actives" v-bind:key="titulacio.id">
                                            <div class="mb-1"><router-link :to="{name: 'titulacio', params: {id: titulacio}}"><translate>{{ titulacio }}</translate></router-link></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="organitzacio.organitzacio_titulacions_no_actives">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Titulacions NO actives</translate></p></div>
                                    <div class="col-md-9 col-xs-12 text-secondary">
                                        <div v-for="titulacio in organitzacio.organitzacio_titulacions_no_actives" v-bind:key="titulacio.id">
                                            <div class="mb-1"><router-link :to="{name: 'titulacio', params: {id: titulacio}}"><translate>{{ titulacio }}</translate></router-link></div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="col-md-2 col-xs-12"><img src="../assets/images/fons_fitxa.png" alt="user" class="img-responsive rounded float-left"></div>
                        </div>

                    </div>

                    <div class="card-footer">
                      <div class="d-flex">
                        <div class="mr-auto p-0">
                          <AddThis publicId="ra-5ae95b2117917438" :data-title="organitzacio.organitzacio_nom_ca" :async="true"/>
                        </div>
                        <div class="mt-2">
                          <div class="d-flex">

                            <img src="../assets/images/mini-upc.png" alt="user" class="mr-1">
                            <a class="mr-4" :href="'https://apren.upc.edu/api/RSS/' + organitzacio.id" target="_blank" rel="noreferrer noopener"><translate>RSS</translate></a>

                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

        </div>

        <a v-if="professors.length > 0" @click='hideShowProfessors()' style="cursor:pointer;" class="text-secondary mr-1"><h2 class="mt-5"><i class="fas fa-plus"></i> <translate>participant</translate></h2></a>

        <br>
        <div id="divProfessors" style="display: none;">
            <div class="row">

                <div class="col-sm-12 col-md-12">
                    <ul class="list-unstyled" style="column-count: 4;">
                        <!-- class="list-group-item list-group-item-action" style="border-bottom: none; border-top: none ; border-right: none; border-left: none;"-->
                        <router-link v-for="(professor) in professors" :key="professor.id" :to="{name: 'professor', params: {id: professor.id}}">
                            <li class="mb-3"><i class="fas fa-user mr-2 text-secondary"></i> {{professor.professor_nom}} ({{professor.professor_num_materials}})</li>
                        </router-link>

                    </ul>
                </div>

            </div>
        </div>

        <div class="row d-none d-lg-block">
            <div class="col-sm-12">
                <b-tabs content-class="mt-3 col-12">
                    <b-tab active>
                        <template v-slot:title>
                            <h5><i class="fas fa-chart-bar"></i> <translate>Resum Gràfic</translate></h5>
                        </template>
                        <b-card-text>
                            <div class="row">
                                <div class="col-4">
                                    <ChartByTipus :facetes="facetes"></ChartByTipus>
                                </div>
                                <div class="col-8">
                                    <ChartByData :facetes="facetes" :facetesPivot="facetesPivot"></ChartByData>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <h5><i class="fas fa-globe-europe"></i> <translate>Resum Gràfic rights</translate></h5>
                      </template>
                      <b-card-text>
                        <div class="row">
                          <div class="col-12">
                            <ChartByDataAndAccess :facetes="facetes" :facetesPivot="facetesPivot"></ChartByDataAndAccess>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <br>


        <div class="row">

            <div class="col-sm-12 col-md-8">

              <h2><translate>Materials</translate></h2>
              <nav class="navbar navbar-expand-lg navbar-upc bg-light rounded">

                  <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

                  <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

                      <ul class="navbar-nav mr-auto mt-lg-0">

                      </ul>

                      <form class="form-inline">
                          <div class="form-check mt-1 mb-1 form-control-sm">
                              <label class="form-check-label"><translate>Ordenar per:</translate></label>
                              <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                                <option value="data asc, material_data_available asc"><translate>Data</translate> asc.</option>
                                <option value="data desc, material_data_available desc" selected><translate>Data</translate> desc.</option>
                                <option value="material_titol asc"><translate>Títol</translate> asc.</option>
                                <option value="material_titol desc"><translate>Títol</translate> desc.</option>
                              </select>
                          </div>
                      </form>
                  </div>
              </nav>

              <MaterialList :materials="materials"></MaterialList>

              <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>

            </div>

            <div id="filtres" class="col-sm-12 col-md-4 mt-0">
                <Facetes ref='facetes' :data="facetes" :numFiltres="7" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort"></Facetes>
            </div>

        </div>

    </div>

</template>

<script>

    import axios from 'axios'
    import Facetes from '../components/Facetes.vue'
    import ChartByTipus from '../components/ChartByTipus.vue'
    import ChartByData from '../components/ChartByData.vue'
    import Pagination from "../components/Pagination"
    import PaginationHeader from "../components/PaginationHeader";
    import Utils from "../extends/Utils";
    import MaterialList from "../components/MaterialList"
    import AddThis from 'vue-simple-addthis-share'
    import ChartByDataAndAccess from "../components/ChartByDataAndAccess";

    export default {
        mixins: [Utils],
        name: 'Organitzacio',
        components: {
          PaginationHeader,
          Facetes,
          ChartByTipus,
          ChartByData,
          ChartByDataAndAccess,
          Pagination,
          MaterialList,
          AddThis
        },
        data() {
            return {
                organitzacio: [],
                professor: [],
                professors: [],
                materials: [],
                facetes: [],
                facetesPivot: [],

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "initInCreated",
                queryProfessors: "InitInCreated",
                sort: "data desc, material_data_available desc",
                errors: []
            }
        },

        methods: {
            setMetaTitle(elem){
              if(this.$language.current === 'ca') document.title = "Aprèn - " + elem.organitzacio_nom_ca
              else if(this.$language.current === 'es') document.title = "Aprèn - " + elem.organitzacio_nom_es
              else document.title = "Aprèn - " + elem.organitzacio_nom_en
            },

            hideShowProfessors(){
                var x = document.getElementById("divProfessors")
                if (x.style.display === "none") {
                    x.style.display = "block";
                } else {
                    x.style.display = "none";
                }
            },

            solrQueryProfessorsOrganitzacio: async function (query) {
                let response = await axios.get(this.API_URL + '/engine/organitzacio/professors?query='+query)
                this.professors = response.data.response.docs;
            },

            solrQueryOrganitzacio: async function (codi) {

              let response = await axios.get(this.API_URL + '/engine/'+codi)
              if(response.data.response.numFound === 0) window.location = this.APREN_URL
              this.organitzacio = response.data.response.docs[0];
              this.setMetaTitle(this.organitzacio)

              //init: order titulacions by name
              let myMap = new Map()
              let arrayNomTitulacions = []

              if(this.organitzacio.organitzacio_titulacions_actives === "undefined"){
                this.organitzacio.organitzacio_titulacions_actives.forEach(value => {
                  myMap.set(this.$gettext(value)+"::"+value,value)
                  arrayNomTitulacions.push(this.$gettext(value)+"::"+value)
                })

                let arrayTitulacionsOrder = Array()
                arrayNomTitulacions.sort().forEach(value => {
                  arrayTitulacionsOrder.push(myMap.get(this.$gettext(value)))
                })
                //end: order titulacions by name

                this.organitzacio.organitzacio_titulacions_actives = arrayTitulacionsOrder
              }

            },
            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },
            solrQuery: async function (currentPage, length, query, sort) {
                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage-1)*length

                let response = await axios.get(this.API_URL +'/engine/item?query='+query+'&rows='+length+'&start='+start+'&sort='+sort)

                this.materials = response.data.response.docs;
                this.facetes = response.data.facet_counts.facet_fields;
                this.facetesPivot = response.data.facet_counts.facet_pivot;

                //reverse facet data
                response.data.facet_counts.facet_fields.data = this.reverseFacetData(response.data.facet_counts.facet_fields.data)

                this.navigation_start = response.data.response.start+1;
                this.navigation_total = response.data.response.numFound;
                this.navigation_length = length;
                this.navigation_end = response.data.response.start+this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start+this.navigation_length

                this.totalPages = Math.ceil(response.data.response.numFound / length);
            }
        },


        async created() {
          if(this.$route.params.tipus === "centre" || this.$route.params.tipus === "servei"){
            this.query = "material_centres:"+this.$route.params.id
            this.queryProfessors = "professor_centre_docent:"+this.$route.params.id
          }else if(this.$route.params.tipus === "departament"){
            this.query = "material_departaments:"+this.$route.params.id
            this.queryProfessors = "professor_departament:"+this.$route.params.id
          }else if(this.$route.params.tipus === "institut"){
            this.query = "material_instituts:"+this.$route.params.id
            this.queryProfessors = "professor_institut:"+this.$route.params.id
          }

          this.solrQueryOrganitzacio(this.$route.params.id)

          this.solrQueryProfessorsOrganitzacio(this.queryProfessors)
          this.solrQuery(1, this.navigation_length, this.query, this.sort)

        }
    }

</script>

<style scoped>

    .card-header {
        border-bottom: 5px solid #5fa728 !important;
    }

    .card-body {
        background-color: #ddf1ea;
        border-bottom: 1px solid #dfdfdf !important;
    }

    .card-footer {
        border-top: 5px solid #5fa728 !important;
    }

</style>
