<template>

  <div>

    <nav aria-label="breadcrumb" class="mt-n4 small">
      <ol class="breadcrumb bg-white ml-n3">
        <li class="mr-1"><translate>Sou a:</translate></li>
        <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
        <li class="breadcrumb-item active" aria-current="page">ADMIN</li>
      </ol>
    </nav>

    <div class="row">

      <div class="col-sm-12 col-md-12">

        <h1>Status: {{status}}</h1>
        Response: <h3>{{data}}</h3>

      </div>

    </div>
  </div>

</template>


<script>

import axios from 'axios'

export default {

  name: 'Admin',
  components: {

  },

  data() {

    return {

      data: "",
      status: "",
      errors: [],
    }
  },


  methods: {


    solrQuery: function () {


      //let response = await axios.get(this.API_URL + '/admin/test')
      //this.result = response;

      axios.get(this.API_URL + '/login', {

          headers: {
            'Access-Control-Allow-Origin': '*',
          },

          }).then(response => {

            alert("OK")
            this.data = response.data
            this.status = response.status
            localStorage.setItem("username", response.data)
            //window.location = "https://apren.upc.edu"

          })
          .catch(e => {
            //window.location = this.API_URL+"/login"
            localStorage.removeItem("username")
            alert(e)
            this.errors.push(e)
            console.log(e)
          })

    },
  },

  created() {
    this.solrQuery()
  },

}

</script>






