// routes.js
import Home from "./pages/Home";
import Professors from "./pages/Professors";
import Professor from "./pages/Professor";
import Assignatures from "./pages/Assignatures";
import Assignatura from "./pages/Assignatura";
import Materials from "./pages/Materials";
import Material from "./pages/Material";
import AccesObert from "./pages/AccesObert";
import TreballsFinals from "./pages/TreballsFinals";
import Organitzacions from "./pages/Organitzacions";
import Organitzacio from "./pages/Organitzacio";
import Titulacions from "./pages/Titulacions";
import Titulacio from "./pages/Titulacio";
import Video from "./pages/Video";
import About from "./pages/About";
import Contact from "@/pages/Contact";
import Admin from "@/pages/Admin";
import Cercar from "./pages/Cercar";
import Publish from "./pages/Publish";
import Sdg from "@/pages/Sdg";
import Directori from "./pages/Directori";

const routes = [
    {path: "/", redirect: `/ca`},

    { path: '/:locale(ca|es|en)', component: Home, name: 'home' },

    { path: '/:locale(ca|es|en)/cerca', component: Cercar, name: 'cercar', query:'q' },

    { path: '/:locale(ca|es|en)/professorat', component: Professors, name: 'professors'},
    { path: '/:locale(ca|es|en)/professorat/cerca', component: Professors, name: 'professorsCerca', query:'q' },
    { path: '/:locale(ca|es|en)/professorat/:id', component: Professor, name: 'professor', meta: [{name: 'description', content: 'blabala'},{title: 'Professorat :id'}] },

    { path: '/:locale(ca|es|en)/organitzacions', component: Organitzacions, name: 'organitzacions' },
    { path: '/:locale(ca|es|en)/organitzacions/cerca', component: Organitzacions, name: 'organitzacionsCerca', query:'q' },
    { path: '/:locale(ca|es|en)/organitzacions/:tipus/:id', component: Organitzacio, name: 'organitzacio' },

    { path: '/:locale(ca|es|en)/assignatures', component: Assignatures, name: 'assignatures' },
    { path: '/:locale(ca|es|en)/assignatures/cerca', component: Assignatures, name: 'assignaturesCerca', query:'q' },
    { path: '/:locale(ca|es|en)/assignatures/:id', component: Assignatura, name: 'assignatura' },

    { path: '/:locale(ca|es|en)/titulacions', component: Titulacions, name: 'titulacions' },
    { path: '/:locale(ca|es|en)/titulacions/cerca', component: Titulacions, name: 'titulacionsCerca', query:'q' },
    { path: '/:locale(ca|es|en)/titulacions/:id', component: Titulacio, name: 'titulacio' },

    { path: '/:locale(ca|es|en)/materials', component: Materials, name: 'materials' },
    { path: '/:locale(ca|es|en)/materials/cerca', component: Materials, name: 'materialsCerca', query:'q' },
    { path: '/:locale(ca|es|en)/materials/:id', component: Material, name: 'material' },

    { path: '/:locale(ca|es|en)/accesObert', component: AccesObert, name: 'accesObert' },
    { path: '/:locale(ca|es|en)/accesObert/cerca', component: AccesObert, name: 'accesObertCerca', query:'q' },

    { path: '/:locale(ca|es|en)/treballsFinals', component: TreballsFinals, name: 'treballsFinals' },
    { path: '/:locale(ca|es|en)/treballsFinals/cerca', component: TreballsFinals, name: 'treballsFinalsCerca', query:'q' },

    { path: '/:locale(ca|es|en)/videos', component: Video, name: 'videos' },
    { path: '/:locale(ca|es|en)/videos/cerca', component: Video, name: 'videosCerca', query:'q' },

    { path: '/:locale(ca|es|en)/about', component: About, name: 'about' },

    { path: '/:locale(ca|es|en)/contact', component: Contact, name: 'contact' },

    { path: '/:locale(ca|es|en)/admin', component: Admin, name: 'admin' },

    { path: '/:locale(ca|es|en)/publish', component: Publish, name: 'publish' },

    { path: '/:locale(ca|es|en)/sdg', component: Sdg, name: 'sdgAll'},
    { path: '/:locale(ca|es|en)/sdg/:id', component: Sdg, name: 'sdg'},

    { path: '/:locale(ca|es|en)/directori', component: Directori, name: 'directori' },


    //{ path: '/login', component: _import ( 'common / login'), name: 'login', meta: {title: 'login'}},


    //{ path: '/materials/:id', component: Material, name: 'material' },
    { path: '*', name: 'error', component: Home },
];


export default routes;
