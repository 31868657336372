<template>
    <div>
        <span v-if="facetes"></span>
        <highcharts :options="chartOptions"></highcharts>
    </div>
</template>

<script>
    import Utils from "@/extends/Utils";

    export default {
        name: 'ChartByTipus',
        mixins: [Utils],
        props: {
            facetes: [Object, Array]

        },
        data() {
            return {
                msg: "String",

                chartOptions: {
                    chart: {
                        type: 'column',
                        style: {
                          fontFamily: 'Roboto'
                        }
                        //height: 640
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: ['Tipus de material']
                    },
                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: ''
                        }
                    },

                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: false,
                                rotation: 0,

                            },
                            groupPadding: 0
                        },

                    },

                    /*legend: {

                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },*/

                    legend: {
                      itemStyle: {
                        fontWeight: 'bold',
                      }
                    },


                    colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],

                    series: []

                },
            }
        },
        updated() {


            if(this.chartOptions.series.length > 0) return
            //reset chart
            this.chartOptions.series = []
            this.chartOptions.title.text = this.$gettext('Contingut docent per tipus')
            this.chartOptions.yAxis.title.text = this.$gettext('Número Contingut docents')

            //add series
            Object.entries(this.facetes).forEach(([facetName, facetValue]) => {
                var nameSerie
                //var indexColor = 0
                if(facetName == "subtipus"){
                    Object.entries(facetValue).forEach(([index, value]) => {
                        if(index % 2 == 0){
                            nameSerie = this.$gettext(value)
                        } else{
                            var colorSerie = this.colorSeriesChart(nameSerie)
                            //color por orden: this.chartOptions.colors[index%10]
                            this.chartOptions.series.push({name:nameSerie, data:[value], color:colorSerie})
                            //indexColor++
                        }
                    })
                }
            });

            if(this.chartOptions.series.length <= 5){
                this.chartOptions.plotOptions.series.pointWidth = 40
            }
        }
    }
</script>

