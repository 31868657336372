<template>

  <div>
    <nav aria-label="breadcrumb" class="mt-n4 small">
      <ol class="breadcrumb bg-white ml-n3">
        <li class="mr-1"><translate>Sou a:</translate></li>
        <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
        <li class="breadcrumb-item active" aria-current="page"><translate>Materials</translate></li>
      </ol>
    </nav>

    <br>



      <div id="searcher" class="mb-5">
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <div class="form-row align-items-center">
                <div class="col-12">
                  <!-- Using components -->
                  <b-input-group class="mt-4 mb-4">
                    <label for="search"></label>
                    <input id="search" @keyup.enter="search()" ref="searchText" :value="paramSearch" name="Text" type="text" class="form-control" style="border-color: #6c757d;" placeholder="">

                  </b-input-group>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>


    <div class="row">

      <div class="col-sm-12 col-md-8">

        <h2><translate>Materials</translate></h2>

        <nav class="navbar navbar-expand-lg navbar-upc bg-light-upc rounded">

          <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

          <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul class="navbar-nav mr-auto mt-lg-0"></ul>
            <form class="form-inline">
              <div class="form-check mt-1 mb-1 form-control-sm">
                <label class="form-check-label"><translate>Ordenar per:</translate></label>
                <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                  <option value="persona_cognom1 asc" selected><translate>Cognom</translate> asc.</option>
                  <option value="persona_cognom1 desc"><translate>Cognom</translate> desc.</option>
                  <option value="id asc"><translate>ID</translate> asc.</option>
                  <option value="id desc"><translate>ID</translate> desc.</option>
                </select>
              </div>
            </form>
          </div>
        </nav>

        <div class="list-group list-group-flush mt-3">
          <li v-for="(p) in persones" :key="p.id" class="list-group-item list-group-item-action flex-column align-items-start">

            <div class="m-t-20 row">
              <div class="col-md-10">
                <div class="d-flex justify-content-between mb-2">
                  <div class="p2">
                    <!--i class="fas fa-user mr-2 text-secondary"></i-->
                    <router-link :to="{name: 'material', params: {id: p.id}}">{{ p.persona_nom }} {{ p.persona_cognom1 }} {{ p.persona_cognom2 }} </router-link>
                  </div>

                </div>

                <div>


                  <div class="font-weight-bold  mb-1">
                    <span class="text-secondary">{{ p.persona_usuari }}<i class="fas fa-at text-secondary"></i>upc.edu</span>
                  </div>

                  <div class="text-secondary mb-1">
                    Gauss ID:
                    <span>{{p.id}}</span>
                  </div>

                  <div v-for="(ue, index) in p.persona_perfils_desc" :key="ue" class="text-orange mb-1">
                    Unitat estructural:
                    <router-link :to="{name: 'material', params: {id: p.persona_perfils_id[index]}}">{{ ue }} </router-link>
                  </div>



                </div>

              </div>

            </div>


          </li>

        </div>

        <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>

      </div>


      <div id="filtres" class="col-sm-12 col-md-4 mt-0">
        <Facetes ref='facetes' :data="facetes" :numFiltres="7" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort" :xarxa-desactivada="true"></Facetes>
      </div>

    </div>
  </div>
</template>

<script>

import axios from 'axios'
import Utils from '../extends/Utils'
import Facetes from '../components/Facetes.vue'
import Pagination from "../components/Pagination"
import PaginationHeader from "../components/PaginationHeader"

export default {
  mixins: [Utils],
  name: 'Materials',
  components: {
    PaginationHeader,
    Facetes,
    Pagination,
  },
  data() {

    return {
      persones: [],
      facetes: [],
      facetesPivot: [],

      navigation_start: 0,
      navigation_end: 0,
      navigation_length: 20,
      navigation_total: 0,
      totalPages: 1,
      currentPage: 1,
      query: "tipus:persona",
      sort: "persona_cognom1 asc",

      paramSearch: "",
      errors: [],
    }
  },

  methods: {
    setMetaTitle(){
      document.title = "Aprèn - "+this.$router.app.$gettext('Materials')
    },

    onChange(event) {
      this.solrQuery(1, this.navigation_length, this.query, event.target.value)
    },

    solrQuery: function (currentPage, length, query, sort) {

      this.currentPage = currentPage;
      this.query = query;
      this.sort = sort;

      let start = (currentPage-1)*length;

      axios.get('http://147.83.97.5:7008/directori' + '/engine/execute?query=q='+query+'??facet.field=persona_perfils_desc??facet.field=persona_perfils_campus??facet=on??f.data.facet.sort=index??facet.mincount=1??rows='+length+'??start='+start+'??sort='+sort)
          .then(response => {

            this.persones = response.data.response.docs;
            this.facetes = response.data.facet_counts.facet_fields;
            this.facetesPivot = response.data.facet_counts.facet_pivot;

            //reverse facet data
            //response.data.facet_counts.facet_fields.data = this.reverseFacetData(response.data.facet_counts.facet_fields.data)

            this.navigation_start = response.data.response.start+1;
            this.navigation_total = response.data.response.numFound;
            this.navigation_length = length;
            this.navigation_end = response.data.response.start+this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start+this.navigation_length

            this.totalPages = Math.ceil(response.data.response.numFound / length);
            this.setMetaTitle()
          })
          .catch(e => {
            this.errors.push(e)
          })
    },

    search(){
      this.query = "tipus:persona"
      this.paramSearch = this.$refs.searchText.value
      let strs = this.paramSearch.split(" ")
      let query = "("
      strs.forEach(element => {
        if(query === "(") query += '*'+element+'*'
        else query += ' OR *'+element+'*'
      })
      query += ")"

      this.query += " AND persona_cerca:"+query
      //alert(this.query)
      this.solrQuery(1, this.navigation_length, this.query, this.sort)


    }
  },

  created() {
    /*if(this.$route.query.q !== undefined){
      this.query += " AND material_cerca:\""+this.$route.query.q+"\""
    }*/
    this.solrQuery(1, this.navigation_length, this.query, this.sort)
  }
}

</script>





