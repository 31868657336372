<template>

    <div>

        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><translate>Professorat</translate></li>
            </ol>
        </nav>

        <div class="row">

            <div class="col-sm-12 col-md-8">

                <h2><translate>Professorat</translate></h2>
                <nav class="navbar navbar-expand-lg navbar-upc bg-light-upc rounded">

                    <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

                    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

                        <ul class="navbar-nav mr-auto mt-lg-0">
                        </ul>

                        <form class="form-inline">
                            <div class="form-check mt-1 mb-1 form-control-sm">
                                <label class="form-check-label"><translate>Ordenar per:</translate></label>
                                <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                                    <option value="professor_nom_sort asc" selected>Nom asc.</option>
                                    <option value="professor_nom_sort desc">Nom desc.</option>
                                    <option value="professor_num_materials asc">Núm materials asc.</option>
                                    <option value="professor_num_materials desc">Núm materials desc.</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </nav>

                <div class="list-group list-group-flush mt-3">

                    <li v-for="(professor) in professors" :key="professor.id" class="list-group-item list-group-item-action flex-column align-items-start">

                        <div class="d-flex w-100 justify-content-between mb-1">
                            <router-link :to="{name: 'professor', params: {id: professor.id}}" :key="professor.id">{{ professor.professor_nom }}</router-link>
                            <span class="text-secondary"><translate>Materials docents</translate> <span class="badge badge-upc badge-pill">{{ professor.professor_num_materials }}</span> </span>
                        </div>

                        <div>

                            <div class="text-secondary mb-1" v-if="professor.professor_departament">
                                <span v-for="departament in professor.professor_departament" v-bind:key="departament">
                                    <translate>{{departament}}</translate><br>
                                </span>
                            </div>

                            <div class="mb-1" v-if="professor.professor_centre_docent">
                                <span v-for="centre in professor.professor_centre_docent" v-bind:key="centre">
                                    <translate>{{centre}}</translate><br>
                                </span>
                            </div>

                            <div class="text-orange mb-1" v-if="professor.professor_campus">
                                <span v-if="professor.professor_campus !== 'EXTERN'">{{ professor.professor_campus }}</span>
                            </div>
                        </div>
                    </li>

                </div>

                <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>


            </div>

            <div id="filtres" class="col-sm-12 col-md-4 mt-0">
                <Facetes ref='facetes' :data="facetes" :numFiltres="1" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort"></Facetes>
            </div>
        </div>
    </div>

</template>

<script>

    import axios from 'axios'
    import Facetes from '../components/Facetes.vue'
    import Pagination from "../components/Pagination"
    import PaginationHeader from "../components/PaginationHeader";

    export default {

        name: 'Professors',
        components: {
            PaginationHeader,
            Facetes,
            Pagination
        },

        data() {

            return {
                professors: [],
                facetes: [],

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "tipus:professor",
                sort: "professor_nom_sort asc",

                errors: [],
            }
        },


        methods: {
            setMetaTitle(){
              document.title = "Aprèn - "+this.$router.app.$gettext('Professorat')
            },

            showModal2() {
                this.isModalVisible = true;
            },

            closeModal() {
                this.isModalVisible = false;
            },

            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },

            solrQuery: async function (currentPage, length, query, sort) {

                this.$store.state.count = currentPage
                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage - 1) * length;

                //let response = await axios.get(this.API_URL + '/engine/execute?query=q=' + query + '??facet.field=professor_actiu??facet.field=professor_centre_docent??facet.field=professor_departament??facet.field=professor_campus??facet.field=professor_titulacions??facet.field=professor_assignatures??facet=on??facet.mincount=1??rows=' + length + '??start=' + start + '??sort=' + sort)
                let response = await axios.get(this.API_URL + '/engine/professorat?query='+query+'&rows='+length+'&start='+start+'&sort='+sort)
                this.professors = response.data.response.docs;
                this.facetes = response.data.facet_counts.facet_fields;

                this.navigation_start = response.data.response.start + 1;
                this.navigation_total = response.data.response.numFound;
                this.navigation_length = length;
                this.navigation_end = response.data.response.start + this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start + this.navigation_length

                this.totalPages = Math.ceil(response.data.response.numFound / length);
                this.setMetaTitle()

            },
        },

        created() {
            if(this.$route.query.q !== undefined){
                this.query += " AND professor_cerca:\""+this.$route.query.q+"\""
            }
            this.solrQuery(1, this.navigation_length, this.query, this.sort)
        },
    }

</script>






