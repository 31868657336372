<template>

    <div class="containerChart">
      <span v-if="facetes"></span>
      <highcharts :options="chartOptions1" class="mb-5"></highcharts>
      <highcharts :options="chartOptions2" class="mb-5"></highcharts>
    </div>

</template>

<script>
    import Utils from "@/extends/Utils";

    export default {
        name: 'ChartByDataAndAccess',
        mixins: [Utils],
        props: {
            facetes: [Object, Array],
            facetesPivot: [Object, Array]
        },
        data() {
            return {

              chartOptions1: {

                chart: {
                  type: 'bar',
                  style: {
                    fontFamily: 'Roboto'
                  }
                  //height: 600,
                },
                title: {
                  text: ''
                },
                xAxis: {
                  categories: []
                },
                yAxis: {
                  min: 1,
                  title: {
                    text: 'Número continguts docents'
                  }
                },
                legend: {
                  reversed: true
                },
                plotOptions: {
                  series: {
                    stacking: 'normal',
                    dataLabels: {
                      enabled: false
                    }
                  }
                },
                series: []

              },

              chartOptions2: {

                  chart: {
                    type: 'bar',
                    style: {
                      fontFamily: 'Roboto'
                    }
                  },

                  title: {
                    text: ''
                  },
                  xAxis: {
                    categories: []
                  },
                  yAxis: {
                    min: 1,
                    title: {
                      text: 'Número continguts docents'
                    }
                  },
                  legend: {
                    reversed: true
                  },
                  plotOptions: {
                    series: {
                      stacking: 'normal',
                      dataLabels: {
                        enabled: false
                      }
                    }
                  },
                  series:[]
                  /*series: [{
                    name: 'Accés obert',
                    data: [631, 727, 3202, 721, 26]
                  }]*/

                },


            }
        },

        updated() {

          // CHART 1: DOCUMENT TYPE BY RIGHTS

          if(this.chartOptions1.series.length > 0) return
          //reset chart
          this.chartOptions1.series = []
          this.chartOptions1.xAxis.categories = []
          this.chartOptions1.title.text = this.$gettext("Contingut docent per condicions dacces")
          this.chartOptions1.yAxis.title.text = this.$gettext("Número Contingut docents")

          //add xAxys Categories

          Object.entries(this.facetes).forEach(([facetName, facetValue]) => {

            if(facetName === "material_rights"){
              Object.entries(facetValue).forEach(([index, item]) => {
                if(index % 2 === 0) this.chartOptions1.xAxis.categories.push(this.$gettext(item))
                console.log(item)
              })
              this.chartOptions1.xAxis.categories.sort();
              if(this.chartOptions1.xAxis.categories.length > 20){
                this.chartOptions1.xAxis.categories = this.chartOptions1.xAxis.categories.slice(this.chartOptions1.xAxis.categories.length-20,this.chartOptions1.xAxis.categories.length)
              }
            }
          })



          Object.entries(this.facetesPivot).forEach(([facetName, facetValue]) => {
            facetName

            if (facetName === "subtipus,material_rights"){

              Object.entries(facetValue).forEach(([index, item]) => {
                index
                let nameSerie;

                let map = new Map();
                Object.entries(item.pivot).forEach(([index, pivot]) => {
                  index
                  nameSerie = this.$gettext(item.value)

                  //console.log(item.value+"----->"+pivot.value +": "+pivot.count)
                  //alert(item.value+"----->"+pivot.value +": "+pivot.count)
                  map.set(this.$gettext(pivot.value), pivot.count)
                })

                let data = []

                for (let i = 0; i < this.chartOptions1.xAxis.categories.length; i++) {
                  if (map.get(this.chartOptions1.xAxis.categories[i]) !== undefined) {

                    data[i] = map.get(this.chartOptions1.xAxis.categories[i])
                  } else {
                    data[i] = 0
                  }
                }

                const colorSerie = this.colorSeriesChart(nameSerie)
                //color por orden: this.chartOptions2.colors[index%10]
                this.chartOptions1.series.push({name: nameSerie, data: data, color: colorSerie})

              })
            }
          })

          if(this.chartOptions1.xAxis.categories.length <= 10){
            this.chartOptions1.plotOptions.series.pointWidth = 40
          }

          this.chartOptions1.chart.height = this.chartOptions1.xAxis.categories.length * 70 + 280;
          this.chartOptions1.chart.width = 1000;



          // CHART 2:  RIGHTS BY DOCUMENT TYPE

          if(this.chartOptions2.series.length > 0) return
          //reset chart
          this.chartOptions2.series = []
          this.chartOptions2.xAxis.categories = []
          this.chartOptions2.title.text = this.$gettext("Condicions acces per contingut docent")
          this.chartOptions2.yAxis.title.text = this.$gettext("Número Contingut docents")


          //add xAxys Categories

          Object.entries(this.facetes).forEach(([facetName, facetValue]) => {

            if(facetName === "subtipus"){
              Object.entries(facetValue).forEach(([index, item]) => {

                if(index % 2 === 0) this.chartOptions2.xAxis.categories.push(this.$gettext(item))
              })
              this.chartOptions2.xAxis.categories.sort();
              if(this.chartOptions2.xAxis.categories.length > 20){
                this.chartOptions2.xAxis.categories = this.chartOptions2.xAxis.categories.slice(this.chartOptions2.xAxis.categories.length-20,this.chartOptions2.xAxis.categories.length)
              }
            }
          })



          Object.entries(this.facetesPivot).forEach(([facetName, facetValue]) => {
            facetName
            //alert(facetName)

            if (facetName === "material_rights,subtipus"){

              Object.entries(facetValue).forEach(([index, item]) => {
                index
                let nameSerie

                let map = new Map()
                Object.entries(item.pivot).forEach(([index, pivot]) => {
                  index
                  nameSerie = this.$gettext(item.value)

                  //console.log(item.value+"----->"+pivot.value +": "+pivot.count)
                  //alert(item.value+"----->"+pivot.value +": "+pivot.count)
                  map.set(this.$gettext(pivot.value), pivot.count)
                })

                let data = []

                for (let i = 0; i < this.chartOptions2.xAxis.categories.length; i++) {
                  if (map.get(this.chartOptions2.xAxis.categories[i]) !== undefined) {

                    data[i] = map.get(this.chartOptions2.xAxis.categories[i])
                  } else {
                    data[i] = 0
                  }
                }


                const colorSerie = this.colorSeriesRightsChart(nameSerie)
                //color por orden: this.chartOptions2.colors[index%10]
                this.chartOptions2.series.push({name: nameSerie, data: data, color: colorSerie})

              })
            }
          })

          if(this.chartOptions2.xAxis.categories.length <= 10){
            this.chartOptions2.plotOptions.series.pointWidth = 40
          }

          this.chartOptions2.chart.height = this.chartOptions2.xAxis.categories.length * 60 + 180;
          this.chartOptions2.chart.width = 1000;


        }
    }
</script>


