<template>
  <div id="main-wrapper">
    <BaseStyle></BaseStyle>

    <!--Header v-show="$route.path!=='/ca/directori' ? true : false" ref='header' @incrementKey="incrementKey"></Header-->
    <Header ref='header' @incrementKey="incrementKey"></Header>

    <Main msg="principal" ref="main" :componentKey="actualKey" />

    <Footer ref='footer'></Footer>

  </div>
</template>

<script>
  //import 'bootstrap/dist/css/bootstrap.min.css'
  import BaseStyle from './components/BaseStyle'
  import Header from './components/Header.vue'
  import Main from './components/Main.vue'
  import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    BaseStyle, Header, Main, Footer
  },
  head:{
    script:  [
      //{ type: 'application/javascript', src: 'https://revistes.upc.edu/usercentrics-helper.js'},
      { type: 'application/javascript', src: ''},
      { type: 'application/javascript', src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js', id: 'usercentrics-cmp', 'data-settings-id':'k73tMWYfS'}
    ],
  },
  data () {
    return {
      keys: {},
    }
  },
  computed: {
    actualKey () {
      return this.$route.path + (this.keys[this.$route.path] || 0)
    },
  },
  methods: {
    incrementKey() {
      this.$set(this.keys, this.$route.path, (this.keys[this.$route.path] + 1) || 0)
      if(window.innerWidth <= 600) document.getElementById("searcher").scrollIntoView();
    }
  },

  metaInfo () {
    let title, description, keywords

    if(this.$language.current == 'ca') {
      title = "APRÈN. Portal de la producció docent del professorat de la UPC"
      description = "APRÈN és el portal que facilita l’accés als continguts que elabora el professorat de la Universitat Politècnica de Catalunya (UPC) com a eina de suport a la seva activitat docent."
      keywords = "apren,upc,producció,docent,docència,universitat,professorat"
    }else if(this.$language.current == 'es'){
      title = "APRÈN. Portal de la producción docente del profesorado de la UPC"
      description = "APRÈN es el portal que facilita el acceso a los contenidos que elabora el profesorado de la Universitat Politècnica de Catalunya (UPC) como herramienta de apoyo a su actividad docente."
      keywords = "apren,upc,producción,docente,docencia,universidad,profesorado"
    }else{
      title = "APRÈN. Website of the learning production by UPC teachers"
      description = "APRÈN is a portal that provides access to the teaching support materials developed by the teaching staff of the Universitat Politècnica de Catalunya (UPC)."
      keywords = "apren,upc,production,learning,university,teacher"
    }
    return {
      htmlAttrs: {
        lang: this.$language.current
      },
      title: title,
      titleTemplate: '%s',
      meta: [
        {vmid: 'author', name: 'author', content: 'Universitat Politècnica de Catalunya (UPC). Iniciativa Digital Politècnica (IDP)'},
        {vmid: 'author2', name: 'author', content: 'Universitat Politècnica de Catalunya (UPC). Servei de Biblioteques, Publicacions i Arxius (SBPA)'},
        {vmid: 'description', name: 'description', content: description},
        {vmid: 'keywords', name: 'keywords', content: keywords}
      ],
      link: [
        {rel: 'alternate', hreflang: 'ca', href: this.APREN_URL+"/ca"+this.$route.path.substr(3)},
        {rel: 'alternate', hreflang: 'es', href: this.APREN_URL+"/es"+this.$route.path.substr(3)},
        {rel: 'alternate', hreflang: 'en', href: this.APREN_URL+"/en"+this.$route.path.substr(3)},
        {rel: 'canonical', href: this.APREN_URL+this.$route.path}
      ]
    }
  }
  /*watch: {
    '$route' (to) {
      //this.$language.current=to.params.locale
      //alert(to.params.locale)
      document.title = to.meta.title || 'Your Website'
    }
  },*/

  }
</script>

<!--style lang="scss"> @import "/src/styles/app.scss";</style-->

<style>

  #app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;*/
}
</style>
