<template>


    <div id="body">
        <div class="container">
            <!--{{componentKey}}-->
            <!--button @click="forceRerender">forceRenderer</button-->
            <!--keep-alive include="Professors, Organitzacions"-->
            <!--router-view :key="$route.fullPath+componentKey"/-->
            <keep-alive>
                <router-view :key="componentKey"/>
            </keep-alive>

            <!--router-view :key="$route.fullPath"/-->

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Main',

        data() {
            return {

            }
        },

        props: {
            msg: String,
            componentKey: String,
        },

        /*methods:{
            forceRerender() {
                this.componentKey += 1;
            },
        }*/


    }
</script>

