<template>

    <div>

        <!--{{this.$parent.$parent.keys}}-->

        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><translate>Professorat</translate></li>
            </ol>
        </nav>

        <div class="row">

            <div class="col-sm-12 col-md-12">

                <div v-if="professors.length !== 0">
                  <h1><translate>Professorat</translate></h1>
                  <div class="list-group list-group-flush mt-3">

                    <li v-for="(professor) in professors" :key="professor.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="d-flex w-100 justify-content-between mb-1">
                        <router-link :to="{name: 'professor', params: {id: professor.id}}" :key="professor.id">{{ professor.professor_nom }}</router-link>
                        <span class="text-secondary"><translate>Materials docents</translate> <span class="badge badge-upc badge-pill">{{ professor.professor_num_materials }}</span> </span>
                      </div>

                      <div>
                        <div class="text-secondary mb-1" v-if="professor.professor_departament">
                                <span v-for="departament in professor.professor_departament" v-bind:key="departament">
                                    <translate>{{departament}}</translate><br>
                                </span>
                        </div>
                        <div class="mb-1" v-if="professor.professor_centre_docent">
                                <span v-for="centre in professor.professor_centre_docent" v-bind:key="centre">
                                    <translate>{{centre}}</translate><br>
                                </span>
                        </div>
                        <div class="text-orange mb-1" v-if="professor.professor_campus">
                          <span v-if="professor.professor_campus !== 'EXTERN'">{{ professor.professor_campus }}</span>
                        </div>
                      </div>
                    </li>

                  </div>
                  <router-link v-if="professors.length === rows" @click="incrementKeyParent" :to="{name: 'professorsCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>


                <div v-if="organitzacions.length !== 0" class="mt-3">
                  <h1><translate>Organitzacions</translate></h1>
                  <div class="list-group list-group-flush mt-2">

                    <li v-for="(organitzacio) in organitzacions" :key="organitzacio.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="d-flex w-100 justify-content-between mb-1">
                        <router-link :to="{name: 'organitzacio', params: {tipus:organitzacio.subtipus, id: organitzacio.id}}">
                          <span v-if="$language.current === 'ca'" >{{ organitzacio.organitzacio_nom_ca}}</span>
                          <span v-else-if="$language.current === 'es'" >{{ organitzacio.organitzacio_nom_es}}</span>
                          <span v-else>{{ organitzacio.organitzacio_nom_en}}</span>
                        </router-link>
                      </div>

                      <div class="text-secondary mb-1" >
                        <span>{{ organitzacio.organitzacio_sigles }}</span>
                      </div>

                      <div class="mb-1" >
                        <span><translate>{{ organitzacio.subtipus }}</translate></span>
                      </div>
                    </li>
                  </div>
                  <router-link v-if="organitzacions.length === rows" @click="incrementKeyParent" :to="{name: 'organitzacionsCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>

                <div v-if="assignatures.length !== 0" class="mt-5">
                  <h1><translate>Assignatures</translate></h1>
                  <div class="list-group list-group-flush mt-3">

                    <li v-for="(assignatura) in assignatures" :key="assignatura.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="d-flex w-100 justify-content-between">
                        <router-link :to="{name: 'assignatura', params: {id: assignatura.id}}">
                          <span v-if="$language.current === 'ca'" >{{ assignatura.assignatura_nom_ca}}</span>
                          <span v-else-if="$language.current === 'es'" >{{ assignatura.assignatura_nom_es}}</span>
                          <span v-else>{{ assignatura.assignatura_nom_en}}</span>
                        </router-link>
                      </div>

                      <div>

                        <div class="mt-2 text-secondary font-weight-bold mb-1">{{ assignatura.assignatura_sigles }}</div>

                        <div class="mt-2" v-if="assignatura.assignatura_centres">
                          <span v-for="centre in (assignatura.assignatura_centres.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual))" v-bind:key="centre">
                              <translate>Centres</translate>: <translate>{{centre}}</translate><br>
                          </span>
                        </div>

                        <div class="mt-2 text-secondary " v-if="assignatura.assignatura_titulacions">
                          <div v-for="(titulacio, index) in assignatura.assignatura_titulacions" v-bind:key="titulacio">
                              <div class="d-flex w-100 justify-content-between">
                                  <span><translate>{{titulacio}}</translate></span>
                                  <span v-if="getTipus(assignatura.assignatura_tipus, index) === 'OB'" class="mb-1"><span class="badge badge-upc"><translate>OB</translate></span></span>
                                  <span v-else-if="getTipus(assignatura.assignatura_tipus, index) === 'OP'" class="mb-1"><span class="badge badge-success"><translate>OP</translate></span></span>
                                  <span v-else-if="getTipus(assignatura.assignatura_tipus, index) === 'ALE'" class="mb-1"> <span class="badge badge-danger"><translate>ALE</translate></span></span>
                                  <span v-else class="mb-1"><span class="badge badge-dark"><translate>OTHER</translate></span></span>
                              </div>
                          </div>
                        </div>

                      </div>
                    </li>
                  </div>
                  <router-link v-if="assignatures.length === rows" @click="incrementKeyParent" :to="{name: 'assignaturesCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>
                <div v-if="titulacions.length !== 0" class="mt-3">
                  <h1><translate>Titulacions</translate></h1>
                  <div class="list-group list-group-flush mt-3">

                    <li v-for="(titulacio) in titulacions" :key="titulacio.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="d-flex w-100 justify-content-between mb-1">
                        <router-link :to="{name: 'titulacio', params: {id: titulacio.id}}">{{ titulacio.titulacio_nom_ca }}</router-link>
                        <span class="text-secondary">
                                <span v-if="titulacio.titulacio_activa" class="badge badge-pill badge-success"><translate>Activa</translate></span>
                                <span v-else class="badge badge-pill badge-danger"><translate>No activa</translate></span>
                              </span>
                      </div>

                      <div class="text-secondary mb-1" >
                        <span>{{ titulacio.titulacio_sigles }}</span>
                      </div>

                      <div class="mb-1" >
                        <span><translate>{{ titulacio.titulacio_centre }}</translate></span>
                      </div>

                      <div class="text-success mb-1" >
                        <span><translate>{{ titulacio.titulacio_nivell }}</translate></span>
                      </div>

                    </li>

                  </div>
                  <router-link v-if="titulacions.length === rows" @click="incrementKeyParent" :to="{name: 'titulacionsCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>


                <div v-if="materials.length !== 0" class="mt-3">
                  <h1><translate>Materials</translate></h1>
                  <div class="list-group list-group-flush mt-3">
                    <li v-for="(material, index) in materials" :key="material.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="m-t-20 row">
                        <div class="col-md-10">

                          <div class="d-flex justify-content-between mb-2">
                            <div class="p2">
                              <router-link :to="{name: 'material', params: {id: material.id}}">{{ material.material_titol}} </router-link>
                              <i v-if="material.material_rights === 'Open Access'" class="fas fa-lock-open text-warning" :title="material.material_rights"></i>
                              <img v-else-if="material.material_rights === 'Restricted access to the UPC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the UPC academic community">
                              <img v-else-if="material.material_rights === 'Restricted access to the CSUC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the CSUC academic community">
                              <i v-else class="fas fa-lock text-warning" :title="material.material_rights"></i>
                            </div>
                          </div>

                          <div>

                            <div class="text-secondary font-weight-bold mb-1"><translate>{{ material.subtipus }}</translate></div>

                            <div v-if="material.material_comunitat[0] === 'pfc'" >

                              <div class="text-secondary mb-1" v-if="material.material_altresAutories">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(altre, index) in material.material_altresAutories" v-bind:key="altre">
                                            {{altre}}<span v-if="index !== (material.material_altresAutories.length-1)">;</span>
                                          </span>
                              </div>

                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <translate>tutor</translate>:
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                              {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                          </span>
                              </div>

                            </div>
                            <div v-else>
                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                                {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                            </span>
                              </div>
                            </div>

                            <div class="text-secondary mb-1"><translate>Data de publicació</translate> {{ material.data }}</div>

                            <div v-if="material.material_isbn" class="text-secondary mb-1">
                              ISBN:
                              <span v-for="(isbn, index) in material.material_isbn" v-bind:key="isbn">
                                          {{isbn}}<span v-if="index !== (material.material_isbn.length-1)">;</span>
                                        </span>
                            </div>

                            <!--div v-if="material.material_dl" class="text-secondary mb-1"><translate>Dipòsit legal</translate>: {{ material.material_dl }}</div-->

                            <div class="mt-2" v-if="material.material_centres">
                                          <span v-for="centre in material.material_centres" v-bind:key="centre">
                                              <translate>{{centre}}</translate><br>
                                          </span>
                            </div>

                            <div class="mt-2 text-orange" v-if="material.material_assignatures">
                                          <span v-for="assignatura in material.material_assignatures" v-bind:key="assignatura">
                                              <translate>{{assignatura}}</translate><br>
                                          </span>
                            </div>

                            <!--div class="mt-2 text-secondary" v-if="material.material_titulacions">
                                <span v-for="titulacio in material.material_titulacions" v-bind:key="titulacio">
                                    <translate>{{titulacio}}</translate><br>
                                </span>
                            </div-->

                            <div class="mt-4">
                              <a v-if="material.material_resum" @click='hideShow("resum"+index)' style="cursor:pointer;" class="text-secondary mr-4"><i class="fas fa-lg fa-search"></i> <translate>Veure detall</translate></a>
                              <a :href="'http://hdl.handle.net/' + material.id.replace('_','/')" target="_blank" rel="noreferrer noopener" class="text-secondary mr-4"><i class="fas fa-lg fa-file"></i> <translate>Accés al text complet</translate></a>

                              <div v-bind:id="'resum'+index" class="alert alert-dark mt-3" style="display: none;">
                                <div v-for="(resum, index) in material.material_resum" v-bind:key="resum">
                                    <div v-if="index !== (material.material_resum.length-1)" class="mb-4">{{resum}}</div>
                                    <div v-else>{{resum}}</div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="col-md-2 col-xs-12 d-none d-lg-block">
                          <a v-bind:href="material.material_original" target="_blank" rel="noreferrer noopener">
                            <img v-if="material.material_thumbnail" :src="material.material_thumbnail" @error="imageUrlAlt($event, material.material_rights)" alt="thumbnail" class="img-responsive float-left img-thumbnail">
                            <img v-else src="../assets/images/no-photo.png" alt="thumbnail">
                          </a>
                        </div>
                      </div>

                    </li>

                  </div>
                  <router-link v-if="materials.length === rows" @click="incrementKeyParent" :to="{name: 'materialsCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>

                <div v-if="materialsAccesObert.length !== 0" class="mt-3">
                  <h1><translate>Accés Obert</translate></h1>
                  <div class="list-group list-group-flush mt-3">
                    <li v-for="(material, index) in materialsAccesObert" :key="material.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="m-t-20 row">
                        <div class="col-md-10">

                          <div class="d-flex justify-content-between mb-2">
                            <div class="p2">
                              <router-link :to="{name: 'material', params: {id: material.id}}">{{ material.material_titol}} </router-link>
                              <i v-if="material.material_rights === 'Open Access'" class="fas fa-lock-open text-warning" :title="material.material_rights"></i>
                              <img v-else-if="material.material_rights === 'Restricted access to the UPC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the UPC academic community">
                              <img v-else-if="material.material_rights === 'Restricted access to the CSUC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the CSUC academic community">
                              <i v-else class="fas fa-lock text-warning" :title="material.material_rights"></i>
                            </div>
                          </div>

                          <div>

                            <div class="text-secondary font-weight-bold mb-1"><translate>{{ material.subtipus }}</translate></div>

                            <div v-if="material.material_comunitat[0] === 'pfc'" >

                              <div class="text-secondary mb-1" v-if="material.material_altresAutories">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(altre, index) in material.material_altresAutories" v-bind:key="altre">
                                                {{altre}}<span v-if="index !== (material.material_altresAutories.length-1)">;</span>
                                            </span>
                              </div>

                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <translate>tutor</translate>:
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                                {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                            </span>
                              </div>

                            </div>
                            <div v-else>
                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                                {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                            </span>
                              </div>
                            </div>

                            <div class="text-secondary mb-1"><translate>Data de publicació</translate> {{ material.data }}</div>

                            <div v-if="material.material_isbn" class="text-secondary mb-1">
                              ISBN:
                              <span v-for="(isbn, index) in material.material_isbn" v-bind:key="isbn">
                                          {{isbn}}<span v-if="index !== (material.material_isbn.length-1)">;</span>
                                        </span>
                            </div>

                            <!--div v-if="material.material_dl" class="text-secondary mb-1"><translate>Dipòsit legal</translate>: {{ material.material_dl }}</div-->

                            <div class="mt-2" v-if="material.material_centres">
                                        <span v-for="centre in material.material_centres" v-bind:key="centre">
                                            <translate>{{centre}}</translate><br>
                                        </span>
                            </div>

                            <div class="mt-2 text-orange" v-if="material.material_assignatures">
                                        <span v-for="assignatura in material.material_assignatures" v-bind:key="assignatura">
                                            <translate>{{assignatura}}</translate><br>
                                        </span>
                            </div>

                            <!--div class="mt-2 text-secondary" v-if="material.material_titulacions">
                                <span v-for="titulacio in material.material_titulacions" v-bind:key="titulacio">
                                    <translate>{{titulacio}}</translate><br>
                                </span>
                            </div-->

                            <div class="mt-4">
                              <a v-if="material.material_resum" @click='hideShow("resum"+index)' style="cursor:pointer;" class="text-secondary mr-4"><i class="fas fa-lg fa-search"></i> <translate>Veure detall</translate></a>
                              <a :href="'http://hdl.handle.net/' + material.id.replace('_','/')" target="_blank" rel="noreferrer noopener" class="text-secondary mr-4"><i class="fas fa-lg fa-file"></i> <translate>Accés al text complet</translate></a>
                              <div v-bind:id="'resum'+index" class="alert alert-dark mt-3" style="display: none;">
                                <div v-for="(resum, index) in material.material_resum" v-bind:key="resum">
                                    <div v-if="index !== (material.material_resum.length-1)" class="mb-4">{{resum}}</div>
                                    <div v-else>{{resum}}</div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-2 col-xs-12 d-none d-lg-block">
                          <a v-bind:href="material.material_original" target="_blank" rel="noreferrer noopener">
                            <img v-if="material.material_thumbnail" :src="material.material_thumbnail" class="img-responsive float-left img-thumbnail" alt="thumbnail">
                            <img v-else src="../assets/images/no-photo.png" alt="thumbnail">
                          </a>
                        </div>
                      </div>

                    </li>

                  </div>
                  <router-link v-if="materialsAccesObert.length === rows" @click="incrementKeyParent" :to="{name: 'accesObertCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>


                <div v-if="materialsPfc.length !== 0" class="mt-3">
                  <h1><translate>Treballs Finals</translate></h1>
                  <div class="list-group list-group-flush mt-3">
                    <li v-for="(material, index) in materialsPfc" :key="material.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="m-t-20 row">
                        <div class="col-md-10">

                          <div class="d-flex justify-content-between mb-2">
                            <div class="p2">
                              <router-link :to="{name: 'material', params: {id: material.id}}">{{ material.material_titol}} </router-link>
                              <i v-if="material.material_rights === 'Open Access'" class="fas fa-lock-open text-warning" :title="material.material_rights"></i>
                              <img v-else-if="material.material_rights === 'Restricted access to the UPC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the UPC academic community">
                              <img v-else-if="material.material_rights === 'Restricted access to the CSUC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the CSUC academic community">
                              <i v-else class="fas fa-lock text-warning" :title="material.material_rights"></i>
                            </div>
                          </div>

                          <div>

                            <div class="text-secondary font-weight-bold mb-1"><translate>{{ material.subtipus }}</translate></div>

                            <div v-if="material.material_comunitat[0] === 'pfc'" >

                              <div class="text-secondary mb-1" v-if="material.material_altresAutories">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(altre, index) in material.material_altresAutories" v-bind:key="altre">
                                                {{altre}}<span v-if="index !== (material.material_altresAutories.length-1)">;</span>
                                            </span>
                              </div>

                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <translate>tutor</translate>:
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                                {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                            </span>
                              </div>

                            </div>
                            <div v-else>
                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                                {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                            </span>
                              </div>
                            </div>

                            <div class="text-secondary mb-1"><translate>Data de publicació:</translate> {{ material.data }}</div>

                            <div v-if="material.material_isbn" class="text-secondary mb-1">
                              ISBN:
                              <span v-for="(isbn, index) in material.material_isbn" v-bind:key="isbn">
                                            {{isbn}}<span v-if="index !== (material.material_isbn.length-1)">;</span>
                                          </span>
                            </div>

                            <!--div v-if="material.material_dl" class="text-secondary mb-1"><translate>Dipòsit legal</translate>: {{ material.material_dl }}</div-->

                            <div class="mt-2" v-if="material.material_centres">
                                        <span v-for="centre in material.material_centres" v-bind:key="centre">
                                            <translate>{{centre}}</translate><br>
                                        </span>
                            </div>

                            <div class="mt-2 text-orange" v-if="material.material_assignatures">
                                        <span v-for="assignatura in material.material_assignatures" v-bind:key="assignatura">
                                            <translate>{{assignatura}}</translate><br>
                                        </span>
                            </div>

                            <!--div class="mt-2 text-secondary" v-if="material.material_titulacions">
                                <span v-for="titulacio in material.material_titulacions" v-bind:key="titulacio">
                                    <translate>{{titulacio}}</translate><br>
                                </span>
                            </div-->

                            <div class="mt-4">
                              <a v-if="material.material_resum" @click='hideShow("resum"+index)' style="cursor:pointer;" class="text-secondary mr-4"><i class="fas fa-lg fa-search"></i> <translate>Veure detall</translate></a>
                              <a :href="'http://hdl.handle.net/' + material.id.replace('_','/')" target="_blank" rel="noreferrer noopener" class="text-secondary mr-4"><i class="fas fa-lg fa-file"></i> <translate>Accés al text complet</translate></a>
                              <div v-bind:id="'resum'+index" class="alert alert-dark mt-3" style="display: none;">
                                <div v-for="(resum, index) in material.material_resum" v-bind:key="resum">
                                    <div v-if="index !== (material.material_resum.length-1)" class="mb-4">{{resum}}</div>
                                    <div v-else>{{resum}}</div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-2 col-xs-12 d-none d-lg-block">
                          <a v-bind:href="material.material_original" target="_blank" rel="noreferrer noopener">
                            <img v-if="material.material_thumbnail" :src="material.material_thumbnail" @error="imageUrlAlt($event, material.material_rights)" alt="thumbnail" class="img-responsive float-left img-thumbnail">
                            <img v-else src="../assets/images/no-photo.png" alt="thumbnail">
                          </a>
                        </div>
                      </div>

                    </li>

                  </div>
                  <router-link v-if="materialsPfc.length === rows" @click="incrementKeyParent" :to="{name: 'treballsFinalsCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>

                <div v-if="materialsVideos.length !== 0" class="mt-3">
                  <h1><translate>Vídeos</translate></h1>
                  <div class="list-group list-group-flush mt-3">
                    <li v-for="(material, index) in materialsVideos" :key="material.id" class="list-group-item list-group-item-action flex-column align-items-start">

                      <div class="m-t-20 row">
                        <div class="col-md-10">

                          <div class="d-flex justify-content-between mb-2">
                            <div class="p2">
                              <router-link :to="{name: 'material', params: {id: material.id}}">{{ material.material_titol}} </router-link>
                              <i v-if="material.material_rights === 'Open Access'" class="fas fa-lock-open text-warning" :title="material.material_rights"></i>
                              <img v-else-if="material.material_rights === 'Restricted access to the UPC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the UPC academic community">
                              <img v-else-if="material.material_rights === 'Restricted access to the CSUC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the CSUC academic community">
                              <i v-else class="fas fa-lock text-warning" :title="material.material_rights"></i>
                            </div>
                          </div>

                          <div>

                            <div class="text-secondary font-weight-bold mb-1"><translate>{{ material.subtipus }}</translate></div>

                            <div v-if="material.material_comunitat[0] === 'pfc'" >

                              <div class="text-secondary mb-1" v-if="material.material_altresAutories">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(altre, index) in material.material_altresAutories" v-bind:key="altre">
                                                {{altre}}<span v-if="index !== (material.material_altresAutories.length-1)">;</span>
                                            </span>
                              </div>

                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <translate>tutor</translate>:
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                                {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                            </span>
                              </div>

                            </div>
                            <div v-else>
                              <div class="text-secondary mb-1" v-if="material.material_professors">
                                <i class="fas fa-user text-secondary"></i>
                                <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                                                {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                                            </span>
                              </div>
                            </div>

                            <div class="text-secondary mb-1"><translate>Data de publicació:</translate> {{ material.data }}</div>

                            <div v-if="material.material_isbn" class="text-secondary mb-1">
                              ISBN:
                              <span v-for="(isbn, index) in material.material_isbn" v-bind:key="isbn">
                                          {{isbn}}<span v-if="index !== (material.material_isbn.length-1)">;</span>
                                        </span>
                            </div>

                            <!--div v-if="material.material_dl" class="text-secondary mb-1"><translate>Dipòsit legal</translate>: {{ material.material_dl }}</div-->

                            <div class="mt-2" v-if="material.material_centres">
                                        <span v-for="centre in material.material_centres" v-bind:key="centre">
                                            <translate>{{centre}}</translate><br>
                                        </span>
                            </div>

                            <div class="mt-2 text-orange" v-if="material.material_assignatures">
                                        <span v-for="assignatura in material.material_assignatures" v-bind:key="assignatura">
                                            <translate>{{assignatura}}</translate><br>
                                        </span>
                            </div>

                            <!--div class="mt-2 text-secondary" v-if="material.material_titulacions">
                                <span v-for="titulacio in material.material_titulacions" v-bind:key="titulacio">
                                    <translate>{{titulacio}}</translate><br>
                                </span>
                            </div-->

                            <div class="mt-4">
                              <a v-if="material.material_resum" @click='hideShow("resum"+index)' style="cursor:pointer;" class="text-secondary mr-4"><i class="fas fa-lg fa-search"></i> <translate>Veure detall</translate></a>
                              <a :href="'http://hdl.handle.net/' + material.id.replace('_','/')" target="_blank" rel="noreferrer noopener" class="text-secondary mr-4"><i class="fas fa-lg fa-file"></i> <translate>Accés al text complet</translate></a>
                              <div v-bind:id="'resum'+index" class="alert alert-dark mt-3" style="display: none;">
                                <div v-for="(resum, index) in material.material_resum" v-bind:key="resum">
                                    <div v-if="index !== (material.material_resum.length-1)" class="mb-4">{{resum}}</div>
                                    <div v-else>{{resum}}</div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-2 col-xs-12 d-none d-lg-block">
                          <a v-bind:href="material.material_original" target="_blank" rel="noreferrer noopener">
                            <img v-if="material.material_thumbnail" :src="material.material_thumbnail" @error="imageUrlAlt($event, material.material_rights)" alt="thumbnail" class="img-responsive float-left img-thumbnail">
                            <img v-else src="../assets/images/no-photo.png" alt="thumbnail">
                          </a>
                        </div>
                      </div>

                    </li>

                  </div>
                  <router-link v-if="materialsVideos.length === rows" @click="incrementKeyParent" :to="{name: 'videosCerca', query:{q: this.query}}" style="text-decoration: none; color: inherit;" class="mt-3 mb-5 d-flex main-menu-element align-items-center mb-2">
                    <button type="button" class="btn btn-warning text-white"> <i class="fas fa-plus"></i> <translate>Veure tots els resultats</translate></button>
                  </router-link>
                </div>
            </div>
        </div>
    </div>

</template>


<script>

    import axios from 'axios'
    import Utils from '../extends/Utils'

    export default {
        mixins: [Utils],
        name: 'Professors',
        components: {

        },


        data() {

            return {
                professors: [],
                organitzacions: [],
                assignatures: [],
                titulacions: [],
                materials: [],
                materialsAccesObert: [],
                materialsPfc: [],
                materialsVideos: [],

                rows: 5,

            }
        },

        computed: {
          actualKey () {
            return this.$route.path + (this.keys2[this.$route.path] || 0)
          },
        },

        methods: {

            incrementKeyParent() {
              this.$parent.$parent.$set(this.$parent.$parent.keys, this.$route.path, (this.$parent.$parent.keys[this.$route.path] + 1) || 0)
            },

            setMetaTitle(){
              document.title = "Aprèn - "+this.$router.app.$gettext('Cercador')
            },

            showModal2() {
                this.isModalVisible = true;
            },

            closeModal() {
                this.isModalVisible = false;
            },

            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },

            getTipus(tipus, index) {
              //paso la variable tipus a String
              let tipusStr = tipus+""
              return tipusStr.split(",")[index];
            },

            solrQuery: async function () {

                if(this.$route.query.q !== undefined){
                  this.query = this.$route.query.q

                  //let response = await axios.get(this.API_URL + '/engine/execute?query=q=tipus:professor AND professor_cerca:"'+this.$route.query.q+'"??rows='+this.rows+'??sort=professor_nom_sort asc')
                  let response = await axios.get(this.API_URL + '/engine/search?query=tipus:professor AND professor_cerca:"'+this.$route.query.q+'"&rows='+this.rows+'&sort=professor_nom_sort asc')
                  this.professors = response.data.response.docs;

                  response = await axios.get(this.API_URL + '/engine/search?query=tipus:organitzacio AND organitzacio_cerca:'+this.$route.query.q+'&rows='+this.rows+'&sort=organitzacio_nom_ca asc')
                  this.organitzacions = response.data.response.docs;

                  response = await axios.get(this.API_URL + '/engine/search?query=tipus:assignatura AND assignatura_cerca:"'+this.$route.query.q+'"&rows='+this.rows+'&sort=assignatura_nom_ca asc')
                  this.assignatures = response.data.response.docs;

                  response = await axios.get(this.API_URL + '/engine/search?query=tipus:titulacio AND titulacio_cerca:"'+this.$route.query.q+'"&rows='+this.rows+'&sort=titulacio_sigles asc')
                  this.titulacions = response.data.response.docs;

                  response = await axios.get(this.API_URL + '/engine/search?query=tipus:material AND material_cerca:"'+this.$route.query.q+'"&rows='+this.rows+'&sort=data desc, material_data_available desc')
                  this.materials = response.data.response.docs;

                  response = await axios.get(this.API_URL + '/engine/search?query=material_rights:"Open Access" AND material_cerca:"'+this.$route.query.q+'"&rows='+this.rows+'&sort=data desc, material_data_available desc')
                  this.materialsAccesObert = response.data.response.docs;

                  response = await axios.get(this.API_URL + '/engine/search?query=material_comunitat:pfc AND material_cerca:"'+this.$route.query.q+'"&rows='+this.rows+'&sort=data desc, material_data_available desc')
                  this.materialsPfc = response.data.response.docs;

                  response = await axios.get(this.API_URL + '/engine/search?query=material_comunitat:videodocent AND material_cerca:"'+this.$route.query.q+'"&rows='+this.rows+'&sort=data desc, material_data_available desc')
                  this.materialsVideos = response.data.response.docs;

                }

                this.setMetaTitle()

            },

            search(query, searchSelectedKey) {

              this.incrementKeyIntern()

              if(searchSelectedKey === 1){
                if(this.paramSearch === "") this.$router.replace({ name: 'cercar'})
                else this.$router.replace({ name: 'cercar', query:{q: this.query}})
              }
              else if(searchSelectedKey === 2){
                if(this.paramSearch === "") this.$router.replace({ name: 'professors'})
                else this.$router.replace({ name: 'professorsCerca', query:{q: this.query}})
              }
              else if(searchSelectedKey === 3){
                if(this.paramSearch === "") this.$router.replace({ name: 'organitzacions'})
                else this.$router.replace({ name: 'organitzacionsCerca', query:{q: this.query}})
              }
              else if(searchSelectedKey === 4){
                if(this.paramSearch === "") this.$router.replace({ name: 'assignatures'})
                else this.$router.replace({ name: 'assignaturesCerca', query:{q: this.query}})
              }
              else if(searchSelectedKey === 5){
                if(this.paramSearch === "") this.$router.replace({ name: 'titulacions'})
                else this.$router.replace({ name: 'titulacionsCerca', query:{q: this.query}})
              }
              else if(searchSelectedKey === 6){
                if(this.paramSearch === "") this.$router.replace({ name: 'materials'})
                else this.$router.replace({ name: 'materialsCerca', query:{q: this.query}})
              }
              else if(searchSelectedKey === 7){
                if(this.paramSearch === "") this.$router.replace({ name: 'accesObert'})
                else this.$router.replace({ name: 'accesObertCerca', query:{q: this.query}})
              }
              else if(searchSelectedKey === 8){
                if(this.paramSearch === "") this.$router.replace({ name: 'treballsFinals'})
                else this.$router.replace({ name: 'treballsFinalsCerca', query:{q: this.query}})
              }
              else if(searchSelectedKey === 9){
                if(this.paramSearch === "") this.$router.replace({ name: 'videos'})
                else this.$router.replace({ name: 'videosCerca', query:{q: this.query}})
              }
            }
        },

        created() {
          this.solrQuery()
        },
    }

</script>






