<template>
    <div>
        <div class="card ">
            <div class="card-header bg-light-upc">
                <h5><translate>Filtra els resultats</translate> <span class="float-right"><i class="fas fa-filter"></i></span></h5>
            </div>

            <div v-for="(facet, field, index) in data" :key="facet.id">

                <!--translate :translate-params="{value: field}">%{value}</translate-->

                <div v-if="index < numFiltres">
                    <div class="card-body card-header bg-light-upc" v-if="facet.length > 0">

                        <h3>
                            <span v-if="facet.length/2 <= 5">
                                <a class="mr-1 disabled"><translate>{{field}}</translate></a>
                            </span>
                            <span v-else>
                                <a href="javascript:void(0);" class="mr-1" @click='hideShow(index)'>
                                    <span v-if="showMore[index]">+ </span>
                                    <span v-else>- </span>
                                    <translate>{{field}}</translate>
                                </a>
                            </span>
                            <b-button class="btn btn-light border btn-sm" v-b-modal="field"><i class="fas fa-info-circle"></i></b-button>
                            <Modal :contingut="field" :id="field" :language="$language.current"></Modal>

                            <!--button type="button" class="btn btn-light border btn-sm" onclick="javascript:missatge('Informació', 'FILTRE_INFORMACIO_INVESTIGADOR_GRUPRECERCA', 0);" aria-label="Informació">
                                <i class="fas fa-info-circle"></i>
                            </button-->
                        </h3>

                        <div v-if="showMore[index] && facet.length/2 > 5">
                            <!--{{(facet.slice(0, 5*2).length/2)}}-->

                            <div v-if="field == 'subtipus'">
                              <div v-for="(itemFacet, i) in facet.slice(0, 7*2)" :key="i" >

                                <div v-if="i % 2 != 0" class="mb-3">
                                  <div class="opcio-filtre">


                                    <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                        <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1]}}</translate> </a>
                                        &nbsp;<span  class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                    </span>
                                    <span v-else>
                                        <span class="text-secondary font-weight-bold"><translate>{{facet[i-1]}}</translate> </span>
                                        &nbsp;<span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                        <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                    </span>

                                    <br>
                                  </div>
                                </div>

                              </div>
                              <button class="btn btn-outline-secondary btn-sm" @click='hideShow(index)'>Més...</button>
                            </div>
                            <div v-else-if="field == 'material_ods'">
                              <div v-for="(itemFacet, i) in facet.slice(0, 17*2)" :key="i" >

                                <div v-if="i % 2 != 0" class="mb-3">
                                  <div class="opcio-filtre">


                                      <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                          <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1]}}</translate> </a>
                                          &nbsp;<span  class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                      </span>
                                    <span v-else>
                                          <span class="text-secondary font-weight-bold"><translate>{{facet[i-1]}}</translate> </span>
                                          &nbsp;<span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                          <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                      </span>

                                    <br>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div v-else>
                              <div v-for="(itemFacet, i) in facet.slice(0, 3*2)" :key="i" >

                                <div v-if="i % 2 != 0" class="mb-3">
                                  <div class="opcio-filtre">

                                    <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                        <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1]}}</translate> </a>
                                        &nbsp;<span  class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                    </span>
                                    <span v-else>
                                        <span class="text-secondary font-weight-bold"><translate>{{facet[i-1]}}</translate> </span>
                                        &nbsp;<span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                        <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                    </span>

                                    <br>
                                  </div>
                                </div>

                              </div>
                              <button class="btn btn-outline-secondary btn-sm" @click='hideShow(index)'>Més...</button>
                            </div>




                        </div>
                        <div v-else>
                            <!--{{facet.length/2}}-->
                            <div v-for="(itemFacet, i) in facet" :key="i" >

                                <div v-if="i % 2 != 0" class="mb-3">
                                    <div class="opcio-filtre">


                                    <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                        <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1]}}</translate> </a>
                                        &nbsp;<span class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                    </span>
                                        <span v-else>
                                        <span class="text-secondary font-weight-bold"><translate>{{facet[i-1]}}</translate> </span>
                                        &nbsp;<span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                        <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                    </span>

                                        <br>
                                    </div>
                                </div>

                            </div>
                            <button v-if="facet.length/2 > 5" class="btn btn-outline-secondary btn-sm" @click='hideShow(index)'>Tancar</button>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <br>

        <div class="card" v-if="!xarxaDesactivada">
            <div class="card-header bg-white">
                <h5><translate>Xarxa de col·laboració UPC</translate><span class="float-right"><i class="fas fa-sitemap"></i></span></h5>

            </div>

            <div v-for="(facet, field, index) in data" :key="facet.id">
                <div v-if="index >= numFiltres">
                    <div class="card-body bg-white" v-if="facet.length > 0">
                        <h3>
                            <span v-if="facet.length/2 <= 5">
                                <a class="mr-1 disabled"><translate>{{field}}</translate></a>
                            </span>
                            <span v-else>
                                <a href="javascript:void(0);" class="mr-1" @click='hideShow(index)'>
                                    <span v-if="showMore[index]">+ </span>
                                    <span v-else>- </span>
                                    <translate>{{field}}</translate>
                                </a>
                            </span>
                            <b-button class="btn btn-light border btn-sm" v-b-modal="field"><i class="fas fa-info-circle"></i></b-button>
                            <Modal :contingut="field" :id="field" :language="$language.current"></Modal>

                        </h3>
                        <div v-if="showMore[index] && facet.length/2 > 5">
                            <!--{{(facet.slice(0, 5*2).length/2)}}-->
                            <div v-for="(itemFacet, i) in facet.slice(0, 3*2)" :key="i" >
                                <div v-if="i % 2 != 0" class="mb-3">
                                    <div class="opcio-filtre">
                                        <span v-if="field == 'material_professors_faceta'">
                                          <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                              <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1].split("::")[0]}}</translate> </a>
                                            &nbsp; <span  class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                          </span>
                                          <span v-else>
                                              <span class="text-secondary font-weight-bold"><translate>{{facet[i-1].split("::")[0]}}</translate> </span>
                                            &nbsp; <span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                              <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                          </span>
                                        </span>
                                        <span v-else>
                                            <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                                <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1]}}</translate> </a>
                                              &nbsp; <span  class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                            </span>
                                          <span v-else>
                                                <span class="text-secondary font-weight-bold"><translate>{{facet[i-1]}}</translate> </span>
                                              &nbsp; <span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                                <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                            </span>
                                        </span>


                                        <span v-if="profileLink(field) == 'centre' || profileLink(field) == 'departament' || profileLink(field) == 'institut'">
                                            <router-link :to="{name: 'organitzacio', params: {tipus:profileLink(field), id: facet[i-1]}}"><img class="ml-1" src="../assets/images/pagina.png" alt="Ver perfil en el portal"> </router-link>
                                        </span>
                                        <span v-else-if="profileLink(field) == 'professors'">
                                            <router-link :to="{name: 'professor', params: {id: facet[i-1].split('::')[1]}}"><img class="ml-1" src="../assets/images/pagina.png" alt="Ver perfil en el portal"> </router-link>
                                        </span>
                                        <span v-else-if="profileLink(field) == ''">
                                        </span>
                                        <span v-else>
                                            <router-link :to="{name: profileLink(field), params: {id: facet[i-1]}}" ><img class="ml-1" src="../assets/images/pagina.png" alt="Ver perfil en el portal"> </router-link>
                                        </span>


                                        <br>
                                    </div>
                                </div>

                            </div>
                            <button class="btn btn-outline-secondary btn-sm" @click='hideShow(index)'>Més...</button>


                        </div>
                        <div v-else>
                            <!--{{facet.length/2}}-->
                            <div v-for="(itemFacet, i) in facet" :key="i" >

                                <div v-if="i % 2 != 0" class="mb-3">
                                    <div class="opcio-filtre">

                                      <span v-if="field == 'material_professors_faceta'">
                                        <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                            <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1].split("::")[0]}}</translate> </a>
                                            &nbsp;<span  class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                        </span>
                                            <span v-else>
                                            <span class="text-secondary font-weight-bold"><translate>{{facet[i-1].split("::")[0]}}</translate> </span>
                                            &nbsp;<span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                            <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                        </span>
                                      </span>
                                      <span v-else>
                                        <span v-if='query.indexOf(field+":\""+facet[i-1]+"\"") == -1' ref="list">
                                            <a href="javascript:void(0);" @click='onClickAddFilter(field+":\""+facet[i-1]+"\"")' rel="nofollow"><translate>{{facet[i-1]}}</translate> </a>
                                            &nbsp;<span  class="badge badge-pill badge-light border">{{itemFacet.toLocaleString()}}</span>
                                        </span>
                                            <span v-else>
                                            <span class="text-secondary font-weight-bold"><translate>{{facet[i-1]}}</translate> </span>
                                            &nbsp;<span class="badge badge-pill badge-dark mr-1">{{itemFacet.toLocaleString()}}</span>
                                            <span class="badge badge-pill badge-danger" style="cursor: pointer;" @click='onClickRemoveFilter(field+":\""+facet[i-1]+"\"")'>X</span>
                                        </span>
                                      </span>


                                        <span v-if="profileLink(field) == 'centre' || profileLink(field) == 'departament' || profileLink(field) == 'institut'">
                                            <router-link :to="{name: 'organitzacio', params: {tipus:profileLink(field), id: facet[i-1]}}"><img class="ml-1" src="../assets/images/pagina.png" alt="Ver perfil en el portal"> </router-link>
                                        </span>
                                        <span v-else-if="profileLink(field) == 'professors'">
                                            <router-link :to="{name: 'professor', params: {id: facet[i-1].split('::')[1]}}"><img class="ml-1" src="../assets/images/pagina.png" alt="Ver perfil en el portal"> </router-link>
                                        </span>
                                        <span v-else-if="profileLink(field) == ''">
                                        </span>
                                        <span v-else>
                                            <router-link :to="{name: profileLink(field), params: {id: facet[i-1]}}" ><img class="ml-1" src="../assets/images/pagina.png" alt="Ver perfil en el portal"> </router-link>
                                        </span>

                                        <br>
                                    </div>
                                </div>

                            </div>
                            <button v-if="facet.length/2 > 5" class="btn btn-outline-secondary btn-sm" @click='hideShow(index)'>Tancar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import Modal from "./Modal";
    export default {
        name: 'Facetes',
        components: {Modal},
        props: {
            data: [Object, Array, Function],
            numFiltres: Number,
            xarxaDesactivada: Boolean,

            currentPage: Number,
            length: Number,
            query: String,
            sort: String,
            id: String
        },
        data() {
            return {
                showMore: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
            }
        },
        computed: {
            //TODO: canviar a computed
            activeUsers: function(i) {
                //console.log(this.data[i])
                return this.data[i]
            }
        },
        methods:{
          selectedOds(odsSelected){
              //console.log(odsSelected)
              let ods = ""

              if(odsSelected === '01') ods = "01 - Fi de la Pobresa"
              else if(odsSelected === '02') ods = "02 - Fam zero"
              else if(odsSelected === '03') ods = "03 - Salut i Benestar"
              else if(odsSelected === '04') ods = "04 - Educació de Qualitat"
              else if(odsSelected === '05') ods = "05 - Igualtat de Gènere"
              else if(odsSelected === '06') ods = "06 - Aigua Neta i Sanejament"
              else if(odsSelected === '07') ods = "07 - Energia Assequible i No Contaminant"
              else if(odsSelected === '08') ods = "08 - Treball Decent i Creixement Econòmic"
              else if(odsSelected === '09') ods = "09 - Indústria, Innovació i Infraestructura"
              else if(odsSelected === '10') ods = "10 - Reducció de les Desigualtats"
              else if(odsSelected === '11') ods = "11 - Ciutats i Comunitats Sostenibles"
              else if(odsSelected === '12') ods = "12 - Producció i Consum Responsables"
              else if(odsSelected === '13') ods = "13 - Acció per al Clima"
              else if(odsSelected === '14') ods = "14 - Vida Submarina"
              else if(odsSelected === '15') ods = "15 - Vida d'Ecosistemes Terrestres"
              else if(odsSelected === '16') ods = "16 - Pau, Justícia i Institucions Sòlides"
              else if(odsSelected === '17') ods = "17 - Aliança per a Aconseguir els Objetius"

              if(odsSelected === 'all') this.onClickAddFilter('material_ods:*')
              else this.onClickAddFilter('material_ods:"'+ods+'"')
            },

            profileLink(field){
                if(field.indexOf("centre") != -1) return "centre"
                else if(field.indexOf("departament") != -1) return "departament"
                else if(field.indexOf("institut") != -1) return "institut"
                else if(field.indexOf("titulacio") != -1) return "titulacio"
                else if(field.indexOf("assignatures") != -1) return "assignatura"
                else if(field.indexOf("professors") != -1) return "professors"
                else return ""
            },

            hideShow(index){
                this.$set(this.showMore, index, !this.showMore[index])
            },

            onClickAddFilter(filter) {
                this.$parent.solrQuery(1, this.length, this.query+' AND '+ filter, this.sort)
            },

            onClickRemoveFilter(event) {
                let query = this.query.replace(' AND '+event, "")
                let currentPage = 1
                this.$parent.solrQuery(currentPage, this.length, query, this.sort)
            },
        },

      mounted() {

      }
    }
</script>

