<template>

    <div class="mt-3">

        <!--:labels="paginationAnchorTexts"-->
        <v-pagination v-model="currentPage"
                      :page-count="totalPages"
                      :classes="bootstrapPaginationClasses"
                      @input="linkToParentSolrQuery(currentPage, length, query, sort)"></v-pagination>
    </div>

</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        components: {vPagination},
        name: 'Pagination',
        data() {
            return {
                currentPage: 1,
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                /*paginationAnchorTexts: {
                    first: 'Primera',
                    prev: 'Anterior',
                    next: 'Següent',
                    last: 'Última'
                }*/
            }
        },
        methods: {
            linkToParentSolrQuery(currentPage, length, query, sort) {
                this.$parent.solrQuery(currentPage, length, query, sort)
            }
        },

        props: {
            totalPages: Number,

            length: Number,
            query: String,
            sort: String
        }
    }
</script>

