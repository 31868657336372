<template>

    <div id="header">
        <div class="container">
            <div class="row mt-4 mb-0">
                <div class="col-xl-6 text-left">
                  <a href="https://apren.upc.edu" rel="noreferrer noopener">
                    <img src="@/assets/images/logo-apren.png" width="50" alt="Universitat Politècnica de Catalunya" title="Universitat Politècnica de Catalunya" class="mr-2">
                  </a>
                  <a href="https://www.upc.edu" target="_blank" rel="noreferrer noopener">
                    <!--img src="@/assets/images/logo2.png" alt="Universitat Politècnica de Catalunya" title="Universitat Politècnica de Catalunya"-->
                    <!--img src="@/assets/images/logo-apren-horizontal.png" width="300" alt="Universitat Politècnica de Catalunya" title="Universitat Politècnica de Catalunya" class="ml-2"-->
                    <img src="@/assets/images/logo.png" alt="Universitat Politècnica de Catalunya" title="Universitat Politècnica de Catalunya">

                  </a>
                  <!--router-link @click.native="$emit('incrementKey')" :to="{name: 'admin'}">
                    TEST
                  </router-link-->
                </div>


                <!-- ============================================================== -->
                <!-- Top menu: -->
                <!-- ============================================================== -->
                <div class="col-xl-6 text-right container">
                    <ul id="top-menu" class="d-lg-block d-md-none d-sm-none d-xs-none">

                      <li class="pr-2">
                        {{username}} {{$store.state.username}}
                      </li>
                      <!--router-link @click.native="$emit('incrementKey')" :to="{name: 'admin'}">
                      login
                      </router-link-->


                        <!--li class="pr-2">
                          <a href="https://futur.upc.edu" target="_blank" rel="noreferrer noopener"><img :src="require('@/assets/images/futur.png')" alt="Futur"> Futur</a>
                        </li-->


                        <li class="pr-2">
                          <router-link :to="{name: 'publish'}">
                            <i class="fas fa-exclamation-triangle" style="color: #b2b2b2;" ></i> <translate>Com publicar</translate>
                          </router-link>

                        </li>

                        <li class="pr-2">
                            <router-link :to="{name: 'about'}">
                                <i class="fas fa-info-circle" style="color: #b2b2b2;" ></i> <translate>Sobre</translate>
                            </router-link>

                        </li>

                        <li class="pr-2">
                            <router-link :to="{name: 'contact'}">
                              <i class="fas fa-envelope" style="color: #b2b2b2;" ></i> <translate>Contacte</translate>
                            </router-link>

                        </li>
                        <li class="pr-2">
                            <!--i class="fas fa-globe-europe"></i>
                            <b-dropdown  variant="light" id="language" :text="language" v-model="$language.current">
                                <b-dropdown-item v-for="(language, key) in $language.available" v-bind:key="key" :value="key"  @click="language='es'">
                                    {{language}}
                                </b-dropdown-item>
                            </b-dropdown-->
                            <!--v-select v-model="$language.current"
                                      label="countryName"
                                      :options="$language.available"
                            ></v-select-->

                            <b-dropdown variant="btn btn-outline-secondary" >
                                <template v-slot:button-content>
                                    <i class="fas fa-globe-europe mr-1"></i>
                                    <span> {{$language.current == 'ca' ? 'Català' : $language.current == 'es' ? 'Español' : 'English'}}</span>
                                </template>

                                  <b-dropdown-item @click="changeLocale('ca')">Català</b-dropdown-item>
                                  <b-dropdown-item @click="changeLocale('es')">Español</b-dropdown-item>
                                  <b-dropdown-item @click="changeLocale('en')">English</b-dropdown-item>
                                 <!--b-dropdown-item @click="$language.current='ca'">Català</b-dropdown-item>
                                 <b-dropdown-item @click="$language.current='es'">Español</b-dropdown-item>
                                 <b-dropdown-item @click="$language.current='en'">English</b-dropdown-item-->
                            </b-dropdown>

                         </li>
                    </ul>


                </div>
            </div>

            <div class="row mt-0 mb-2">
              <div class="col-xl-8 text-left">
                <div class="mt-4 pl-1">
                  <h1>
                  <a class="h1 font-weight-bold text-secondary" style="text-decoration: none; font-size: 2rem" :href="'http://apren.upc.edu/'+$language.current">APRÈN. </a>
                  <a class="h1 text-secondary" style="font-size: 1.6rem; text-decoration: none;" :href="'http://apren.upc.edu/'+$language.current"><translate>Portal de la producció docent del professorat de la UPC</translate></a>
                  </h1>
                </div>
              </div>

              <div class="col-xl-4 text-right container">

                <div class="d-none d-lg-block mt-n3">

                  <!--img src="@/assets/images/logo-apren2.png" width="100" alt="Universitat Politècnica de Catalunya" title="Universitat Politècnica de Catalunya"-->
                  <!--img src="@/assets/images/logo-apren-horizontal.png" width="" alt="Universitat Politècnica de Catalunya" title="Universitat Politècnica de Catalunya"-->
                  <!--img src="@/assets/images/logo-apren.png" width="64" alt="Universitat Politècnica de Catalunya" title="Universitat Politècnica de Catalunya" class="mr-2"-->
                </div>
              </div>
            </div>

        </div>
        <!-- ============================================================== -->
        <!-- Main menu TODO canviar para adaptarlo a mobile -->
        <!-- ============================================================== -->
        <div id="main-menu" class="main-background">
            <div class="container">
                <div class="row">

                    <div class="col-sm-12 mt-5 mb-5">
                        <div class="d-flex flex-wrap justify-content-center align-items-center">


                            <!--button @click="()=>$router.push('professors')" >PPP</button-->

                            <!--router-link @click.native="forceRerenderComponentMain" :to="{name: 'professors'}" :key="componentKey"  accesskey="" class="d-flex main-menu-element align-items-center mb-2"-->

                            <router-link @click="$emit('incrementKey')" :to="{name: 'professors'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-users fa-2x mb-2"></i>
                                    <span class="text"><translate>Professorat</translate></span>
                                    <span class="number">{{num_professor}}</span>
                                </div>
                            </router-link>

                            <!--router-link @click.native="forceRerenderComponentMain" :to="{name: 'organitzacions'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2"-->
                            <router-link @click="$emit('incrementKey')" :to="{name: 'organitzacions'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-building fa-2x mb-2"></i>
                                    <span class="text"><translate>Organització</translate></span>
                                    <span class="number">{{num_organitzacio}}</span>
                                </div>
                            </router-link>

                            <router-link @click="$emit('incrementKey')" :to="{name: 'assignatures'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-sitemap fa-2x mb-2"></i>
                                    <span class="text"><translate>Assignatures</translate></span>
                                    <span class="number">{{num_assignatura}}</span>
                                </div>
                            </router-link>

                            <router-link @click="$emit('incrementKey')" :to="{name: 'titulacions'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-user-graduate fa-2x mb-2"></i>
                                    <span class="text"><translate>Titulacions</translate></span>
                                    <span class="number">{{num_titulacio}}</span>
                                </div>
                            </router-link>

                            <router-link @click="$emit('incrementKey')" :to="{name: 'materials'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-file fa-2x mb-2"></i>
                                    <span class="text"><translate>Materials</translate></span>
                                    <span class="number">{{num_material}}</span>
                                </div>
                            </router-link>

                            <router-link @click="$emit('incrementKey')" :to="{name: 'accesObert'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-lock-open fa-2x mb-2"></i>
                                    <span class="text"><translate>Accés Obert</translate></span>
                                    <span class="number">{{num_openAccess}}</span>
                                </div>
                            </router-link>

                            <router-link @click="$emit('incrementKey')" :to="{name: 'treballsFinals'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-graduation-cap fa-2x mb-2"></i>
                                    <span class="text"><translate>Treballs Finals</translate></span>
                                    <span class="number">{{num_pfc}}</span>
                                </div>
                            </router-link>

                            <!--router-link :to="'innovacio'" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i class="fas fa-cogs fa-3x mb-2"></i>
                                    <span class="text">Innovació</span>
                                    <span class="number">0</span>
                                </div>
                            </router-link-->

                            <router-link @click="$emit('incrementKey')" :to="{name: 'videos'}" accesskey="" class="d-flex main-menu-element align-items-center mb-2">
                                <div class="content">
                                    <i style="color: #5fa728;" class="fas fa-video fa-2x mb-2"></i>
                                    <span class="text"><translate>Vídeos</translate></span>
                                    <span class="number">{{num_video}}</span>
                                </div>
                            </router-link>

                            <a href="https://futur.upc.edu" target="_blank" rel="noreferrer noopener" style="background: #bfe5f4;" class="d-flex main-menu-element align-items-center mb-2">

                              <div class="content" >
                                <img :src="require('@/assets/images/futur-bola.png')" alt="Futur" class="mb-2" >
                                <span class="text">Futur</span>
                              </div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- Search box -->
        <!-- ============================================================== -->


        <div id="searcher">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <div class="form-row align-items-center">
                            <div class="col-12">
                                <!-- Using components -->
                                <b-input-group class="mt-4 mb-4">
                                    <label for="search"></label>
                                    <input id="search" @keyup.enter="search()" ref="searchText" :value="paramSearch" name="Text" type="text" class="form-control" style="border-color: #6c757d;" placeholder="">
                                    <b-input-group-append>

                                        <b-dropdown id="ddown1" variant="outline-secondary" class="btn-default" :text="searchSelected" ref="searchSelected">
                                            <b-dropdown-item @click="changeSearchSelected('Tots', 1)" ><translate>Tots</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Professorat', 2)" ><translate>Professorat</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Organització', 3)"><translate>Organització</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Assignatures', 4)"><translate>Assignatures</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Titulacions', 5)"><translate>Titulacions</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Materials', 6)"><translate>Materials</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Accés Obert', 7)"><translate>Accés Obert</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Treballs Finals', 8)"><translate>Treballs Finals</translate></b-dropdown-item>
                                            <b-dropdown-item @click="changeSearchSelected('Vídeos', 9)"><translate>Vídeos</translate></b-dropdown-item>
                                        </b-dropdown>


                                        <b-button class="btn btn-primary button-action btn-size-40" aria-label="Search" @click='search()'>
                                            <i class="fas fa-search"></i>
                                        </b-button>

                                        <b-button class="btn btn-default button-action btn-size-40" aria-label="Info" variant="light" v-b-modal="modal_search">
                                            <i class="fas fa-info"></i>
                                        </b-button>

                                    </b-input-group-append>
                                </b-input-group>
                                <Modal :contingut="modal_search" :id="modal_search" :language="$language.current"></Modal>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

    import axios from 'axios'
    import Modal from "./Modal";

    export default {
        name: 'Header',
        components: {Modal},

        data() {
            return {
                modal_search: "modal_search",
                num_professor: "0",
                num_organitzacio: "0",
                num_assignatura: "0",
                num_titulacio: "0",
                num_material: "0",
                num_openAccess: "0",
                num_pfc: "0",
                num_video: "0",
                errors: [],
                searchSelected: 'Tots',
                searchSelectedKey: 1,

                componentKey: 0,

                authenticated: false,
                username: "",

                paramSearch: ""
            }
        },





        created() {
          this.searchSelected = this.$gettext(this.searchSelected)
          this.solrQueryHeaders()
          //console.log(document.cookie)
          //this.authCredentials()

        },



        methods:{

            changeSearchSelected(elem, val){
              this.searchSelected=this.$gettext(elem)
              this.searchSelectedKey=val
            },
            changeLocale(locale){

              this.$router.push('/'+locale+this.$route.path.substring(3))

              if(this.searchSelectedKey == 1) this.changeSearchSelected('Tots', 1)
              else if(this.searchSelectedKey == 2) this.changeSearchSelected('Professorat', 2)
              else if(this.searchSelectedKey == 3) this.changeSearchSelected('Organització', 3)
              else if(this.searchSelectedKey == 4) this.changeSearchSelected('Assignatures', 4)
              else if(this.searchSelectedKey == 5) this.changeSearchSelected('Titulacions', 5)
              else if(this.searchSelectedKey == 6) this.changeSearchSelected('Materials', 6)
              else if(this.searchSelectedKey == 7) this.changeSearchSelected('Accés Obert', 7)
              else if(this.searchSelectedKey == 8) this.changeSearchSelected('Treballs Finals', 8)
              else if(this.searchSelectedKey == 9) this.changeSearchSelected('Vídeos', 9)

            },
            forceRerenderComponentMain() {
                //console.log(this.$store.state.routeCurrent)
                //console.log(this.$route.path)

                this.$parent.$refs.main.forceRerender()


                //this.componentKey += 1
                //this.$parent.$refs.main.componentKey += 1;



                //this.$store.state.routeCurrent = this.$route.path
            },

            solrQueryHeaders: function () {

              axios.get(this.API_URL + '/engine/header')

                  .then(response => {

                    var str = JSON.stringify(response.data.facet_counts.facet_queries).split("tipus:").join('').replace(':\\"Open Access\\"', "").replace("material_comunitat:", "").replace("material_comunitat:", "")


                    var jsonData = JSON.parse(str);
                    console.log(jsonData)
                    this.num_professor = jsonData.professor.toLocaleString();
                    this.num_organitzacio = jsonData.organitzacio.toLocaleString();
                    this.num_assignatura = jsonData.assignatura.toLocaleString();
                    this.num_titulacio = jsonData.titulacio.toLocaleString();
                    this.num_material = jsonData.material.toLocaleString();
                    this.num_openAccess = jsonData.material_rights.toLocaleString();
                    this.num_pfc = jsonData.pfc.toLocaleString();
                    this.num_video = jsonData.videodocent.toLocaleString();
                  })
                  .catch(e => {
                    this.errors.push(e)
                  })
            },

            authCredentials: function () {

              axios.get(this.API_URL + '/admin/authCredentials', {


              }).then(response => {
                this.username = response.data;
              })
              .catch(e => {
                //window.location = this.API_URL+"/login"
                //alert(e)
                this.errors.push(e)
                console.log(e)
              })
            },

            search() {

                this.paramSearch = this.$refs.searchText.value
                //let optionSelected = this.$refs.searchSelected.id

                if(this.searchSelectedKey == 1){
                  if(this.paramSearch == "") this.$router.replace({ name: 'cercar'})
                  else this.$router.replace({ name: 'cercar', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 2){
                    if(this.paramSearch == "") this.$router.replace({ name: 'professors'})
                    else this.$router.replace({ name: 'professorsCerca', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 3){
                    if(this.paramSearch == "") this.$router.replace({ name: 'organitzacions'})
                    else this.$router.replace({ name: 'organitzacionsCerca', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 4){
                    if(this.paramSearch == "") this.$router.replace({ name: 'assignatures'})
                    else this.$router.replace({ name: 'assignaturesCerca', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 5){
                    if(this.paramSearch == "") this.$router.replace({ name: 'titulacions'})
                    else this.$router.replace({ name: 'titulacionsCerca', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 6){
                    if(this.paramSearch == "") this.$router.replace({ name: 'materials'})
                    else this.$router.replace({ name: 'materialsCerca', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 7){
                    if(this.paramSearch == "") this.$router.replace({ name: 'accesObert'})
                    else this.$router.replace({ name: 'accesObertCerca', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 8){
                    if(this.paramSearch == "") this.$router.replace({ name: 'treballsFinals'})
                    else this.$router.replace({ name: 'treballsFinalsCerca', query:{q: this.paramSearch}})
                }
                else if(this.searchSelectedKey == 9){
                    if(this.paramSearch == "") this.$router.replace({ name: 'videos'})
                    else this.$router.replace({ name: 'videosCerca', query:{q: this.paramSearch}})
                }

                this.$emit('incrementKey')
            }
        }
    }

</script>

<style scoped>
    .main-background{
        background-image: url("../assets/images/sections/default8.png");
    }

    .bg-active-upc{
        background-color: #dfec9c !important;
        /*e6ec65*/

    }

    .btn-size-40{
        width: 40px;
    }

</style>
