<template>

    <footer>
        <div>
            <div class="container">
                <div class="preFooter">
                    <a href="https://www.upc.edu/idp/ca" target="_blank" rel="noreferrer noopener" title="Iniciativa Digital Politècnica">
                      <img src="@/assets/images/partners/idp.jpg" alt="Iniciativa Digital Politècnica" title="Iniciativa Digital Politècnica" class="grayscale" height="40">
                    </a>
                    <a href="https://bibliotecnica.upc.edu/" target="_blank" rel="noreferrer noopener" title="Servei de Biblioteques, Publicacions i Arxius">
                        <img src="@/assets/images/partners/sbpa.png" alt="Servei de Biblioteques, Publicacions i Arxius" title="Servei de Biblioteques, Publicacions i Arxius" class="grayscale" height="40">
                    </a>
                    <a href="https://www.ice.upc.edu/ca" target="_blank" rel="noreferrer noopener" title="Institut de Ciències de l'Educació">
                        <img src="@/assets/images/partners/ice.png" alt="Institut de Ciències de l'Educació" title="Institut de Ciències de l'Educació" class="grayscale" height="40">
                    </a>
                    <a href="https://prisma-nou.upc.edu/" target="_blank" rel="noreferrer noopener" title="Oficina Prisma">
                        <img src="@/assets/images/partners/prisma.png" alt="Oficina Prisma" title="Oficina Prisma" class="grayscale" height="40">
                    </a>
                    <!--a href="https://atenea.upc.edu" target="_blank" rel="noreferrer noopener" title="Atenea">
                        <img src="@/assets/images/partners/atenea.png" alt="ATENEA" title="Atenea" class="grayscale">
                    </a>
                    <a href="http://drac.upc.edu/" target="_blank" rel="noreferrer noopener" title="DRAC. Descriptor de la Recerca i l'Activitat Acadèmica de la UPC">
                        <img src="@/assets/images/partners/drac.jpg" alt="DRAC. Descriptor de la Recerca i l'Activitat Acadèmica de la UPC" title="DRAC. Descriptor de la Recerca i l'Activitat Acadèmica de la UPC" class="grayscale">
                    </a-->
                </div>
            </div>

            <div class="bg-light-upc">
                <div class="container d-flex pt-3 pb-3">
                    <div class="mr-auto p-3"><span class="font-weight-bold">© UPC - </span>Universitat Politècnica de Catalunya · BarcelonaTech</div>
                    <div class="p-3"><a href="https://www.upc.edu/ca/contacte"><translate>Contacte</translate></a></div>
                    <div class="p-3"><a href="https://apren.upc.edu/api/RSS" target="_blank" ><translate>RSS</translate></a></div>
                    <div class="p-3"><a href="https://www.upc.edu/ca/avis-legal/accessibilitat"><translate>Accessibilitat</translate></a></div>
                    <div class="p-3"><a href="https://www.upc.edu/ca/avis-legal"><translate>Avís legal</translate></a></div>
                </div>
            </div>

        </div>

    </footer>
</template>

<script>
    export default {
        name: "Footer",
    }
</script>

<style scoped>

    footer{
        border-top: 1px solid #d4d5cf;
    }
    .preFooter{
        background: #fff;
        text-align: center;
    }
    .preFooter a{
        display: inline-block;
        height: 50px;
        margin: 15px;
    }
    .preFooter a img{
        max-height: 100%;
        width: auto;
        display: block;
        border: 0px;
        opacity: 0.9;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .preFooter a:hover img{
        opacity: 10;
    }
</style>