<template>

    <!-- https://html5-editor.net/ -->


    <div>

      <div v-if="$language.current === 'ca' || $language.current === 'es' || $language.current === 'en'">
        <h1>Com publicar a l’Aprèn</h1>

        <p>Per tal que un contingut docent aparegui publicat al portal <strong>Aprèn</strong>, cal que abans hagi estat dipositat a <a href="https://upcommons.upc.edu/">UPCommons</a>, el portal del coneixement obert de la UPC o a <a href="https://zonavideo.upc.edu/">Zonavídeo</a> si es tracta d’un audiovisual.</p>
        <p>El professorat de la UPC pot publicar continguts docents a <a href="https://upcommons.upc.edu/">UPCommons</a> adreçant-se a la seva biblioteca, o mitjançant <a href="https://atenea.upc.edu/">Atenea</a>, l’entorn virtual d’aprenentatge de la UPC.</p>


        <h4 class="mb-3">Beneficis de publicar a l’Aprèn&nbsp;</h4>
        <p>La publicació de recursos docents al portal <strong>Aprèn</strong> aporta molts beneficis al professorat, als centres i a la universitat:</p>
        <ul>
          <li class="ml-5 mb-3" aria-level="1">L’<strong>autoria</strong> dels continguts docents <strong>queda clarament definida</strong>, fet que els protegeix de possibles mals usos</li>
          <li class="ml-5 mb-3" aria-level="1">Els continguts docents queden <strong>dipositats i preservats</strong> al UPCommons, el portal del coneixement obert de la UPC</li>
          <li class="ml-5 mb-3" aria-level="1">L’ús de les llicències <a href="https://bibliotecnica.upc.edu/propietat-intellectual/llicencies-acces-lliure-creative-commons-gnu#quines-llicencies-cc-existeixen">Creative Commons</a> permet <strong>definir de manera unívoca</strong> quins són els <strong>usos que s’autoritzen</strong> a fer dels vostres continguts docents.
          </li>
          <li class="ml-5 mb-3" aria-level="1"><strong>Aprèn</strong> permet <strong>difondre i visualitzar l’activitat docent del PDI</strong> a partir dels continguts que s’elaboren per la docència.</li>
          <li class="ml-5 mb-3" aria-level="1"><strong>Aprèn</strong> proporciona informació de l’ús que se'n fa dels continguts publicats, indicant també a quines assignatures, centres i titulacions s’estan utilitzant.</li>
          <li class="ml-5 mb-3" aria-level="1">Un cop publicat disposaràs d’una <strong>adreça web permanent</strong> per referenciar el teu material docent</li>
        </ul>

        <h4>Com dipositar continguts docents a UPCommons des d’Atenea</h4>
        <p>Ja es poden incorporar continguts docents a UPCommons directament des d’<a href="https://atenea.upc.edu/">Atenea</a>, l’entorn virtual d’aprenentatge de la UPC.</p>
        <p>El procés que s’ha definit és molt simple i permet que, de manera ràpida, el PDI pugui compartir a UPCommons els arxius que prèviament ha incorporat a les seves assignatures.</p>
        <p>Un cop dipositat a UPCommons, els continguts apareixeran també al portal <strong>Aprèn</strong>, vinculats al  perfil del professorat, així com al de les assignatures, centres i titulacions en les quals s’està utilitzant. </p>
        <p>L’aplicació permet també que, des de la pròpia assignatura d’Atenea, es pugui fer el seguiment de l’estat de la seva publicació.</p>
        <p dir="ltr">&nbsp;</p>

        <h4>Com fer-ho?</h4>
        <p>
          Es poden incorporar a UPCommons des d’Atenea els arxius que el PDI ha publicat a les seves assignatures.
        </p>
        <p>
          La publicació es fa desde l’edició de la pròpia assignatura:
        </p>
        <p>
          1. Activeu el model d’edició de vostra assignatura:
          <br>
          <img src="../assets/images/publish/publish1.png" class="border mt-3 mb-3" alt="Model edició">
        </p>
        <p>
          2. Aneu al menú d’opcions de l’arxiu que vulgueu publicar a UPCommons i seleccioneu l'opció ‘Envia a UPCommons’.
          <br>
          <img src="../assets/images/publish/publish2.png" class="border mt-3 mb-3" alt="Envia a UPCommons">
        </p>
        <p>
          3. Seleccioneu de quin tipus d’activitat es tracta.
          <br>
          <img src="../assets/images/publish/publish3.png" class="border mt-3 mb-3" alt="Tipus activitat">
        </p>
        <p>
          4. Reviseu les dades abans de ‘Desar els canvis’ i envieu l’arxiu a UPCommons.
          <br>
          <img src="../assets/images/publish/publish4.png" class="border mt-3 mb-3" alt="Desar canvis">
        </p>
        <ul class="mt-4">
          <li class="ml-5 mb-3" aria-level="1">Podeu editar, modificar o ampliar les dades (afegir autores i autors, …) abans de que s’enviïn, juntament amb l’arxiu a UPCommons.</li>
          <li class="ml-5 mb-3" aria-level="1">Totes les metadades són revisades i ampliades per part del personal del <a href="https://bibliotecnica.upc.edu/">Servei de Biblioteques, Publicacions i Arxius</a> (SBPA)</li>
          <li class="ml-5 mb-3" aria-level="1">Un cop el contingut docent es troba publicat a UPCommons rebreu un missatge de correu electrònic on s’indicarà l’adreça per poder accedir-hi.</li>
        </ul>
        <p>
          5. Podeu fer el seguiment de l’estat de la vostra tramesa prement l’opció “Consulta trameses a UPCommons” que trobaràs al menú lateral dret.
          <br>
          <img src="../assets/images/publish/publish5.png" class="border mt-3 mb-3" alt="Estat tramesa">
        </p>
        <ul class="mt-4">
          <p>Al menú d’informació de l’assignatura hi trobaràs l’opció ‘Consulta trameses a UPCommons’, que et facilita saber en quin estat es troba la publicació dels teus materials docents:</p>
          <li class="ml-5 mb-3" aria-level="1"><strong>Pendent</strong>: encara no enviat a UPCommons.</li>
          <li class="ml-5 mb-3" aria-level="1"><strong>Enviat</strong>: l’arxiu i les seves dades es troben ja enviades a UPCommons.</li>
          <li class="ml-5 mb-3" aria-level="1"><strong>En revisió</strong>: en procés de revisió i ampliació de metadades per part d’una biblioteca.</li>
          <li class="ml-5 mb-3" aria-level="1"><strong>Rebutjat</strong>: és possible que un contingut s’hagi hagut de rebutjar si, per exemple, ja es troba dipositat a UPCommons.</li>
          <li class="ml-5 mb-3" aria-level="1"><strong>Publicat</strong>: el contingut docent es troba ja publicat a UPCommons i se n’indica l’enllaç.</li>
        </ul>

        <h4 class="mb-3">Contacta amb la teva biblioteca&nbsp;</h4>
        <p>Si tens dubtes o vols publicar continguts docents que no es troben a Atenea, contactat amb la teva biblioteca: </p>
        <ul>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/brgf">Biblioteca Rector Gabriel Ferrat&eacute;</a>: Montse Moragas (<a href="mailto:montse.moragas@upc.edu">montse.moragas@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bct">Biblioteca del Campus de Terrassa</a>: Isabel Molinos (<a href="mailto:isabel.molinos@upc.edu">isabel.molinos@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcbl">Biblioteca del Campus del Baix Llobregat</a>: Gemma Garc&iacute;a (<a href="mailto:gemma.garcia@upc.edu">gemma.garcia@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcum">Biblioteca del Campus Universitari de Manresa</a>: Conxa Moncunill (<a href="mailto:conxa.moncunill@upc.edu">conxa.moncunill@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etseib">Biblioteca de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Enginyeria Industrial de Barcelona</a>: Margarita Anglada (<a href="mailto:margarita.anglada@upc.edu">margarita.anglada@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsab">Biblioteca Oriol Bohigas de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Arquitectura de Barcelona</a>: Fani D&iacute;az (<a href="mailto:fani.diaz@upc.edu">fani.diaz@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/eebe">Biblioteca de l&rsquo;Escola d&rsquo;Enginyeria de Barcelona Est</a>: Marina Casadevall (<a href="mailto:marina.casadevall@upc.edu">marina.casadevall@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/epseb">Biblioteca de l&rsquo;Escola Polit&egrave;cnica Superior d&rsquo;Edificaci&oacute; de Barcelona</a>: Rosal&iacute;a Ord&oacute;&ntilde;ez (<a href="mailto:rosalia.ordonez@upc.edu">rosalia.ordonez@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/epsevg">Biblioteca de l&rsquo;Escola Polit&egrave;cnica Superior d&rsquo;Enginyeria de Vilanova i la Geltr&uacute;</a>: Ta&iuml;s Bag&eacute;s (<a href="mailto:tais.bages@upc.edu">tais.bages@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsav">Biblioteca de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Arquitectura del Vall&egrave;s</a>: &Agrave;ngel Beumala (<a href="mailto:angel.beumala@upc.edu">angel.beumala@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/fme">Biblioteca de la Facultat de Matem&agrave;tiques i Estad&iacute;stica</a>: Sara Matheu (<a href="mailto:sara.matheu@upc.edu">sara.matheu@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/fnb">Biblioteca de la Facultat de N&agrave;utica de Barcelona</a>: Manel Mendoza (<a href="mailto:manel.mendoza@upc.edu">manel.mendoza@upc.edu</a>)</li>
        </ul>

      </div>


      <div v-if="$language.current === 'es'">

      </div>

      <div v-if="$language.current === 'en'">

      </div>

    </div>

</template>

<script>

    export default {
        name: 'Publish',
    }
</script>

<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: circle;
        padding: 0;
    }

    li {
        /*display: inline-block;*/
        margin: 0 10px;
    }

</style>
