<template>

    <div id="home" class="view">

      <div class="home-block">
        <h2><translate>Destacats</translate></h2>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg">
            <div class="grid-element grid-element-separated-space">
              <router-link :to="{name: 'publish'}" class="zoom internal">
                <img src="../assets/images/home_blocks_items/ice.png" title="Accés obert" alt="Accés obert" style="height: 270px;">
                <div class="icon text">
                  <h3 class="featured"><translate>Com publicar a APRÈN</translate></h3>
                  <p>Per tal que un contingut docent aparegui publicat al portal Aprèn, cal que abans hagi estat dipositat a UPCommons, a Zonavídeo si es tracta d’un audiovisual o mitjançant Atenea, l’entorn virtual d’aprenentatge de la UPC.</p>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg">
            <div class="grid-element grid-element-separated-space">
              <a href="https://bibliotecnica.upc.edu/punt-atenea" target="true"  class="zoom external">
                <img src="../assets/images/home_blocks_items/punt-atenea2.png" title="Punt ATENEA" alt="Punt ATENEA" style="height: 270px;">
                <div class="icon text">
                  <h3 class="featured"><translate>Punt ATENEA Biblioteques-ICE</translate></h3>
                  <p>El Punt ATENEA Biblioteques-ICE és un servei personalitzat d’orientació i assessorament per estructurar i dissenyar cursos al campus virtual ATENEA (Moodle).</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="home-block featured-background">
        <h2><translate>Serveis</translate></h2>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://www.upc.edu/idp/ca" target="_blank" class="zoom external">
                <img src="../assets/images/home_blocks_items/home-idp.png" title="idp" alt="idp">
                <div class="icon text">
                  <h3 class="featured">Publicacions</h3>
                  <p>Llibres Iniciativa Digital Politècnica</p>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://serveistic.upc.edu/ca/produccio-audiovisual" target="_blank" class="zoom external">
                <img  src="../assets/images/home_blocks_items/home-zonavideo.png" title="Audiovisuals" alt="Audiovisuals">
                <div class="icon text">
                  <h3 class="featured">Audiovisuals</h3>
                  <p>Servei de suport a la producció audiovisual</p>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://bibliotecnica.upc.edu/professors" target="_blank" class="zoom external">
                <img  src="../assets/images/home_blocks_items/calaix.png" title="Bibliotècnica recursos" alt="Bibliotècnica recursos">
                <div class="icon text">
                  <h3 class="featured">Eines pel PDI</h3>
                  <p>Eines i recursos per a la docència i la gestió</p>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://www.upc.edu/slt/ca" target="_blank" class="zoom external">
                <img  src="../assets/images/home_blocks_items/home-slt.jpg" title="slt" alt="slt">
                <div class="icon text">
                  <h3 class="featured">SLT</h3>
                  <p>Servei de Llengües i Terminologia</p>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>

      <div class="home-block">

          <h2><translate>Publicar a APRÈN</translate></h2>


            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="grid-element grid-element-separated-space">
                      <b-link v-b-modal.modal-1-crea class="zoom">
                        <img :src="require('@/assets/images/home_blocks_items/1'+$language.current+'.png')" title="Crea" alt="Crea">
                      </b-link>
                    </div>
                </div>


                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="grid-element grid-element-separated-space">
                      <b-link v-b-modal.modal-2-identifica class="zoom">
                        <img :src="require('@/assets/images/home_blocks_items/2'+$language.current+'.png')" title="Identifica" alt="Identifica">
                      </b-link>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="grid-element grid-element-separated-space">
                      <b-link v-b-modal.modal-3-publica class="zoom">
                        <img :src="require('@/assets/images/home_blocks_items/3'+$language.current+'.png')" title="Publica" alt="Publica">
                      </b-link>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="grid-element grid-element-separated-space">
                      <b-link v-b-modal.modal-4-comparteix class="zoom">
                        <img :src="require('@/assets/images/home_blocks_items/4'+$language.current+'.png')" title="Comparteix" alt="Comparteix">
                      </b-link>
                    </div>
                </div>

            </div>

      </div>

      <div class="home-block" id="home-block-sdg">
        <h2>Objectius de desenvolupament sostenible</h2>
        <div class="d-flex flex-wrap align-items-stretch justify-content-center">
          <div class="sdg" v-for="(goal, index) in goals" :key="'goal.' + index">
            <!--a :href="goal.link" :title="goal.title"><img :src="goal.image" :alt="goal.title"></a-->

            <router-link :to="{name: 'sdg', params: {id: goal.link}}" :title="goal.title">
              <img :src="goal.image" :alt="goal.title">
            </router-link>

          </div>
        </div>
      </div>

      <div class="home-block featured-background">
        <h2><translate>Altres productes</translate></h2>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://upcommons.upc.edu" target="_blank" class="zoom external">
                <img src="../assets/images/home_blocks_items/home-upcommons.png" title="upcommons" alt="upcommons">
                <div class="icon text">
                  <h3 class="featured">UPCommons</h3>
                  <p>Portal d'accés obert al coneixement de la UPC</p>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://geocommons.upc.edu" target="_blank" class="zoom external">
                <img src="../assets/images/home_blocks_items/home-geocommons.png" title="geocommons" alt="geocommons">
                <div class="icon text">
                  <h3 class="featured">GeoCommons</h3>
                  <p>Geolocalització de la producció acadèmica de la UPC</p>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://futur.upc.edu" target="_blank" class="zoom external">
                <img src="../assets/images/home_blocks_items/home-futur.png" title="futur" alt="futur">
                <div class="icon text">
                  <h3 class="featured">FUTUR</h3>
                  <p>Portal de la producció científica dels investigadors de la UPC</p>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="grid-element grid-element-separated-space">
              <a href="https://geodoctorat.upc.edu" target="_blank" class="zoom external">
                <img src="../assets/images/home_blocks_items/home-geodoctorat.jpg" title="geodoctorat" alt="geodoctorat">
                <div class="icon text">
                  <h3 class="featured">Geodoctorat UPC</h3>
                  <p>Geolocalització dels doctorants de la UPC</p>
                </div>
              </a>
            </div>
          </div>

        </div>


      </div>

      <div class="home-block">
        <h2><translate>Novetats</translate></h2>
        <MaterialList :materials="materials"></MaterialList>
      </div>




      <!-- MODALS PUBLICAR EN APREN -->
      <div>
        <!-- 1. Crea -->
        <b-modal id="modal-1-crea" size="lg" header-class="crea" footer-class="crea">
          <div>
            <div v-if="$language.current === 'ca'">
              <p class="text-dark font-weight-bold h1 mb-4">1. Crea</p>
              <ul>
                <li class="content-modal">Tots els continguts docents disponibles a Apr&egrave;n es troben dipositats a <a href="https://upcommons.upc.edu/"><strong>UPCommons</strong></a> o a <a href="https://zonavideo.upc.edu/"><strong>Zonav&iacute;deo</strong></a>, si es tracta de materials audiovisuals.</li>
                <li class="content-modal">Pots publicar a Apr&egrave;n apunts i presentacions, ex&agrave;mens, treballs de fi d&rsquo;estudis (TFE), v&iacute;deos docents, llibres, treballs d&rsquo;estudiants, problemes i exercicis, pr&agrave;ctiques, programari o imatges.</li>
                <li class="content-modal">Tingues en compte els aspectes relacionats amb la <a href="https://bibliotecnica.upc.edu/propietat-intellectual/drets-autor-materials-docents"><strong>propietat intel&middot;lectual</strong></a> quan elaboris els teus continguts docents.</li>
                <li class="content-modal">La UPC posa a la teva disposici&oacute; serveis de suport a la creaci&oacute; de continguts docents, com el <a href="https://serveistic.upc.edu/ca/produccio-audiovisual"><strong>Servei de Producci&oacute; Audiovisual</strong></a>, o els <a href="https://www.upc.edu/slt/ca/traduccions"><strong>serveis de traduccions i correccions</strong></a> que ofereix el Servei de Lleng&uuml;es i Terminologia.</li>
              </ul>
            </div>
            <div v-else-if="$language.current === 'es'">
              <p class="text-dark font-weight-bold h1 mb-4">1. Crea</p>
              <ul>
                <li class="content-modal">Todos los contenidos docentes disponibles en Apr&egrave;n se hallan depositados en <a href="https://upcommons.upc.edu/"><strong>UPCommons</strong></a> o en <a href="https://zonavideo.upc.edu/"><strong>Zonav&iacute;deo</strong></a>, si se trata de materiales audiovisuales.</li>
                <li class="content-modal">Puedes publicar en Apr&egrave;n apuntes y presentaciones, ex&aacute;menes, trabajos de fin de estudios (TFE), v&iacute;deos docentes, libros, trabajos de estudiantes, problemas y ejercicios, pr&aacute;cticas, software o im&aacute;genes.</li>
                <li class="content-modal">Ten en cuenta los aspectos relacionados con la <a href="https://bibliotecnica.upc.edu/propietat-intellectual/drets-autor-materials-docents"><strong>propiedad intelectual</strong></a> a la hora de elaborar tus contenidos docentes.</li>
                <li class="content-modal">La UPC pone a tu disposici&oacute;n servicios de apoyo a la creaci&oacute;n de contenidos docentes, como el <a href="https://serveistic.upc.edu/ca/produccio-audiovisual"><strong>Servicio de Producci&oacute;n Audiovisual</strong></a>, o los <a href="https://www.upc.edu/slt/ca/traduccions"><strong>servicios de traducciones y correcciones</strong></a> que ofrece el Servicio de Lenguas y Terminolog&iacute;a.</li>
              </ul>
            </div>
            <div v-else>
              <p class="text-dark font-weight-bold h1 mb-4">1. Create</p>
              <ul>
                <li class="content-modal">All the teaching content in Apr&egrave;n has been deposited in <a href="https://upcommons.upc.edu/"><strong>UPCommons</strong></a> or <a href="https://zonavideo.upc.edu/"><strong>Zonav&iacute;deo</strong></a>, in the case of audiovisual content.</li>
                <li class="content-modal">You can publish lecture notes and presentations, exam papers, bachelor&rsquo;s and master&rsquo;s theses, teaching videos, books, student assignments, problems and exercises, practicals, software and images in the Apr&egrave;n system.</li>
                <li class="content-modal">Keep in mind aspects of <a href="https://bibliotecnica.upc.edu/propietat-intellectual/drets-autor-materials-docents"><strong>intellectual property</strong></a> when you are preparing your teaching content .</li>
                <li class="content-modal">The UPC provides services that support the creation of teaching content, such as an <a href="https://serveistic.upc.edu/ca/produccio-audiovisual"><strong>audiovisual production service</strong></a>, and <a href="https://www.upc.edu/slt/ca/traduccions"><strong>translation and editing services</strong></a> by the Language and Terminology Service.</li>
              </ul>
            </div>
          </div>
        </b-modal>


        <!-- 2. Identifica -->
        <b-modal id="modal-2-identifica" size="lg" header-class="identifica" footer-class="identifica">
          <div>
            <div v-if="$language.current === 'ca'">
              <p class="text-dark font-weight-bold h1 mb-4">2. Identifica</p>
              <ul>
                <li class="content-modal">Assegura&rsquo;t que els teus continguts docents disposen d&rsquo;una autoria clarament identificada.></li>
                <li class="content-modal">Incorpora a la portada o a la primera p&agrave;gina:
                  <ul class="mt-3">
                    <li class="content-modal">El nom complet de tots els autors i autores (<a href="https://bibliotecnica.upc.edu/investigadors/signatura-filiacio-institucional">Signatura i filiació institucional</a>)</li>
                    <li class="content-modal">Un t&iacute;tol que els identifiqui</li>
                    <li class="content-modal">La data de creaci&oacute; dels continguts</li>
                    <li class="content-modal">La unitat o les unitats als estudis de les quals van adre&ccedil;ats</li>
                    <li class="content-modal">El nom de l&rsquo;assignatura</li>
                    <li class="content-modal">El logotip de la UPC i dels centres o d&rsquo;altres unitats</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else-if="$language.current === 'es'">
              <p class="text-dark font-weight-bold h1 mb-4">2. Identifica</p>
              <ul>
                <li class="content-modal">Aseg&uacute;rate de que tus contenidos docentes tienen una autor&iacute;a claramente identificada.&nbsp;</li>
                <li class="content-modal">Incorpora a la portada o a la primera p&aacute;gina:
                  <ul class="mt-3">
                    <li class="content-modal">El nombre completo de todos los autores y autoras (<a href="https://bibliotecnica.upc.edu/investigadors/signatura-filiacio-institucional">Firma y filiación institucional</a>)</li>
                    <li class="content-modal">Un t&iacute;tulo identificativo</li>
                    <li class="content-modal">La fecha de creaci&oacute;n de los contenidos</li>
                    <li class="content-modal">La unidad o las unidades a cuyos estudios van dirigidos</li>
                    <li class="content-modal">El nombre de la asignatura</li>
                    <li class="content-modal">El logotipo de la UPC y de los centros u otras unidades</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else>
              <p class="text-dark font-weight-bold h1 mb-4">2. Identify</p>
              <ul>
                <li class="content-modal">Make sure that the authorship of your teaching content is clearly identified.&nbsp;</li>
                <li class="content-modal">The cover or first page should include:
                  <ul class="mt-3">
                    <li class="content-modal">The full name of all the authors (<a href="https://bibliotecnica.upc.edu/investigadors/signatura-filiacio-institucional">Signature and institutional affiliation</a>).</li>
                    <li class="content-modal">The title.</li>
                    <li class="content-modal">The date on which the content was created.</li>
                    <li class="content-modal">The school that teaches the degree that the teaching content is for.</li>
                    <li class="content-modal">The name of the subject.</li>
                    <li class="content-modal">The logo of the UPC and of the schools or other units.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </b-modal>


        <!-- 3. Publica -->
        <b-modal id="modal-3-publica" size="lg" header-class="publica" footer-class="publica">
          <div>
            <div v-if="$language.current === 'ca'">
              <p class="text-dark font-weight-bold h1 mb-4">3. Publica</p>
              <ul>
                <li class="content-modal">Publica els teus continguts en acc&eacute;s obert i assigna una llic&egrave;ncia de <a href="https://bibliotecnica.upc.edu/propietat-intellectual/llicencies-acces-lliure-creative-commons-gnu">Creative Commons</a> als teus materials per tal d&rsquo;especificar quins usos n&rsquo;autoritzes.</li>
                <li class="content-modal">Contacta amb la teva <a href="https://bibliotecnica.upc.edu/biblioteques">biblioteca</a> i publica els teus continguts docents a UPCommons en obert sempre que sigui possible:
                  <ul class="mt-3">
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/brgf">Biblioteca Rector Gabriel Ferrat&eacute;</a>: Montse Moragas (<a href="mailto:montse.moragas@upc.edu">montse.moragas@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bct">Biblioteca del Campus de Terrassa</a>: Isabel Molinos (<a href="mailto:isabel.molinos@upc.edu">isabel.molinos@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bcbl">Biblioteca del Campus del Baix Llobregat</a>: Gemma Garc&iacute;a (<a href="mailto:gemma.garcia@upc.edu">gemma.garcia@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bcum">Biblioteca del Campus Universitari de Manresa</a>: Conxa Moncunill (<a href="mailto:conxa.moncunill@upc.edu">conxa.moncunill@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etseib">Biblioteca de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Enginyeria Industrial de Barcelona</a>: Margarita Anglada (<a href="mailto:margarita.anglada@upc.edu">margarita.anglada@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etsab">Biblioteca Oriol Bohigas de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Arquitectura de Barcelona</a>: Fani D&iacute;az (<a href="mailto:fani.diaz@upc.edu">fani.diaz@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/eebe">Biblioteca de l&rsquo;Escola d&rsquo;Enginyeria de Barcelona Est</a>: Marina Casadevall (<a href="mailto:marina.casadevall@upc.edu">marina.casadevall@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/epseb">Biblioteca de l&rsquo;Escola Polit&egrave;cnica Superior d&rsquo;Edificaci&oacute; de Barcelona</a>: Rosal&iacute;a Ord&oacute;&ntilde;ez (<a href="mailto:rosalia.ordonez@upc.edu">rosalia.ordonez@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/epsevg">Biblioteca de l&rsquo;Escola Polit&egrave;cnica Superior d&rsquo;Enginyeria de Vilanova i la Geltr&uacute;</a>: Ta&iuml;s Bag&eacute;s (<a href="mailto:tais.bages@upc.edu">tais.bages@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etsav">Biblioteca de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Arquitectura del Vall&egrave;s</a>: &Agrave;ngel Beumala (<a href="mailto:angel.beumala@upc.edu">angel.beumala@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/fme">Biblioteca de la Facultat de Matem&agrave;tiques i Estad&iacute;stica</a>: Sara Matheu (<a href="mailto:sara.matheu@upc.edu">sara.matheu@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/fnb">Biblioteca de la Facultat de N&agrave;utica de Barcelona</a>: Manel Mendoza (<a href="mailto:manel.mendoza@upc.edu">manel.mendoza@upc.edu</a>)</li>
                  </ul>
                </li>
              </ul>


            </div>

            <div v-else-if="$language.current === 'es'">
              <p class="text-dark font-weight-bold h1 mb-4">3. Publica</p>
              <ul>
                <li class="content-modal">Publica tus contenidos en acceso abierto y asigna una licencia de <a href="https://bibliotecnica.upc.edu/propietat-intellectual/llicencies-acces-lliure-creative-commons-gnu">Creative Commons</a> a tus materiales para especificar qu&eacute; usos autorizas.</li>
                <li class="content-modal">Contacta con tu <a href="https://bibliotecnica.upc.edu/biblioteques">biblioteca</a> y publica tus contenidos docentes en UPCommons en abierto siempre que sea posible:
                  <ul class="mt-3">
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/brgf">Biblioteca Rector Gabriel Ferrat&eacute;</a>: Montse Moragas (<a href="mailto:montse.moragas@upc.edu">montse.moragas@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bct">Biblioteca del Campus de Terrassa</a>: Isabel Molinos (<a href="mailto:isabel.molinos@upc.edu">isabel.molinos@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bcbl">Biblioteca del Campus del Baix Llobregat</a>: Gemma Garc&iacute;a (<a href="mailto:gemma.garcia@upc.edu">gemma.garcia@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bcum">Biblioteca del Campus Universitario de Manresa</a>: Conxa Moncunill (<a href="mailto:conxa.moncunill@upc.edu">conxa.moncunill@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etseib">Biblioteca de la Escuela T&eacute;cnica Superior de Ingenier&iacute;a Industrial de Barcelona</a>: Margarita Anglada (<a href="mailto:margarita.anglada@upc.edu">margarita.anglada@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etsab">Biblioteca Oriol Bohigas de la Escuela T&eacute;cnica Superior de Arquitectura de Barcelona</a>: Fani D&iacute;az (<a href="mailto:fani.diaz@upc.edu">fani.diaz@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/eebe">Biblioteca de la Escuela de Ingenier&iacute;a de Barcelona Este</a>: Marina Casadevall (<a href="mailto:marina.casadevall@upc.edu">marina.casadevall@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/epseb">Biblioteca de la Escuela Polit&eacute;cnica Superior de Edificaci&oacute;n de Barcelona</a>: Rosal&iacute;a Ord&oacute;&ntilde;ez (<a href="mailto:rosalia.ordonez@upc.edu">rosalia.ordonez@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/epsevg">Biblioteca de la Escuela Polit&eacute;cnica Superior de Ingenier&iacute;a de Vilanova i la Geltr&uacute;</a>: Ta&iuml;s Bag&eacute;s (<a href="mailto:tais.bages@upc.edu">tais.bages@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etsav">Biblioteca de la Escuela T&eacute;cnica Superior de Arquitectura del Vall&egrave;s</a>: &Agrave;ngel Beumala (<a href="mailto:angel.beumala@upc.edu">angel.beumala@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/fme">Biblioteca de la Facultad de Matem&aacute;ticas y Estad&iacute;stica</a>: Sara Matheu (<a href="mailto:sara.matheu@upc.edu">sara.matheu@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/fnb">Biblioteca de la Facultad de N&aacute;utica de Barcelona</a>: Manel Mendoza (<a href="mailto:manel.mendoza@upc.edu">manel.mendoza@upc.edu</a>)</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div v-else>
              <p class="text-dark font-weight-bold h1 mb-4">3. Publish</p>
              <ul>
                <li class="content-modal">Publish your content in open access and assign a Creative <a href="https://bibliotecnica.upc.edu/propietat-intellectual/llicencies-acces-lliure-creative-commons-gnu">Commons licence</a> to your materials that specifies how they can be used.</li>
                <li class="content-modal">Contact your <a href="https://bibliotecnica.upc.edu/biblioteques">library</a> and publish your teaching content in UPCommons in open access whenever possible:
                  <ul class="mt-3">
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/brgf">Rector Gabriel Ferrat&eacute; Library</a>: Montse Moragas (<a href="mailto:montse.moragas@upc.edu">montse.moragas@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bct">Terrassa Campus Library</a>: Isabel Molinos (<a href="mailto:isabel.molinos@upc.edu">isabel.molinos@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bcbl">Baix Llobregat Campus Library</a>: Gemma Garc&iacute;a (<a href="mailto:gemma.garcia@upc.edu">gemma.garcia@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/bcum">Manresa University Campus Library</a>: Conxa Moncunill (<a href="mailto:conxa.moncunill@upc.edu">conxa.moncunill@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etseib">Barcelona School of Industrial Engineering Library</a>: Margarita Anglada (<a href="mailto:margarita.anglada@upc.edu">margarita.anglada@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etsab">Oriol Bohigas Library of the Barcelona School of Architecture</a>: Fani D&iacute;az (<a href="mailto:fani.diaz@upc.edu">fani.diaz@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/eebe">Barcelona East School of Engineering Library</a>: Marina Casadevall (<a href="mailto:marina.casadevall@upc.edu">marina.casadevall@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/epseb">Barcelona School of Building Construction Library</a>: Rosal&iacute;a Ord&oacute;&ntilde;ez (<a href="mailto:rosalia.ordonez@upc.edu">rosalia.ordonez@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/epsevg">Vilanova i la Geltr&uacute; School of Engineering Library</a>: Ta&iuml;s Bag&eacute;s (<a href="mailto:tais.bages@upc.edu">tais.bages@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/etsav">Vall&egrave;s School of Architecture Library</a>: &Agrave;ngel Beumala (<a href="mailto:angel.beumala@upc.edu">angel.beumala@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/fme">School of Mathematics and Statistics Library</a>: Sara Matheu (<a href="mailto:sara.matheu@upc.edu">sara.matheu@upc.edu</a>)</li>
                    <li class="content-modal"><a href="https://bibliotecnica.upc.edu/fnb">Barcelona School of Nautical Studies Library</a>: Manel Mendoza (<a href="mailto:manel.mendoza@upc.edu">manel.mendoza@upc.edu</a>)</li>
                  </ul>
                </li>
              </ul>

            </div>
          </div>
        </b-modal>

        <!-- 4. Comparteix -->
        <b-modal id="modal-4-comparteix" size="lg" header-class="comparteix" footer-class="comparteix">
          <div>

            <div v-if="$language.current === 'ca'">
              <p class="text-dark font-weight-bold h1 mb-4">4. Comparteix</p>
              <ul>
                <li class="content-modal">Des d&rsquo;<a href="https://upcommons.upc.edu/"><strong>UPCommons</strong></a> i Apr&egrave;n, els podr&agrave;s referenciar als teus estudiants a Atenea o en altres entorns de suport a l&rsquo;aprenentatge, o facilitar-ne l'acc&eacute;s a qualsevol persona que hi estigui interessada.</li>
              </ul>
            </div>
            <div v-else-if="$language.current === 'es'">
              <p class="text-dark font-weight-bold h1 mb-4">4. Comparte</p>
              <ul>
                <li class="content-modal">Desde <a href="https://upcommons.upc.edu/"><strong>UPCommons</strong></a> y Apr&egrave;n, podr&aacute;s referenciarlos a tus estudiantes en Atenea o en otros entornos de apoyo al aprendizaje, o facilitar su acceso a cualquier persona que est&eacute; interesada en ellos.</li>
              </ul>
            </div>
            <div v-else>
              <p class="text-dark font-weight-bold h1 mb-4">4. Share</p>
              <ul>
                <li class="content-modal"><a href="https://upcommons.upc.edu/"><strong>UPCommons</strong></a> and Apr&egrave;n allow you to share content with your students on Atenea and other learning support environments and provide access to it to anyone who is interested.</li>
              </ul>

            </div>
          </div>
        </b-modal>


      </div>

    </div>

</template>

<script>

    import axios from 'axios'
    import MaterialList from "../components/MaterialList"
    import Utils from '../extends/Utils'

    export default {
        mixins: [Utils],
        name: 'Home',
        components: {
          MaterialList
        },

        props: {
            msg: String
        },

        data() {
            return {
                materials: [],
                goals: [
                    { title: '1. Fi de la pobresa', link: '01', image: 'https://futur.upc.edu/images/sdg/1.png' },
                    { title: '2. Fam zero', link: '02', image: 'https://futur.upc.edu/images/sdg/2.png' },
                    { title: '3. Salut i benestar', link: '03', image: 'https://futur.upc.edu/images/sdg/3.png' },
                    { title: '4. Educació de qualitat', link: '04', image: 'https://futur.upc.edu/images/sdg/4.png' },
                    { title: '5. Igualtat de gènere', link: '05', image: 'https://futur.upc.edu/images/sdg/5.png' },
                    { title: '6. Aigua neta i sanejament', link: '06', image: 'https://futur.upc.edu/images/sdg/6.png' },
                    { title: '7. Energia assequible i no contaminant', link: '07', image: 'https://futur.upc.edu/images/sdg/7.png' },
                    { title: '8. Treball decent i creixement econòmic', link: '08', image: 'https://futur.upc.edu/images/sdg/8.png' },
                    { title: '9. Industria, innovació i infraestrcutura', link: '09', image: 'https://futur.upc.edu/images/sdg/9.png' },
                    { title: '10. Reducció de les desigualtats', link: '10', image: 'https://futur.upc.edu/images/sdg/10.png' },
                    { title: '11. Ciutats i comunitats sostenibles', link: '11', image: 'https://futur.upc.edu/images/sdg/11.png' },
                    { title: '12. Producció i consum responsables', link: '12', image: 'https://futur.upc.edu/images/sdg/12.png' },
                    { title: '13. Acció per al clima', link: '13', image: 'https://futur.upc.edu/images/sdg/13.png' },
                    { title: '14. Vida submarina', link: '14', image: 'https://futur.upc.edu/images/sdg/14.png' },
                    { title: '15. Vida d\'ecosistemes terrestres', link: '15', image: 'https://futur.upc.edu/images/sdg/15.png' },
                    { title: '16. Pau, justicia i institucions sòlides', link: '16', image: 'https://futur.upc.edu/images/sdg/16.png' },
                    { title: '17. Aliança per a aconseguir els objectius', link: '17', image: 'https://futur.upc.edu/images/sdg/17.png' },
                    { title: 'Veure tots', link: 'all', image: 'https://futur.upc.edu/images/sdg/all.png' }

                ]
            }
        },
        methods:{
          setMetaTitle(){
            document.title = "Aprèn - "+this.$router.app.$gettext('Inici')
          },

          hideShowBlocks(index){
                let crea = document.getElementById("1crea")
                let identifica = document.getElementById("2identifica")
                let publica = document.getElementById("3publica")
                let comparteix = document.getElementById("4comparteix")

                if(index === "1crea" && crea.style.display === "none" ){
                    crea.style.display = "block"
                    identifica.style.display = "none"
                    publica.style.display = "none"
                    comparteix.style.display = "none"
                }else if(index === "2identifica" && identifica.style.display === "none" ){
                    crea.style.display = "none"
                    identifica.style.display = "block"
                    publica.style.display = "none"
                    comparteix.style.display = "none"
                }else if(index === "3publica" && publica.style.display === "none" ){
                    crea.style.display = "none"
                    identifica.style.display = "none"
                    publica.style.display = "block"
                    comparteix.style.display = "none"
                }else if(index === "4comparteix" && comparteix.style.display === "none" ){
                    crea.style.display = "none"
                    identifica.style.display = "none"
                    publica.style.display = "none"
                    comparteix.style.display = "block"
                }else{
                    crea.style.display = "none"
                    identifica.style.display = "none"
                    publica.style.display = "none"
                    comparteix.style.display = "none"
                }
            },

            solrQuery: function (currentPage, length, query, sort) {

                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage-1)*length;


                axios.get(this.API_URL + '/engine/home/news?rows='+length+'&start='+start+'&sort='+sort)
                    .then(response => {

                        this.materials = response.data.response.docs;

                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            }

        },
        created() {
            this.solrQuery(1, 5, "tipus:material", "data desc, material_data_available desc")
            this.setMetaTitle()
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    #home {
      margin-top: -30px;
    }

    ul li {
      list-style: none;
      position: relative;
      padding: 3px 0 2px 25px;
    }

    li::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 5px;
      width: 7px;
      height: 12px;
      border: solid #007BC1;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }


    .content-modal {
      font-weight: 300;
      margin-bottom: 20px;
      font-size: 1rem;

    }

    /deep/ .crea {
      background: #395471;
      color: #ffffff;
    }

    /deep/ .crea-class > .modal-dialog > .modal-content > .modal-header {
      background: #395471;
      color: white;
    }

    /deep/ .identifica {
      background: #e86b28;
      color: #ffffff;
    }

    /deep/ .publica {
      background: #f0ca00;
      color: #ffffff;
    }

    /deep/ .comparteix {
      background: #4facbe;
      color: #ffffff;
    }

    .alert-crea {
        color: #ffffff;
        background-color: #395471;
        border-color: #c6c8ca;
    }

    .alert-identifica {
        color: #ffffff;
        background-color: #e86b28;
        border-color: #c6c8ca;
    }

    .alert-publica {
        color: #395471;
        background-color: #f0ca00;
        border-color: #c6c8ca;
    }

    .alert-comparteix {
        color: #ffffff;
        background-color: #4facbe;
        border-color: #c6c8ca;
    }

</style>
