<template>

    <div>
        <nav aria-label="breadcrumb" class="mt-n4 small">
            <ol class="breadcrumb bg-white ml-n3">
                <li class="mr-1"><translate>Sou a:</translate></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'home'}"><translate>Inici</translate></router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'assignatures'}"><translate>Assignatures</translate></router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $route.params.id }}</li>
            </ol>
        </nav>

        <div class="row">
            <div class="col-sm-12">

                <div class="card">
                    <div class="card-header">
                        <h3 class="h3">
                            <span v-if="$language.current === 'ca'" >{{ assignatura.assignatura_nom_ca}}</span>
                            <span v-else-if="$language.current === 'es'" >{{ assignatura.assignatura_nom_es}}</span>
                            <span v-else>{{ assignatura.assignatura_nom_en}}</span>
                            - ({{assignatura.assignatura_sigles}})
                        </h3>
                    </div>


                    <div class="card-body">

                        <div class="m-t-20 row">
                            <div class="col-md-2 col-xs-12 text-center mb-2">
                              <!--img v-if="material.material_thumbnail" v-bind:src="material.material_thumbnail" alt="user" class="img-responsive float-left"-->
                              <!--img src="../assets/images/perfil.png" alt="user" class="img-responsive rounded float-left shadow"-->
                              <i style="color: #5fa728;" class="fas fa-sitemap fa-8x mb-2"></i>
                              <!--span class="display-3">{{assignatura.assignatura_sigles}}</span-->
                            </div>
                            <div class="col-md-8">

                                <div class="row mb-2">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Nom</translate></p></div>
                                    <div class="col-md-9 col-xs-12">
                                      <span v-if="$language.current === 'ca'" >{{ assignatura.assignatura_nom_ca}}</span>
                                      <span v-else-if="$language.current === 'es'" >{{ assignatura.assignatura_nom_es}}</span>
                                      <span v-else>{{ assignatura.assignatura_nom_en}}</span>
                                    </div>
                                </div>

                                <hr>

                                <div class="row mb-2" v-if="assignatura.assignatura_centres">
                                    <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Centres</translate></p></div>
                                    <div class="col-md-9 col-xs-12 text-secondary">
                                      <!-- filter para quitar los centros repetidos -->
                                      <div v-for="(centre, index) in (assignatura.assignatura_centres.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual))" v-bind:key="'centre'+index">
                                        <div class="mb-1"><router-link :to="{name: 'organitzacio', params: {tipus:'centre', id: centre}}"><translate>{{ centre }}</translate></router-link></div>
                                      </div>
                                    </div>
                                </div>

                                <div class="row mb-2" v-if="assignatura.assignatura_titulacions_actives">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Titulacions Actives</translate></p></div>
                                  <div class="col-md-9 col-xs-12 text-secondary">
                                    <div v-for="(titulacio, index) in assignatura.assignatura_titulacions_actives" v-bind:key="'titulacio-activa'+index" class="d-flex w-100 justify-content-between">
                                      <div class="mb-1"><router-link :to="{name: 'titulacio', params: {id: titulacio}}"><translate>{{ titulacio }}</translate></router-link></div>
                                      <span v-if="getTipus(assignatura.assignatura_tipus, index) === 'OB'" class="mb-1"><span class="badge badge-upc"><translate>OB</translate></span></span>
                                      <span v-else-if="getTipus(assignatura.assignatura_tipus, index) === 'OP'" class="mb-1"><span class="badge badge-success"><translate>OP</translate></span></span>
                                      <span v-else-if="getTipus(assignatura.assignatura_tipus, index) === 'ALE'" class="mb-1"> <span class="badge badge-danger"><translate>ALE</translate></span></span>
                                      <span v-else class="mb-1"><span class="badge badge-dark"><translate>OTHER</translate></span></span>
                                    </div>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="assignatura.assignatura_titulacions_no_actives">
                                  <div class="col-md-3 col-xs-12"><p class="mb-1 font-weight-bold"><translate>Titulacions NO actives</translate></p></div>
                                  <div class="col-md-9 col-xs-12 text-secondary">
                                    <div v-for="(titulacio, index) in assignatura.assignatura_titulacions_no_actives" v-bind:key="'titulacio-no_activa'+index">
                                      <div class="mb-1"><router-link :to="{name: 'titulacio', params: {id: titulacio}}"><translate>{{ titulacio }}</translate></router-link></div>
                                    </div>
                                  </div>
                                </div>



                            </div>
                            <div class="col-md-2 col-xs-12"><img src="../assets/images/fons_fitxa.png" alt="user" class="img-responsive rounded float-left"></div>
                        </div>

                    </div>



                    <div class="card-footer">
                      <div class="d-flex">
                        <div class="mr-auto p-0">
                          <AddThis publicId="ra-5ae95b2117917438" :data-title="assignatura.assignatura_nom_ca" :async="true"/>
                        </div>
                        <div class="mt-2">
                          <div class="d-flex">
                            <img src="../assets/images/mini-upc.png" alt="user" class="mr-1">
                            <a class="mr-4" :href="'https://apren.upc.edu/api/RSS/' + assignatura.id" target="_blank" rel="noreferrer noopener"><translate>RSS</translate></a>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

        </div>

        <br>

        <h2 class="mt-5" v-if="professors.length>0" ><translate>participant</translate></h2>
        <div class="row">

          <div class="col-sm-12 col-md-12">
            <ul class="list-unstyled" style="column-count: 4;">
              <router-link v-for="(professor) in professors" :key="professor.id" :to="{name: 'professor', params: {id: professor.id}}">
                <li class="mb-3"><i class="fas fa-user mr-2 text-secondary"></i> {{professor.professor_nom}} ({{professor.professor_num_materials}})</li>
              </router-link>

            </ul>
          </div>

        </div>

        <br>

        <div class="row d-none d-lg-block">
            <div class="col-sm-12">
                <b-tabs content-class="mt-3 col-12">
                    <b-tab active>
                        <template v-slot:title>
                            <h5><i class="fas fa-chart-bar"></i> <translate>Resum Gràfic</translate></h5>
                        </template>
                        <b-card-text>

                            <div class="row">
                                <div class="col-4">
                                    <ChartByTipus :facetes="facetes"></ChartByTipus>
                                </div>
                                <div class="col-8">
                                    <ChartByData :facetes="facetes" :facetesPivot="facetesPivot"></ChartByData>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <h5><i class="fas fa-globe-europe"></i> <translate>Resum Gràfic rights</translate></h5>
                      </template>
                      <b-card-text>
                        <div class="row">
                          <div class="col-12">
                            <ChartByDataAndAccess :facetes="facetes" :facetesPivot="facetesPivot"></ChartByDataAndAccess>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <br>


        <div class="row">

            <div class="col-sm-12 col-md-8">

                <h2><translate>Materials</translate></h2>
                <nav class="navbar navbar-expand-lg navbar-upc bg-light rounded">

                    <PaginationHeader :navigation_start="navigation_start" :navigation_end="navigation_end" :navigation_total="navigation_total"></PaginationHeader>

                    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

                        <ul class="navbar-nav mr-auto mt-lg-0">

                        </ul>

                        <form class="form-inline">
                            <div class="form-check mt-1 mb-1 form-control-sm">
                                <label class="form-check-label"><translate>Ordenar per:</translate></label>
                                <select class="custom-select ml-1" @change="onChange($event)" id="selectOrderBy">
                                  <option value="data asc, material_data_available asc"><translate>Data</translate> asc.</option>
                                  <option value="data desc, material_data_available desc" selected><translate>Data</translate> desc.</option>
                                  <option value="material_titol asc"><translate>Títol</translate> asc.</option>
                                  <option value="material_titol desc"><translate>Títol</translate> desc.</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </nav>

                <MaterialList :materials="materials"></MaterialList>

                <Pagination ref='pagination' :length="navigation_length" :totalPages="totalPages" :query="query" :sort="sort"></Pagination>

            </div>

            <div id="filtres" class="col-sm-12 col-md-4 mt-0">
                <Facetes ref='facetes' :data="facetes" :numFiltres="5" :currentPage="currentPage" :length="navigation_length" :query="query" :sort="sort" :xarxa-desactivada="false"></Facetes>
            </div>

        </div>

    </div>

</template>

<script>

    import axios from 'axios'
    import Facetes from '../components/Facetes.vue'
    import Pagination from "../components/Pagination"
    import PaginationHeader from "../components/PaginationHeader"
    import ChartByTipus from "../components/ChartByTipus"
    import ChartByData from "../components/ChartByData"
    import ChartByDataAndAccess from '../components/ChartByDataAndAccess.vue'
    import Utils from "../extends/Utils"
    import MaterialList from "../components/MaterialList"
    import AddThis from 'vue-simple-addthis-share'


    export default {
        mixins: [Utils],
        name: 'Assignatura',
        components: {
            PaginationHeader,
            Facetes,
            ChartByTipus,
            ChartByData,
            ChartByDataAndAccess,
            Pagination,
            MaterialList,
            AddThis
        },
        data() {
            return {
                assignatura: [],
                materials: [],
                facetes: [],
                facetesPivot: [],
                professors: [],

                navigation_start: 0,
                navigation_end: 0,
                navigation_length: 20,
                navigation_total: 0,
                totalPages: 1,
                currentPage: 1,
                query: "material_assignatures:"+this.$route.params.id,
                sort: "data desc, material_data_available desc",
                errors: []
            }
        },


        methods: {
            setMetaTitle(elem){
              if(this.$language.current === 'ca') document.title = "Aprèn - " + elem.assignatura_nom_ca
              else if(this.$language.current === 'es') document.title = "Aprèn - " + elem.assignatura_nom_es
              else document.title = "Aprèn - " + elem.assignatura_nom_en
            },
            getTipus(tipus, index) {
              //paso la variable tipus a String
              let tipusStr = tipus+""
              return tipusStr.split(",")[index];
            },

            solrQueryProfessorsAssignatura: function (codi) {
              axios.get(this.API_URL + '/engine/assignatura/professors/'+codi)
                  .then(response => {
                    this.professors = response.data.response.docs;
                  })
                  .catch(e => {
                    this.errors.push(e)
                  })
            },
            solrQueryProfessor: function (gaussId) {
                axios.get(this.API_URL + '/engine/'+gaussId)
                    .then(response => {
                        if(response.data.response.numFound === 0) window.location = this.APREN_URL
                        this.assignatura = response.data.response.docs[0];
                        this.setMetaTitle(this.assignatura)
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },
            onChange(event) {
                this.solrQuery(1, this.navigation_length, this.query, event.target.value)
            },
            solrQuery: function (currentPage, length, query, sort) {

                this.currentPage = currentPage;
                this.query = query;
                this.sort = sort;

                let start = (currentPage-1)*length

                //axios.get(this.API_URL + '/engine/execute?query=q='+query+'??facet.field=subtipus??facet.field=data??facet.field=material_rights??facet.field=material_arees_tematiques??facet.field=material_idiomes??facet.field=material_examen_resolt??facet.field=material_professors_faceta??facet.field=material_centres??facet.field=material_departaments??facet.field=material_campus??facet.field=material_titulacions_actives??facet.field=material_titulacions_no_actives??facet.pivot=subtipus,data??facet.pivot=subtipus,material_rights??facet.pivot=material_rights,subtipus??facet=on??f.data.facet.sort=index??f.material_ods.facet.sort=index??facet.mincount=1??rows='+length+'??start='+start+'??sort='+sort)
                axios.get(this.API_URL +'/engine/item?query='+query+'&rows='+length+'&start='+start+'&sort='+sort)
                    .then(response => {

                        this.materials = response.data.response.docs;
                        this.facetes = response.data.facet_counts.facet_fields;
                        this.facetesPivot = response.data.facet_counts.facet_pivot;

                        //reverse facet data
                        response.data.facet_counts.facet_fields.data = this.reverseFacetData(response.data.facet_counts.facet_fields.data)

                        this.navigation_start = response.data.response.start+1;
                        this.navigation_total = response.data.response.numFound;
                        this.navigation_length = length;
                        this.navigation_end = response.data.response.start+this.navigation_length >= response.data.response.numFound ? response.data.response.numFound : response.data.response.start+this.navigation_length

                        this.totalPages = Math.ceil(response.data.response.numFound / length);

                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            }
        },

        created() {
            this.solrQueryProfessor(this.$route.params.id)
            this.solrQuery(1, this.navigation_length, this.query, this.sort)
            this.solrQueryProfessorsAssignatura(this.$route.params.id)
        }
    }

</script>

<style scoped>

    .card-header {
        border-bottom: 5px solid #5fa728 !important;
    }

    .card-body {
        background-color: #ddf1ea;
        border-bottom: 1px solid #dfdfdf !important;
    }

    .card-footer {
        border-top: 5px solid #5fa728 !important;
    }

</style>
