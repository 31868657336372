<template>
    <span></span>
</template>

<script>
    import '@fortawesome/fontawesome-free/css/all.css'
    import '@fortawesome/fontawesome-free/js/all.js'

    export default {
        name: "BaseStyle"
    }
</script>

<!--style lang="scss">@import "src/assets/styles/app.scss";</style-->

<!--style scoped>
    //@import './../assets/styles/futur.css';
</style-->

<style lang="scss">
    $font-stack:    Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;;
    $primary-color: #FFFFFF;

    .text-orange{
        color: #c38b06;
    }

    .text-upc {
        color: #007bc0;
    }

    .text-green {
        color: #21644b;
    }

    .bg-light-upc {
        background-color: whitesmoke !important;
    }

    .badge-upc {
        color: #fff;
        background-color: #21644b;
        /*007bc0*/
    }

    .list-group li { background: whitesmoke; }
    .list-group li:nth-child(odd) { background: white; }

    .card-header {
        /*border-bottom: 5px solid #e2ab17 !important;*/
        border-bottom: 5px solid #5fa728 !important;
    }

    .card-body {
        border-bottom: 1px solid #dfdfdf !important;
    }

    .card-footer {
        border-top: 5px solid #0479be !important;
        /*e2ab17*/
    }

    #filtres div.card-body h3 a {
        /*color: #00634d;*/
        color: #5fa728;

    }

    #filtres div.card-body h3 {
        font-size: 14px;
        line-height: 16px;
        color: #960b04;
        margin-top: 0px;
    }

    #filtres div.card-body div.opcio-filtre, #filtres div.opcio-altres {
        margin-top: 10px;
    }


</style>
