<template>

    <!-- https://html5-editor.net/ -->

    <div>

      <div v-if="$language.current === 'ca'">
        <h1>Contacte</h1>

        <p dir="ltr">Nom&eacute;s cal que contactis amb la teva biblioteca per incorporar els teus continguts docents a <strong>Apr&egrave;n</strong> o resoldre possibles dubtes.</p>
        <p>Les persones de contacte s&oacute;n:</p>
        <ul>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/brgf">Biblioteca Rector Gabriel Ferrat&eacute;</a>: Montse Moragas (<a href="mailto:montse.moragas@upc.edu">montse.moragas@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bct">Biblioteca del Campus de Terrassa</a>: Isabel Molinos (<a href="mailto:isabel.molinos@upc.edu">isabel.molinos@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcbl">Biblioteca del Campus del Baix Llobregat</a>: Gemma Garc&iacute;a (<a href="mailto:gemma.garcia@upc.edu">gemma.garcia@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcum">Biblioteca del Campus Universitari de Manresa</a>: Conxa Moncunill (<a href="mailto:conxa.moncunill@upc.edu">conxa.moncunill@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etseib">Biblioteca de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Enginyeria Industrial de Barcelona</a>: Margarita Anglada (<a href="mailto:margarita.anglada@upc.edu">margarita.anglada@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsab">Biblioteca Oriol Bohigas de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Arquitectura de Barcelona</a>: Fani D&iacute;az (<a href="mailto:fani.diaz@upc.edu">fani.diaz@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/eebe">Biblioteca de l&rsquo;Escola d&rsquo;Enginyeria de Barcelona Est</a>: Marina Casadevall (<a href="mailto:marina.casadevall@upc.edu">marina.casadevall@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/epseb">Biblioteca de l&rsquo;Escola Polit&egrave;cnica Superior d&rsquo;Edificaci&oacute; de Barcelona</a>: Rosal&iacute;a Ord&oacute;&ntilde;ez (<a href="mailto:rosalia.ordonez@upc.edu">rosalia.ordonez@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/epsevg">Biblioteca de l&rsquo;Escola Polit&egrave;cnica Superior d&rsquo;Enginyeria de Vilanova i la Geltr&uacute;</a>: Ta&iuml;s Bag&eacute;s (<a href="mailto:tais.bages@upc.edu">tais.bages@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsav">Biblioteca de l&rsquo;Escola T&egrave;cnica Superior d&rsquo;Arquitectura del Vall&egrave;s</a>: &Agrave;ngel Beumala (<a href="mailto:angel.beumala@upc.edu">angel.beumala@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/fme">Biblioteca de la Facultat de Matem&agrave;tiques i Estad&iacute;stica</a>: Sara Matheu (<a href="mailto:sara.matheu@upc.edu">sara.matheu@upc.edu</a>)</li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/fnb">Biblioteca de la Facultat de N&agrave;utica de Barcelona</a>: Manel Mendoza (<a href="mailto:manel.mendoza@upc.edu">manel.mendoza@upc.edu</a>)</li>
        </ul>
        <p dir="ltr"><br />En cas de dubte, et pots adre&ccedil;ar al Servei de Biblioteques, Publicacions i Arxius (SBPA) a l&rsquo;adre&ccedil;a: <a href="mailto:info.biblioteques@upc.edu">info.biblioteques@upc.edu</a>.</p>
      </div>


      <div v-else-if="$language.current === 'es'">
        <h1>Contacto</h1>
        <p><span >Solo tienes que contactar con tu biblioteca para incorporar tus contenidos docentes a </span><strong>Apr&egrave;n</strong><span > o resolver posibles dudas.</span></p>
        <p>&nbsp;</p>
        <p><span >Las personas de contacto son:</span></p>
        <p>&nbsp;</p>
        <ul>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/brgf"><span >Biblioteca Rector Gabriel Ferrat&eacute;</span></a><span >: Montse Moragas (</span><a href="mailto:montse.moragas@upc.edu"><span >montse.moragas@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bct"><span >Biblioteca del Campus de Terrassa</span></a><span >: Isabel Molinos (</span><a href="mailto:isabel.molinos@upc.edu"><span >isabel.molinos@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcbl"><span >Biblioteca del Campus del Baix Llobregat</span></a><span >: Gemma Garc&iacute;a (</span><a href="mailto:gemma.garcia@upc.edu"><span >gemma.garcia@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcum"><span >Biblioteca del Campus Universitario de Manresa</span></a><span >: Conxa Moncunill (</span><a href="mailto:conxa.moncunill@upc.edu"><span >conxa.moncunill@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etseib"><span >Biblioteca de la Escuela T&eacute;cnica Superior de Ingenier&iacute;a Industrial de Barcelona</span></a><span >: Margarita Anglada (</span><a href="mailto:margarita.anglada@upc.edu"><span >margarita.anglada@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsab"><span >Biblioteca Oriol Bohigas de la Escuela T&eacute;cnica Superior de Arquitectura de Barcelona</span></a><span >: Fani D&iacute;az (</span><a href="mailto:fani.diaz@upc.edu"><span >fani.diaz@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/eebe"><span >Biblioteca de la Escuela de Ingenier&iacute;a de Barcelona Este</span></a><span >: Marina Casadevall (</span><a href="mailto:marina.casadevall@upc.edu"><span >marina.casadevall@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/epseb"><span >Biblioteca de la Escuela Polit&eacute;cnica Superior de Edificaci&oacute;n de Barcelona</span></a><span >: Rosal&iacute;a Ord&oacute;&ntilde;ez (</span><a href="mailto:rosalia.ordonez@upc.edu"><span >rosalia.ordonez@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/epsevg"><span >Biblioteca de la Escuela Polit&eacute;cnica Superior de Ingenier&iacute;a de Vilanova i la Geltr&uacute;</span></a><span >: Ta&iuml;s Bag&eacute;s (</span><a href="mailto:tais.bages@upc.edu"><span >tais.bages@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsav"><span >Biblioteca de la Escuela T&eacute;cnica Superior de Arquitectura del Vall&egrave;s</span></a><span >: &Agrave;ngel Beumala (</span><a href="mailto:angel.beumala@upc.edu"><span >angel.beumala@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/fme"><span >Biblioteca de la Facultad de Matem&aacute;ticas y Estad&iacute;stica</span></a><span >: Sara Matheu (</span><a href="mailto:sara.matheu@upc.edu"><span >sara.matheu@upc.edu</span></a><span >)</span></li>
          <li class="ml-5" aria-level="1"><a href="https://bibliotecnica.upc.edu/fnb"><span >Biblioteca de la Facultad de N&aacute;utica de Barcelona</span></a><span >: Manel Mendoza (</span><a href="mailto:manel.mendoza@upc.edu"><span >manel.mendoza@upc.edu</span></a><span >)</span></li>
        </ul>
        <p><br /><span >En caso de duda, puedes dirigirte al Servicio de Bibliotecas, Publicaciones y Archivos (SBPA) enviando un e-mail a: </span><a href="mailto:info.biblioteques@upc.edu"><span >info.biblioteques@upc.edu</span></a><span >.</span></p>

      </div>

      <div v-else>
        <h1>Contact</h1>
        <!--p><strong>How can I incorporate my content?</strong></p-->
        <p><span style="font-weight: 300;">Contact your library to incorporate your teaching content into </span><strong>Apr&egrave;n</strong><span style="font-weight: 300;"> or if you have any queries.</span></p>
        <p><span style="font-weight: 300;">Contact persons:</span></p>
        <ul>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/brgf"><span style="font-weight: 300;">Rector Gabriel Ferrat&eacute; Library</span></a><span style="font-weight: 300;">: Montse Moragas (</span><a href="mailto:montse.moragas@upc.edu"><span style="font-weight: 300;">montse.moragas@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/bct"><span style="font-weight: 300;">Terrassa Campus Library</span></a><span style="font-weight: 300;">: Isabel Molinos (</span><a href="mailto:isabel.molinos@upc.edu"><span style="font-weight: 300;">isabel.molinos@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcbl"><span style="font-weight: 300;">Baix Llobregat Campus Library</span></a><span style="font-weight: 300;">: Gemma Garc&iacute;a (</span><a href="mailto:gemma.garcia@upc.edu"><span style="font-weight: 300;">gemma.garcia@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/bcum"><span style="font-weight: 300;">Manresa University Campus Library</span></a><span style="font-weight: 300;">: Conxa Moncunill (</span><a href="mailto:conxa.moncunill@upc.edu"><span style="font-weight: 300;">conxa.moncunill@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/etseib"><span style="font-weight: 300;">Barcelona School of Industrial Engineering Library</span></a><span style="font-weight: 300;">: Margarita Anglada (</span><a href="mailto:margarita.anglada@upc.edu"><span style="font-weight: 300;">margarita.anglada@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsab"><span style="font-weight: 300;">Oriol Bohigas Library of the Barcelona School of Architecture</span></a><span style="font-weight: 300;">: Fani D&iacute;az (</span><a href="mailto:fani.diaz@upc.edu"><span style="font-weight: 300;">fani.diaz@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/eebe"><span style="font-weight: 300;">Barcelona East School of Engineering Library</span></a><span style="font-weight: 300;">: Marina Casadevall (</span><a href="mailto:marina.casadevall@upc.edu"><span style="font-weight: 300;">marina.casadevall@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/epseb"><span style="font-weight: 300;">Barcelona School of Building Construction Library</span></a><span style="font-weight: 300;">: Rosal&iacute;a Ord&oacute;&ntilde;ez (</span><a href="mailto:rosalia.ordonez@upc.edu"><span style="font-weight: 300;">rosalia.ordonez@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/epsevg"><span style="font-weight: 300;">Vilanova i la Geltr&uacute; School of Engineering Library</span></a><span style="font-weight: 300;">: Ta&iuml;s Bag&eacute;s (</span><a href="mailto:tais.bages@upc.edu"><span style="font-weight: 300;">tais.bages@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/etsav"><span style="font-weight: 300;">Vall&egrave;s School of Architecture Library</span></a><span style="font-weight: 300;">: &Agrave;ngel Beumala (</span><a href="mailto:angel.beumala@upc.edu"><span style="font-weight: 300;">angel.beumala@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/fme"><span style="font-weight: 300;">School of Mathematics and Statistics Library</span></a><span style="font-weight: 300;">: Sara Matheu (</span><a href="mailto:sara.matheu@upc.edu"><span style="font-weight: 300;">sara.matheu@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
          <li style="font-weight: 300;" aria-level="1"><a href="https://bibliotecnica.upc.edu/fnb"><span style="font-weight: 300;">Barcelona School of Nautical Studies Library</span></a><span style="font-weight: 300;">: Manel Mendoza (</span><a href="mailto:manel.mendoza@upc.edu"><span style="font-weight: 300;">manel.mendoza@upc.edu</span></a><span style="font-weight: 300;">)</span></li>
        </ul>
        <p><br /><span style="font-weight: 300;">If you are not sure, you can e-mail the Libraries, Publications and Archives Service (SBPA) at: </span><a href="mailto:info.biblioteques@upc.edu"><span style="font-weight: 300;">info.biblioteques@upc.edu</span></a><span style="font-weight: 300;">.</span></p>
      </div>
    </div>



</template>

<script>

    export default {
        name: 'About',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: circle;
        padding: 0;
    }

    li {
        /*display: inline-block;*/
        margin: 0 10px;
    }

    /*a {
        color: #42b983;
    }

    .alert-crea {
        color: #ffffff;
        background-color: #395471;
        border-color: #c6c8ca;
    }

    .alert-identifica {
        color: #395471;
        background-color: #b1d8e8;
        border-color: #c6c8ca;
    }

    .alert-publica {
        color: #395471;
        background-color: #f0ca00;
        border-color: #c6c8ca;
    }

    .alert-comparteix {
        color: #ffffff;
        background-color: #4facbe;
        border-color: #c6c8ca;
    }
    */

</style>
