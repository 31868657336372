<template>

    <div class="list-group list-group-flush mt-3">
      <li v-for="(material, index) in materials" :key="material.id" class="list-group-item list-group-item-action flex-column align-items-start">

        <div class="m-t-20 row">
          <div class="col-md-10">
            <div class="d-flex justify-content-between mb-2">
              <div class="p2">
                <router-link :to="{name: 'material', params: {id: material.id}}">{{ material.material_titol}} </router-link>
                <i v-if="material.material_rights === 'Open Access'" class="fas fa-lock-open text-warning" :title="material.material_rights"></i>
                <img v-else-if="material.material_rights === 'Restricted access to the UPC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the UPC academic community">
                <img v-else-if="material.material_rights === 'Restricted access to the CSUC academic community'" src="../assets/images/obertupc.png" :title="material.material_rights" alt="Restricted access to the CSUC academic community">
                <i v-else class="fas fa-lock text-warning" :title="material.material_rights"></i>
              </div>
            </div>

            <div>

              <div class="text-secondary font-weight-bold mb-1"><translate>{{ material.subtipus }}</translate></div>

              <div v-if="material.material_comunitat[0] === 'pfc'">

                <div class="text-secondary mb-1" v-if="material.material_altresAutories">
                  <i class="fas fa-user text-secondary"></i>
                  <span v-for="(altre, index) in material.material_altresAutories" v-bind:key="altre">
                    {{altre}}<span v-if="index !== (material.material_altresAutories.length-1)">;</span>
                  </span>
                </div>

                <div class="text-secondary mb-1" v-if="material.material_professors">
                  <translate>tutor</translate>:
                  <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                    {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                  </span>
                </div>

              </div>
              <div v-else>
                <div class="text-secondary mb-1" v-if="material.material_professors">
                  <i class="fas fa-user text-secondary"></i>
                  <span v-for="(professor, index) in material.material_professors" v-bind:key="professor">
                    {{professor.split('##')[0]}}<span v-if="index !== (material.material_professors.length-1)">;</span>
                  </span>
                </div>
              </div>

              <div class="text-secondary mb-1"><translate>Data de publicació:</translate> {{ material.data }}</div>

              <div v-if="material.material_isbn" class="text-secondary mb-1">
                ISBN:
                <span v-for="(isbn, index) in material.material_isbn" v-bind:key="isbn">
                  {{isbn}}<span v-if="index !== (material.material_isbn.length-1)">;</span>
                </span>
              </div>

              <div class="mt-2" v-if="material.material_centres">
                <span v-for="centre in material.material_centres" v-bind:key="centre">
                  <translate>{{centre}}</translate><br>
                </span>
              </div>

              <div class="mt-2 text-orange" v-if="material.material_assignatures">
                <span v-for="assignatura in material.material_assignatures" v-bind:key="assignatura">
                  <translate>{{assignatura}}</translate><br>
                </span>
              </div>

              <div class="mt-4">
                <a v-if="material.material_resum" @click='hideShow("resum"+index)' style="cursor:pointer;" class="text-secondary mr-4"><i class="fas fa-lg fa-search"></i> <translate>Veure detall</translate></a>
                <a :href="'https://hdl.handle.net/' + material.id.replace('_','/')" target="_blank" rel="noreferrer noopener" class="text-secondary mr-4"><i class="fas fa-lg fa-file"></i> <translate>Accés al text complet</translate></a>
                <div v-bind:id="'resum'+index" class="alert alert-dark mt-3" style="display: none;">
                  <div v-for="(resum, index) in material.material_resum" v-bind:key="resum">
                    <div v-if="index !== (material.material_resum.length-1)" class="mb-4">{{resum}}</div>
                    <div v-else>{{resum}}</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="col-md-2 col-xs-12 d-none d-lg-block">
            <a v-bind:href="material.material_original" target="_blank" rel="noreferrer noopener">
              <img v-if="material.material_thumbnail" :src="material.material_thumbnail" @error="imageUrlAlt($event, material.material_rights)" alt="thumbnail" class="img-responsive float-left img-thumbnail">
              <img v-else src="../assets/images/no-photo.png" alt="thumbnail">
            </a>
          </div>
        </div>

      </li>

    </div>

</template>

<script>

    import Utils from "../extends/Utils";

    export default {
        mixins: [Utils],
        name: 'MaterialList',

        props: {
          materials: {
            type: Array,
            default() {
              return [];
            },
          },
        },

        methods: {

          hideShow(index){
            let x = document.getElementById(index)
            if (x.style.display === "none") {
              x.style.display = "block";
            } else {
              x.style.display = "none";
            }
          },
        }
    }
</script>

